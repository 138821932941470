import { 
  React, 
  Switch, 
  Route, 
  Redirect, 
  Router, 
  _,
  User,
  AuthContextProps,
  withAuth
} from "$Imports/Imports";

import { 
  mainNavigation, 
  INavigationItem 
} from "$Utilities/navigation";

import { 
  AppContainer 
} from "./AppContainer";

import {  
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

interface IRoutingBaseProps {

}

type IRoutingProps = IRoutingBaseProps & AuthContextProps

export class _Routing extends React.Component<IRoutingProps> {
  private _renderRouter(navigation: INavigationItem, levelIdx: number, idx: number, securityContext: User | null = null): JSX.Element[] {
    const results: JSX.Element[] = [];

    // Check if the menu item is enabled.
    const isEnabled = typeof navigation.enabled === "function" ? navigation.enabled(navigation, securityContext) : navigation.enabled;

    // Check if the user has access.
    const hasAccess = navigation.hasAccess !== undefined ?
      typeof navigation.hasAccess === "function" ? navigation.hasAccess(navigation, securityContext) : navigation.hasAccess
      : true;

    if (!navigation.externalLink && isEnabled && hasAccess) {
      if (navigation.redirectUrls !== undefined && _.isArray(navigation.redirectUrls)) {
        _.forEach(navigation.redirectUrls, (s, sIdx) => {
          results.push(
            (
              <Route key={`${levelIdx}-${idx}-${sIdx}`} exact={true} path={s}>
                <Redirect
                  to={navigation.url}
                />
              </Route>
            )
          );
        });
      }

      const router: JSX.Element = (
        <Route key={`${levelIdx}-${idx}`} path={navigation.url} component={navigation.component} />
      );

      if (navigation.childNavigation && _.isArray(navigation.childNavigation)) {
        _.forEach(navigation.childNavigation, (n, nIdx) => {
          const routes = this._renderRouter(n, levelIdx + 1, nIdx, securityContext);
          results.push(...routes);
        });
      }

      results.push(router);
    }

    return results;
  }

  render() {
    const navigationRoutes: JSX.Element[][] = _.map(mainNavigation, (n, mnIdx: number) => {
      return this._renderRouter(n, 0, mnIdx, SharedSecurityContext.getUser());
    })
    
    const routes: JSX.Element[] = _.flatten(navigationRoutes);

    return (
      <Router>
        <AppContainer>
          <Switch>
            {routes}
          </Switch>
        </AppContainer>
      </Router>
    );
  }
}

export const Routing = withAuth(_Routing);