import {
  React
} from "$Imports/Imports";

import {
  Grid
} from "$Imports/MaterialUIComponents";

import {
  QuoteView
} from "./QuoteView";

import {
  QuoteDetails
} from "./QuoteDetails";

import {
  RateQuoteCard
} from "./RateQuoteCard";

import {
  QuoteActions
} from "./QuoteActions";

import {
  DeclinedReasonModal
} from "./DeclinedReasonModal";

const styles: {
  column: string
} = require("./CustomerQuoteView.scss");

interface ICustomerQuoteViewProp {

}

/**
 * this page would benefit from refactoring to break the child components down,
 * especially as it grows in complexity
 *
 * SalesPortalView
 *    QuoteEntry (new component, takes the place of CommodityDataEntryView below)
 *        CallerCard
 *        QuoteStopEntry (with tabs for multiple stops)
 *            ShipperConsignee (factor out of CommodityDataEntryView)
 *            Commodity (heavily pruned version of CommodityDataEntryView)
 *            TarpUpcharge (factor out of CommodityDataEntryView)
 *    RateInfoSection
 */

export class CustomerQuoteView extends React.Component<ICustomerQuoteViewProp> {

  render() {
    return (
      <>
        <Grid container>
          <Grid className={styles.column} item xs={8}>
            <QuoteView />
          </Grid>

          <Grid className={styles.column} item xs={4}>
            <QuoteDetails />
            <RateQuoteCard />
            <QuoteActions />
          </Grid>
        </Grid>

        <DeclinedReasonModal />
      </>
    )
  }
}
