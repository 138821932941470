import {
  IAjaxState,
  React,
  _
} from "$Imports/Imports";

import {
  Card
} from "$Imports/MaterialUIComponents";

import {
  AjaxActionIndicator,
  CardLinedHeader
} from "$Imports/CommonComponents";

import {
  Customer
} from "$Generated/api";

interface IKaiserDetailsCardProps {
  customerId: number | undefined;
  customerData: Customer | null | undefined;
  isFetching: boolean;
}

const styles: {
  card: string;
  infoContainer: string;
  subcolumnSmall: string;
  subcolumnLarge: string;
  fieldBlock: string;
  fieldPair: string;
  fieldLabel: string;
  fieldValue: string;
} = require("./KaiserDetailsCard.scss");

export class KaiserDetailsCard extends React.PureComponent<IKaiserDetailsCardProps> {

  render() {
    const {
      customerData,
      isFetching
    } = this.props;

    const salesRep = customerData?.salesAgent ? customerData?.salesAgent?.firstName + " " + customerData?.salesAgent?.lastName : "Not Assigned";
    const salesRepPhoneNumber = customerData?.salesAgent?.phoneNumber || "None";
    const salesRepEmail = customerData?.salesAgent?.emailAddress || "None";

    return (
      <Card className={styles.card}>
        <CardLinedHeader
          title={<span>
            Kaiser Contact Information
          </span>}
          color="white"
        >
          <AjaxActionIndicator showProgress={isFetching} />
          <div className={styles.infoContainer}>
            <div className={styles.subcolumnSmall}>
              <div className={styles.fieldBlock}>
                <div className={styles.fieldPair}>
                <div className={styles.fieldLabel}>Kaiser Rep:</div>
                  <div className={styles.fieldValue} title={salesRep}>{salesRep}</div>
                </div>

                <div className={styles.fieldPair}>
                  <div className={styles.fieldLabel}>Rep Phone:</div>
                  <div className={styles.fieldValue} title={salesRepPhoneNumber}>{salesRepPhoneNumber}</div>
                </div>
              </div>
            </div>

            <div className={styles.subcolumnLarge}>
              <div className={styles.fieldBlock}>
                <div className={styles.fieldPair}>
                  <div className={styles.fieldLabel}>Rep Email:</div>
                  <div className={styles.fieldValue} title={salesRepEmail}>{salesRepEmail !== "None" ? <a href={`mailto:${salesRepEmail}`} target="_blank">{salesRepEmail}</a> :  salesRepEmail}</div>
                </div>
              </div>
            </div>
          </div>
        </CardLinedHeader>
      </Card>
    )
  }
}