import {
  React,
  _
} from "$Imports/Imports";

import {
  ThemeConsumer
} from "$Providers/index";

import {
  INavigationServiceInjectedProps,
  NavigationService
} from "$State/NavigationFreezerService";

import {
  SecurityContext
} from "$Utilities/Security/ApplicationSecuritySettings";

import {
  NoCustomerAccessView
} from "./NoCustomerAccessView"

interface IMainLayoutBaseProps {
  applicationBar: JSX.Element;
  sideNavigation: JSX.Element;
}

type IMainLayoutProps = IMainLayoutBaseProps & INavigationServiceInjectedProps;

const styles: {
  applicationContainer: string;
  subContainer: string;
  mainContent: string;
} = require("./MainLayout.scss");

class _MainLayout extends React.Component<IMainLayoutProps> {

  render() {
    let sideNavigationItems = this.props.navigationService.getSideNavigationItems();
    const currentNaviationItem = this.props.navigationService.getNavigationDetail();

    return (
      <ThemeConsumer>
        {(context) => {
          const theme = context.themeConfig;

          if (!SecurityContext.canAccessCustomerPortal()) {
            return <NoCustomerAccessView />;
          }

          return (
              <div
                className={styles.applicationContainer}
              >
                {currentNaviationItem?.displayMainLayout && <div>
                  {this.props.applicationBar}
                </div>}
                <div
                  className={styles.subContainer}
                >
                  {currentNaviationItem?.displayMainLayout &&
                    <div
                      style={{
                        backgroundColor: theme.sideNavigationColor
                      }}
                      hidden={!sideNavigationItems || sideNavigationItems.length === 1} // Hide side nav menu if only one item.
                    >
                      {this.props.sideNavigation}
                    </div>}
                  <div
                    className={styles.mainContent}
                  >
                    {this.props.children}
                  </div>
                </div>
              </div>
          );
        }}
      </ThemeConsumer>
    );
  }
}

export const MainLayout = NavigationService.inject(
  _MainLayout,
);

