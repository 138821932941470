import {
  React,
  bind
} from "$Imports/Imports";

import {
  KeyboardDatePicker,
  TextField,
  TextFieldProps
} from "$Imports/MaterialUIComponents";

const styles: {
  dateInput: string;
} = require("./DateRangePicker.scss");

type DateType = "start" | "end";

// TODO: KT-1231, use interface/object subprops instead of individual start*/end* props
interface IOwnProps {
  startDate?: Date;
  startError?: string | null;
  startLabel?: string;
  endDate?: Date;
  endError?: string | null;
  endLabel?: string;
  disabled?: boolean;
  onChange: (startDate: Date | null, endDate: Date | null) => void;
}

interface IOwnState {
  startDate: Date | null;
  endDate: Date | null;
}

export class DateRangePicker extends React.PureComponent<IOwnProps, IOwnState> {
  state: IOwnState = {
    startDate: this.props.startDate ?? null,
    endDate: this.props.endDate ?? null
  }

  componentDidUpdate(prev: IOwnProps) {
    const {
      startDate,
      endDate
    } = this.props;

    if (startDate !== prev.startDate) {
      this.setState({
        startDate: startDate ?? null
      });
    }

    if (endDate !== prev.endDate) {
      this.setState({
        endDate: endDate ?? null
      });
    }
  }

  @bind
  private _onChange(date: Date | null, dateType: DateType) {
    let {
      startDate,
      endDate
    } = this.state;

    if (dateType === "start") {
      startDate = date;
    } else {
      endDate = date;
    }

    this.setState({ startDate: startDate, endDate: endDate });

    this.props.onChange(startDate, endDate);
  }

  render() {
    const {
      startDate,
      endDate
    } = this.state;

    const {
      startError,
      startLabel,
      endError,
      endLabel,
      disabled
    } = this.props;

    return (
      <>
        <KeyboardDatePicker
          className={styles.dateInput}
          label={startLabel ?? "From"}
          value={startDate}
          onChange={(date: Date | null) => this._onChange(date, "start")}
          inputFormat="MM/DD/YYYY"
          disabled={disabled}
          renderInput={(props: TextFieldProps) => (
            <TextField
              {...props}
              error={!!startError}
              helperText={startError}
            />
          )}
        />
        <KeyboardDatePicker
          className={styles.dateInput}
          label={endLabel ?? "To"}
          value={endDate}
          onChange={(date: Date | null) => this._onChange(date, "end")}
          inputFormat="MM/DD/YYYY"
          disabled={disabled}
          renderInput={(props: TextFieldProps) => (
            <TextField
              {...props}
              error={!!endError}
              helperText={endError}
            />
          )}
        />
      </>
    );
  }
}
