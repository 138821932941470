import {
  React,
} from "$Imports/Imports";

import {
  CustomerPortalHomeView
} from "./CustomerPortalHomeView";

interface ICustomerPortalHomeViewPageBaseProps {

}

export class CustomerPortalHomeViewPage extends React.Component<ICustomerPortalHomeViewPageBaseProps> {
  render() {
    return ( 
      <CustomerPortalHomeView />
    );
  }
}
