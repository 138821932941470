import {
  _,
  React,
  bind
} from "$Imports/Imports";

import { 
  Customer,
  CustomerContact,
  CustomerQuote,
  Region
} from "$Generated/api";

import {
  CardLinedHeader,
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  ContactPersonDisplay
} from "./ContactPersonDisplay";

import {
  Button
} from "$Imports/MaterialUIComponents";

import { getFormattedZipPostalCode } from "$Shared/utilities/helpers";

interface ICustomerInfoBaseProps {
  currentQuote: CustomerQuote;
  currentCustomer?: Customer;
  contacts?: CustomerContact[];
  regions?: Region[];
  printView?: boolean;
  addContact?: (contact?: CustomerContact) => void;
}

type ICustomerInfoProps = ICustomerInfoBaseProps;

const styles: {
  container:string
  column: string,
  buttonContainer: string
} = require("./CustomerInfo.scss");

export class CustomerInfo extends React.PureComponent<ICustomerInfoProps> {

  @bind
  private _addContact() {
    if (this.props.addContact) {
      this.props.addContact();
    }
  }

  render() {
  
    const {
      currentQuote,
      currentCustomer,
      contacts,
      regions,
      printView
    } = this.props;

    const disableContact = (!!currentQuote.quoteStatus && currentQuote.quoteStatus !== "Pending") || !!printView;
    
    return (
      <CardLinedHeader title={printView ? "Customer Contact Information" : "Verify Contact Information"}>
        {currentCustomer ?
          <div className={styles.container}>
            <div className={styles.column}>
              <div><strong>{currentCustomer.customerName ?? ""}</strong></div>
              <div><strong>{currentCustomer.address1 ?? ""}</strong></div>
              <div><strong>{currentCustomer.address2 ?? ""}</strong></div>
              <div><strong>{`${currentCustomer.city ?? ""}, ${(currentCustomer.region?.regionAbbreviation ?? _.find(regions, r => r.id === currentCustomer.regionId)?.regionAbbreviation ) ?? ""}, ${getFormattedZipPostalCode(currentCustomer.zipPostalCode) ?? ""}`}</strong></div>
            </div>
            <div className={styles.column}>
              <ContactPersonDisplay
                currentQuote={currentQuote}
                currentCustomer={currentCustomer}
                contacts={contacts ?? []} 
                disableContact={disableContact} 
                isPrintView={printView || false} />
            </div>
            <div className={styles.buttonContainer}>
              {!disableContact &&
                <UserAccessControl roles={["contact:edit"]}>
                  <Button onClick={this._addContact}>
                    Add
                  </Button>
                </UserAccessControl>
              }
            </div>
          </div> : undefined}
      </CardLinedHeader>
    );
  }
}
