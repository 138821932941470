import {
  React,
  bind,
  _,
  AuthContextProps,
  withAuth
} from "$Imports/Imports";

import {
  SideNavigationMenuItem
} from "./SideNavigationMenuItem";

import {
  ThemeConsumer
} from "$Providers/index";

import {  
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  INavigationItem
} from "$Utilities/navigation";

import {
  ThemeProvider,
  createTheme
} from "$Imports/MaterialUIStyles";

import {
  INavigationServiceInjectedProps,
  NavigationService
} from "$State/NavigationFreezerService";

import {
  MenuList
} from "$Imports/MaterialUIComponents";

interface ISideNavigationBaseProps { }

type ISideNavigationProps = ISideNavigationBaseProps 
& INavigationServiceInjectedProps
& AuthContextProps;

class _SideNavigation extends React.Component<ISideNavigationProps> {

  @bind
  private _onChange(e: React.MouseEvent<HTMLLIElement, MouseEvent>, newValue: INavigationItem) {
    this.props.navigationService.navigateTo(newValue.url);
  }

  render() {
    let navDetails = this.props.navigationService.getParentPath(window.location.pathname);
    let sideNav = this.props.navigationService.getSideNavigationItems(); 
    this.props.navigationService.updateTitle();

    return (
      <ThemeConsumer>
        {(context) => {
          const MenuListTheme = createTheme(context.themeConfig.themeOptions, context.themeConfig.sideNavigationMenu);
          return (
            <ThemeProvider
              theme={MenuListTheme}
            >
              <MenuList
                style={{
                  width: "200px"
                }}
              >
                {(_.map(sideNav, (n, nIdx) => (
                  <SideNavigationMenuItem
                    key={nIdx}
                    navigationItem={n}
                    selected={(n.url === (navDetails === null ? null : navDetails.url))}
                    onMenuItemClick={this._onChange}
                    securityContext={SharedSecurityContext.getUser()}
                  />
                )))}
              </MenuList>
            </ThemeProvider>
          );
        }}
      </ThemeConsumer>
    );
  }
}

export const SideNavigation = withAuth(
  NavigationService.inject(
  _SideNavigation,
  )
);