import {
  bind,
  React,
  moment,
  _
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  DateRangePicker,
  SearchControlsContainer
} from "$Imports/CommonComponents";

import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from "$Imports/MaterialUIComponents";

import {
  CustomerQuotesSearchCriteria,
  CustomerQuotesSearchCriteriaDateTypeEnum,
  CustomerQuotesSearchCriteriaQuoteStatusesEnum,
} from "$Generated/api";

import {
  DEFAULT_CUSTOMER_QUOTE_SEARCH,
  ICustomerQuoteServiceInjectedProps,
  CustomerQuoteService,
  QuoteSearchValidationSchema
} from "$State/CustomerQuotesFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

interface IOwnProps {
  onSubmit: (search: CustomerQuotesSearchCriteria) => void;
}

type OwnProps = IOwnProps
  & ICustomerQuoteServiceInjectedProps;

interface IOwnState {
  searchCriteria: CustomerQuotesSearchCriteria;
  searchDisabled: boolean;
  errors: ValidationError | null;
}

const styles: {
  container: string;
} = require("./CustomerQuotesSearchForm.scss");

const statusArray: CustomerQuotesSearchCriteriaQuoteStatusesEnum[] = ["Declined", "Expired", "Pending", "Requested"];

class _CustomerQuotesSearchForm extends React.PureComponent<OwnProps, IOwnState> {
  state: IOwnState = {
    searchCriteria: { ...DEFAULT_CUSTOMER_QUOTE_SEARCH },
    searchDisabled: true,
    errors: null
  };

  @bind
  private _onQuoteNumberChange(text: string): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        quoteOrPONumber: text ?? ""
      },
      searchDisabled: false
    }));
  }

  @bind
  private _onQuoteStatusChange(e: SelectChangeEvent<CustomerQuotesSearchCriteriaQuoteStatusesEnum[]>) {
    const allClicked = e.target.value.includes("All" as CustomerQuotesSearchCriteriaQuoteStatusesEnum);
    if (allClicked) {
      this.setState((prev) => ({
        searchCriteria: {
          ...prev.searchCriteria,
          quoteStatuses: prev.searchCriteria?.quoteStatuses && prev.searchCriteria.quoteStatuses.length < 4 ? statusArray : []
        },
        searchDisabled: false
      }));
    } else {
      this.setState((prev) => ({
        searchCriteria: {
          ...prev.searchCriteria,
          quoteStatuses: e.target.value as CustomerQuotesSearchCriteriaQuoteStatusesEnum[]
        },
        searchDisabled: false
      }));
    }
  }

  @bind
  private _onDateTypeChange(type: CustomerQuotesSearchCriteriaDateTypeEnum): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        dateType: type ?? "QuoteDate" as CustomerQuotesSearchCriteriaDateTypeEnum
      },
      searchDisabled: false
    }));
  }

  @bind
  private _onDateRangeChange(start: Date | null, end: Date | null): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        startDate: start ? moment(start).startOf('day').toDate() : undefined,
        endDate: end ? moment(end).endOf('day').toDate() : undefined
      },
      searchDisabled: false
    }));
  }

  @bind
  private async _onSubmit(): Promise<void> {
    const { searchCriteria } = this.state;

    const errors =await validateSchema(QuoteSearchValidationSchema, searchCriteria, {
      abortEarly: false
    });
    this.setState({ errors: errors });
    if (errors) {
      return;
    }

    this.props.onSubmit(searchCriteria);
    this.setState({ searchDisabled: true });
  }

  @bind
  private _onClear(): void {
    this.setState({
      searchCriteria: { ...DEFAULT_CUSTOMER_QUOTE_SEARCH }
    });

    this.props.onSubmit(DEFAULT_CUSTOMER_QUOTE_SEARCH);

    this.setState({ 
      errors: null,
      searchDisabled: true
    });
  }

  componentDidMount() {
    const { searchCriteria } = this.props.customerQuotesService.getState();

    this.setState({
      searchCriteria: { ...searchCriteria },
      searchDisabled: true
    });
  }

  componentWillUnmount() {
    this.props.customerQuotesService.clearFreezer();
  }

  render() {
    const {
      searchCriteria,
      searchDisabled,
      errors
    } = this.state;

    const validationParser = new ValidationErrorParser<CustomerQuotesSearchCriteria>(errors);
    const startError = validationParser.validationMessage("startDate");
    const endError = validationParser.validationMessage("endDate");

    return (
      <SearchControlsContainer
        className={styles.container}
        onSubmit={this._onSubmit}
        onClear={this._onClear}
        searchDisabled={searchDisabled}
      >
        <TextField
          style={{ flex: "0 0 7rem" }}
          label={"Quote # or PO #"}
          value={searchCriteria.quoteOrPONumber ?? ""}
          onChange={(event) => this._onQuoteNumberChange(event.target.value)}
        />

        <FormControl style={{ flex: "0 0 10rem" }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={searchCriteria?.quoteStatuses ?? []}
            onChange={this._onQuoteStatusChange}
            multiple
            renderValue={(selected) => {
              if (selected.length === 4) {
                return <i>All</i>;
              }
              else {
                return _.map(selected as CustomerQuotesSearchCriteriaQuoteStatusesEnum[], (s, idx) => {
                  return (
                    <span key={idx}>
                      {s}
                      <>{idx !== (selected as CustomerQuotesSearchCriteriaQuoteStatusesEnum[]).length - 1 ? ", " : ""}</>
                    </span>
                  )
                })
              }
            }}
          >
            <MenuItem value={"All"}>
              <Checkbox checked={searchCriteria?.quoteStatuses?.length === 4} />
              <i><ListItemText primary={"All"} /></i>
            </MenuItem>
            <hr/>
            {
              statusArray.map((status) => {
                return (
                  <MenuItem key={status} value={status}>
                    <Checkbox checked={_.findIndex(searchCriteria?.quoteStatuses, s => s === status) > -1} />
                    <ListItemText primary={status} />
                  </MenuItem>
                )
              })
            }
          </Select>
        </FormControl>

        <FormControl style={{ flex: "0 0 10rem" }}>
          <InputLabel>Date Type</InputLabel>
          <Select
            value={searchCriteria.dateType}
            onChange={(event) => this._onDateTypeChange(event.target.value as CustomerQuotesSearchCriteriaDateTypeEnum)}
          >
            <MenuItem value={"QuoteDate"}>Quote Date</MenuItem>
            <MenuItem value={"DeliveryDate"}>Delivery Date</MenuItem>
            <MenuItem value={"ExpirationDate"}>Expiration Date</MenuItem>
          </Select>
        </FormControl>

        <div style={{ display: "inline-flex", flex: "0 0 17rem", gap: "0.5rem" }}>
          <DateRangePicker
            startDate={searchCriteria.startDate}
            startError={startError}
            endDate={searchCriteria.endDate}
            endError={endError}
            onChange={this._onDateRangeChange}
          />
        </div>
      </SearchControlsContainer>
    );
  }
}

export const CustomerQuotesSearchForm = CustomerQuoteService.inject(
  _CustomerQuotesSearchForm
);