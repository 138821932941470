
import {
  React,
  bind,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  Tarp,
  CustomerQuote
} from "$Generated/api";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormHelperText
} from "$Imports/MaterialUIComponents"

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

interface ITarpEntryBaseProps {
  tarpId?: number,
  viewOnly?: boolean,
  tarpOptions?: Tarp[],
  onTarpChange?: (tarpId: string) => void,
  validationErrors?: ValidationError | null
}

export class TarpEntry extends React.PureComponent<ITarpEntryBaseProps> {

  @bind
  private _onTarpChange(event: SelectChangeEvent){
    if (this.props.onTarpChange) {
      this.props.onTarpChange(event.target.value as string)
    }
  }

  render() {

    const {
      tarpId,
      tarpOptions,
      validationErrors,
      viewOnly
    } = this.props;

    const now = moment();
    const displayedTarpOptions = tarpOptions ? viewOnly ? tarpOptions : 
    tarpOptions.filter(t => t.isActive && t.tarpValues && t.tarpValues.some(v => v.endDateTime === null || moment(v.endDateTime).isAfter(now) || v.id === -1)) : // the No Tarp option has a TarpValue.Id = -1
      [];

    const validationsParserQuote = new ValidationErrorParser<CustomerQuote>(validationErrors);

    return (
      <FormControl fullWidth error={!validationsParserQuote.isValidDeep(`tarpId`)}>
        <InputLabel>Tarps *</InputLabel>
        <Select
          value={tarpId?.toString() ?? ""}
          disabled={viewOnly}
          onChange={this._onTarpChange}>
          {
            displayedTarpOptions?.map((t, i) =>
              <MenuItem value={t.id} key={i}>
                {t.tarpName}
              </MenuItem>
            )
          }
        </Select>
        <FormHelperText>
          {validationsParserQuote.validationMessageDeep(`tarpId`)}
        </FormHelperText>
      </FormControl>
    )
  }
}