import {
  React,
  _,
  User
} from "$Imports/Imports";

import {
  ISecurityContext
} from "$Shared/utilities/Security/ISecurityContext";

import {
  CustomerPortalHomeViewPage
} from "$Pages/CustomerPortalHomeView"

import {
  CustomerQuoteViewPage,
} from "$Pages/CustomerQuoteView";

import {
  CustomerQuotesListViewPage,
} from "$Pages/CustomerQuotesListView";

import {
  PrintQuoteView
} from "$Pages/PrintQuoteView";

import {
  SvgIconProps
} from "$Shared/imports/MaterialUIStyles";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings"

interface IMatchProps<P> {
  match?: {
    params?: P;
  };
}

export interface INavigationItem {
  url: string;
  redirectUrls?: string[];
  label: string;
  externalLink: boolean;
  enabled: boolean | (<T = ISecurityContext>(navItem: INavigationItem, securityContext: T) => boolean);
  hasAccess?: boolean | ((navItem: INavigationItem, securityContext: User | null) => boolean);
  childNavigation?: INavigationItem[];
  icon?: React.ComponentType<SvgIconProps>;
  component?: React.ComponentClass | React.FunctionComponent;
  title?: string;
  isRoot: boolean;
  rootUrl: string;
  rootUrlLabel?: string;
  rolesToAccess?: string[];
  companiesToAccess?: string[];
  displayMainLayout?: boolean;
}

export interface ISideNavigationItem extends INavigationItem {
  rootUrlPageUrl: string;
}

function hasAccessByContext(navItem: INavigationItem, securityContext: User | null): boolean {
  var canAccess = false;
  const roles = SharedSecurityContext.getRoles(securityContext);
  
  if (roles && navItem.rolesToAccess) {
    var overlap = _.intersection(navItem.rolesToAccess, roles);
    canAccess = overlap.length > 0;
  }

  return canAccess;
}

export const mainNavigation: INavigationItem[] = [
  {
    redirectUrls: ["/"],
    url: "/home",
    label: "Home",
    externalLink: false,
    component: CustomerPortalHomeViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["customer-portal:manager", "customer-portal:salesrep"],
    enabled: true,
    isRoot: true,
    rootUrl: "/home",
    rootUrlLabel: "Home",
    displayMainLayout: true
  },
  {
    url: "/newQuote",
    label: "New Quote",
    externalLink: false,
    component: CustomerQuoteViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["quote:create"],
    enabled: true,
    isRoot: false,
    rootUrl: "/home",
    displayMainLayout: true
  },
  {
    url: "/quotes",
    label: "Quote List",
    externalLink: false,
    component: CustomerQuotesListViewPage,
    hasAccess: hasAccessByContext,
    rolesToAccess: ["quotes:page"],
    enabled: true,
    isRoot: false,
    rootUrl: "/home",
    displayMainLayout: true,
    childNavigation: [
      {
        // TODO: Update to use quote Id or quoteNumber consistently
        url: "/quotes/:quoteId",
        label: "Quote",
        externalLink: false,
        component: (props: React.PropsWithChildren<IMatchProps<{ quoteId: string }>>) => {
          const componentProps: { quoteId: string } = props.match && props.match.params ? props.match.params : { quoteId: "" };
          return React.createElement(CustomerQuoteViewPage, componentProps);
        },
        hasAccess: hasAccessByContext,
        rolesToAccess: ["quote:create"],
        enabled: true,
        isRoot: true,
        rootUrl: "/quotes",
        displayMainLayout: true
      }
    ]
  },
  {
    url: "/printQuote/:quoteId",
    label: "Print Quotes",
    externalLink: false,
    component: (props: React.PropsWithChildren<IMatchProps<{ quoteId: string }>>) => {
      const componentProps: { quoteId: string } = props.match && props.match.params ? props.match.params : { quoteId: "" };
      return React.createElement(PrintQuoteView, componentProps);
    },
    hasAccess: hasAccessByContext,
    rolesToAccess: ["quote:print"],
    enabled: true,
    isRoot: true,
    rootUrl: "/printQuote",
    displayMainLayout: false
  }
];