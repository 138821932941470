import {
  React,
  _
} from "$Imports/Imports";

import {
  Tooltip
} from "$Imports/MaterialUIComponents";

const styles: {
  text: string;
  truncated: string;
} = require("./TruncatedTextDisplay.scss");

interface ITruncatedTextDisplayProps {
  text: string;
  maxDisplayLength: number;
}

export class TruncatedTextDisplay extends React.PureComponent<ITruncatedTextDisplayProps> {
  render() {
    const {
      text,
      maxDisplayLength
    } = this.props;

    const truncate = text.length > maxDisplayLength;
    const displayValue = truncate ? text.substring(0, maxDisplayLength) : text;

    return (
      <Tooltip title={text} placement="bottom">
        <div className={truncate ? styles.truncated : styles.text}>
          {displayValue}
        </div>
      </Tooltip>
    );
  }
}