// extracted by mini-css-extract-plugin
var _1 = "CustomerDetailsCard__address__mo22e";
var _2 = "CustomerDetailsCard__card__gX2ld";
var _3 = "CustomerDetailsCard__column__yti2O";
var _4 = "CustomerDetailsCard__fieldBlock__tFvIE";
var _5 = "CustomerDetailsCard__fieldLabel__zn62W";
var _6 = "CustomerDetailsCard__fieldPair__KhN2y";
var _7 = "CustomerDetailsCard__fieldValue__wtM5v";
var _8 = "CustomerDetailsCard__infoContainer__oRAdU";
var _9 = "CustomerDetailsCard__subcolumnLarge__aTMIK";
var _a = "CustomerDetailsCard__subcolumnSmall__oepFq";
export { _1 as "address", _2 as "card", _3 as "column", _4 as "fieldBlock", _5 as "fieldLabel", _6 as "fieldPair", _7 as "fieldValue", _8 as "infoContainer", _9 as "subcolumnLarge", _a as "subcolumnSmall" }
