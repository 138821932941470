import { DisplayFormattedFeet } from "$Imports/CommonComponents";
import {
  React,
  _,
  bind,
  NumberFormat,
  NumberFormatProps
} from "$Imports/Imports";

import {
  TextField,
  TextFieldProps,
  IconButton,
  FormControlLabel,
  FormHelperText,
  Typography
} from "$Imports/MaterialUIComponents";

import {
  Clear
} from "$Imports/MaterialUIIcons";

const styles: {
  table: string,
  label: string,
  iconButton: string,
  iconButtonCell: string,
} = require("./FeetInputField.scss");

interface IFeetInputFieldBasedProps {
  onFeetValueChange: (newValue?: number) => void;
  value?: number;
  widthOverride?: string;
  label?: string;
}

export type IFeetInputFieldProps = IFeetInputFieldBasedProps & NumberFormatProps<TextFieldProps>;

export class FeetInputField extends React.PureComponent<IFeetInputFieldProps> {

  private isEmpty(value: any): boolean {
    return value === 0 || value === "";
  }

  @bind _onFeetChange(e: any) {
    if (!this.isEmpty(e.currentTarget.value) && this.props.value !== undefined) {
      this.props.onFeetValueChange(this.props.value % 12 + parseInt(e.currentTarget.value) * 12);
    } else if (!this.isEmpty(e.currentTarget.value)) {
      this.props.onFeetValueChange(parseInt(e.currentTarget.value) * 12);
    } else if (this.props.value !== undefined) {
      this.props.onFeetValueChange(this.props.value % 12);
    }
  }

  @bind
  private _onInchChange(e: any) {
    if (!this.isEmpty(e.currentTarget.value) && this.props.value !== undefined) {
      this.props.onFeetValueChange(Math.floor(this.props.value / 12) * 12 + parseInt(e.currentTarget.value));
    } else if (!this.isEmpty(e.currentTarget.value)) {
      this.props.onFeetValueChange(parseInt(e.currentTarget.value));
    } else if (this.props.value !== undefined) {
      this.props.onFeetValueChange(Math.floor(this.props.value / 12) * 12);
    } 
  }

  @bind 
  private _selectAllOnFocus(event: React.FocusEvent<HTMLInputElement>) {
    event.target.select();
  }

  @bind
  private _clear() {
    this.props.onFeetValueChange(undefined);
  }

  render() {
    const {
      onFeetValueChange,
      value,
      label,
      error,
      helperText,
      widthOverride,
      ...passthroughProps
    } = this.props;

    return (
      <table className={styles.table}>
        <tbody>
          <tr>
            {label && <td className={styles.label}>
              <Typography
                variant="body2"
                color={error ? "error" : "inherit"}
              >
                {label}
              </Typography>
            </td>}
            {!this.props.disabled ?
              <>
                <td style={{
                  paddingRight: "5px"
                }}>
                  <NumberFormat
                    {...passthroughProps}
                    error={error}
                    label={"ft"}
                    value={value === undefined ? "" : Math.floor(value / 12) === 0 ? "" : Math.floor(value / 12)}
                    onBlur={this._onFeetChange}
                    onFocus={this._selectAllOnFocus}
                    customInput={TextField}
                    allowNegative={false}
                    decimalScale={0}
                    allowLeadingZeros={false}
                    style={{ width: widthOverride ?? "48px" }}
                  />
                </td>
                <td style={{
                  padding: 0
                }}>
                  <NumberFormat
                    {...passthroughProps}
                    error={error}
                    label={"in"}
                    value={value === undefined ? "" : value % 12 === 0 ? "" : value % 12}
                    onBlur={this._onInchChange}
                    onFocus={this._selectAllOnFocus}
                    customInput={TextField}
                    allowNegative={false}
                    decimalScale={0}
                    allowLeadingZeros={false}
                    style={{ width: widthOverride ?? "48px" }}
                  />
                </td> 
              </> : <DisplayFormattedFeet value={this.props.value} />}
            <td className={styles.iconButtonCell}>
             {!this.props.disabled &&
              <IconButton
                size="small"
                onClick={this._clear}
                disabled={this.props.disabled}
              >
                <Clear className={styles.iconButton} />
              </IconButton>
            }
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              <FormHelperText
                error={error}
                children={helperText ?? ""}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
