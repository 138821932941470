import {
  React,
  bind
} from "$Imports/Imports";

import {
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps,
  CustomerType
} from "$State/QuoteEntryFreezerService";

import {
  QuoteZipCodeMileageService,
  IQuoteZipCodeMileageServiceInjectedProps
} from "$State/QuoteZipCodeMileageFreezerService";

import {
  AdvanceTextField,
  CardLinedHeader,
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  IconButton,
} from "$Imports/MaterialUIComponents";

import {
  Room
} from "$Imports/MaterialUIIcons";

import {
  CityStateSearchModal
} from "../../pages/CustomerQuoteView/CityStateSearchModal";

import {
  QuoteDetailCard
} from "../../pages/CustomerQuoteView/QuoteDetailCard";

import {
  ZipCodePair,
  Place
} from "$Generated/api";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import { getTrimmedZipPostalCode } from "$Shared/utilities/helpers";

const styles: {
  shipperConsigneeDiv: string,
  addressDiv: string,
  label: string
} = require("./CommodityShippingDistanceInput.scss");

interface ICommodityShippingDistanceInputState {
  cityStateSearchModalIsOpen: boolean,
  cityStateCustomerSearchType: CustomerType | undefined;
}

type IQuoteMileageServiceProps = IQuoteEntryServiceInjectedProps & IQuoteZipCodeMileageServiceInjectedProps;

class _CommodityShippingDistanceInput extends React.Component<IQuoteMileageServiceProps, ICommodityShippingDistanceInputState> {

  state = {
    cityStateSearchModalIsOpen: false,
    cityStateCustomerSearchType: undefined,
  }

  @bind
  private _onCustomerCityStateSearchClick(customerType: CustomerType) {
    this.setState({
      cityStateSearchModalIsOpen: true,
      cityStateCustomerSearchType: customerType
    });
  }

  @bind
  private async _onConsigneeChanged(zipcode: string, place?: Place) {
    const trimmedZipCode = getTrimmedZipPostalCode(zipcode);
    this.props.QuoteEntryService.updateCustomerQuote({ consigneeZipPostalCode: trimmedZipCode });

    const val = await this.props.QuoteZipCodeMileageService.updateConsigneeZipCode(trimmedZipCode ?? "");
    this.props.QuoteEntryService.updateCustomerQuote({ mileage: val });

    if (place) {
      this.props.QuoteEntryService.setConsigneePlace(place);
    }
    else {
      const places = await this.props.QuoteZipCodeMileageService.queryForPlace(trimmedZipCode ?? "", "consignee");
      this.props.QuoteEntryService.setConsigneePlace(places && places.length > 0 ? places[0] : null);
    }
  }

  @bind
  private async _onShipperChanged(zipcode: string, place?: Place) {
    const trimmedZipCode = getTrimmedZipPostalCode(zipcode);
    this.props.QuoteEntryService.updateCustomerQuote({ shipperZipPostalCode: trimmedZipCode });

    const val = await this.props.QuoteZipCodeMileageService.updateShipperZipCode(trimmedZipCode ?? "");
    this.props.QuoteEntryService.updateCustomerQuote({ mileage: val });

    if (place) {
      this.props.QuoteEntryService.setShipperPlace(place);
    }
    else {
      const places = await this.props.QuoteZipCodeMileageService.queryForPlace(trimmedZipCode ?? "", "shipper");
      this.props.QuoteEntryService.setShipperPlace(places && places.length > 0 ? places[0] : null);
    }
  }

  @bind
  private _onCustomerCityStateSearchClose() {
    this.setState({
      cityStateSearchModalIsOpen: false,
      cityStateCustomerSearchType: undefined
    })
  }

  @bind
  private _onCustomerLocationSelect(place: Place, customerType: CustomerType | undefined) {
    const zipCode = place.zipPostalCode;
    if (zipCode) {
      if (customerType === "Shipper") {
        this._onShipperChanged(zipCode, place);
      } else {
        this._onConsigneeChanged(zipCode, place);
      }
    }

    this._onCustomerCityStateSearchClose();
  }

  render() {
    const {
      customerQuote,
      viewOnly,
      selectedShipper,
      selectedConsignee
    } = this.props.QuoteEntryService.getState();

    const {
      shipperConsigneeValidationErrors,
    } = this.props.QuoteZipCodeMileageService.getState();

    const validationsParserZipcode = new ValidationErrorParser<ZipCodePair>(shipperConsigneeValidationErrors);

    const {
      cityStateSearchModalIsOpen,
      cityStateCustomerSearchType
    } = this.state;

    return (
      <CardLinedHeader title={"Enter Shipper and Consignee Addresses"} color="black" style={{ marginTop: "10px", marginBottom: "15px" }}>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div>
            <div className={styles.label}>Shipper Address</div>
            <div className={styles.shipperConsigneeDiv}>
              <div className={styles.addressDiv}>
                {selectedShipper?.city ?? ""}
                {selectedShipper?.city && selectedShipper?.stateProvince ? ", " : ""}
                {selectedShipper?.stateProvince ?? ""}
                {selectedShipper === null && <em>No results.</em>}
              </div>
              <AdvanceTextField
                label="Shipper Zipcode"
                onDebouncedChange={this._onShipperChanged}
                debounceWaitTime={750}
                size={"small"}
                disabled={viewOnly}
                value={customerQuote.shipperZipPostalCode ?? ""}
                error={!validationsParserZipcode.isValid("originZipPostalCode")}
                helperText={validationsParserZipcode.validationMessage("originZipPostalCode")}
              />
              <IconButton
                disabled={viewOnly || cityStateSearchModalIsOpen}
                onClick={() => this._onCustomerCityStateSearchClick("Shipper")}
              >
                <Room />
              </IconButton>
            </div>

            <div className={styles.label}>Consignee Address</div>
            <div className={styles.shipperConsigneeDiv}>
              <div className={styles.addressDiv}>
                {selectedConsignee?.city ?? ""}
                {selectedConsignee?.city && selectedConsignee?.stateProvince ? ", " : ""}
                {selectedConsignee?.stateProvince ?? ""}
                {selectedConsignee === null && <em>No results.</em>}
              </div>
              <AdvanceTextField
                label="Consignee Zipcode"
                onDebouncedChange={this._onConsigneeChanged}
                debounceWaitTime={750}
                disabled={viewOnly}
                size={"small"}
                value={customerQuote.consigneeZipPostalCode ?? ""}
                error={!validationsParserZipcode.isValid("destZipPostalCode")}
                helperText={validationsParserZipcode.validationMessage("destZipPostalCode")}
              />
              <IconButton
                disabled={viewOnly || cityStateSearchModalIsOpen}
                onClick={() => this._onCustomerCityStateSearchClick("Consignee")}
              >
                <Room />
              </IconButton>
            </div>
          </div>

          <QuoteDetailCard
            title="Miles"
            majorValue={
              <DisplayFormattedNumber
                value={customerQuote?.mileage}
                formatString="0,000"
                emptyDisplay="-"
              />
            }
            style={{ flexGrow: 1, alignSelf: "center" }}
          />
        </div>

        <CityStateSearchModal
          isOpen={cityStateSearchModalIsOpen}
          customerType={cityStateCustomerSearchType}
          onClose={this._onCustomerCityStateSearchClose}
          onLocationSelect={this._onCustomerLocationSelect}
        />
      </CardLinedHeader>
    );
  }
}

export const CommodityShippingDistanceInput = QuoteZipCodeMileageService.inject(
  QuoteEntryService.inject(_CommodityShippingDistanceInput));