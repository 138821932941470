import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  Company
} from "$Generated/api";

import {
  DialogActions,
  DialogTitle,
  DialogContent,
  Dialog,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel
} from "$Imports/MaterialUIComponents";

interface ICompanySelectModalProps {
  isOpen: boolean;
  companies: Company[];
  selectedCompany: string;
  selectCompany: (newCompany: string) => void;
  onClose: () => void;
}

export class CompanySelectModal extends React.PureComponent<ICompanySelectModalProps> {

  @bind
  private _onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const key = event.target.value;
    this.props.selectCompany(key);
  }

  @bind
  private _onClose() {
    this.props.onClose();
  }

  render() {
    const {
      isOpen,
      selectedCompany,
      companies
    } = this.props;

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle>
          Select Company
        </DialogTitle>
        <DialogContent>
          <RadioGroup name="company" value={selectedCompany} onChange={this._onChange}>
            {_.map(companies, (c, idx) => { return (
              <FormControlLabel
                value={c.companyKey}
                control={<Radio />} 
                label={`Kaiser ${c.companyName}`}
                key={idx}
              />
            )})}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this._onClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}