import {
  React,
  moment
} from "$Imports/Imports";

import {
  getTimeZone
} from "$Shared/utilities/dateTimeUtil";

interface IDisplayFormattedDatetimeProps {
  value?: Date | null;
  formatString: string;
  emptyDisplay?: string;
  showTimeZone?: boolean;
  prefix?: string;
  manualOffset?: boolean | null;
}

export class DisplayFormattedDatetime extends React.PureComponent<IDisplayFormattedDatetimeProps> {
  render() {
    const {
      value,
      formatString,
      emptyDisplay,
      showTimeZone,
      prefix,
      manualOffset
    } = this.props;

    const utcOffset = -(moment(value).utcOffset());

    let formattedValue = value !== undefined && value !== null 
      ? manualOffset
      ? moment(value).local().add(utcOffset, "m").format(formatString)
      : moment(value).local().format(formatString) 
      : emptyDisplay != undefined 
      ? emptyDisplay 
      : "";

    if (prefix) {
      formattedValue = prefix + formattedValue;
    }

    if (showTimeZone && value !== null && value !== undefined) {
      const timezone = getTimeZone(value);
      // \xa0 = &nbsp;
      formattedValue = formattedValue + "\xa0" + timezone;
    }
    return formattedValue;
  }
}