import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  NullableOptional
} from "$Imports/Imports";

import {
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  Customer,
  CustomerResponseBase,
  CustomerApiFactory,
  CustomerContact,
  ContactType,
  CustomerContactResponseBase,
  CommodityExclusion
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ISortState
} from "$Imports/CommonComponents";

import yup from "$Shared/utilities/yupExtension";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  QuoteEntryService
} from "./QuoteEntryFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

export const CustomerContactValidationSchema: SchemaOf<NullableOptional<CustomerContact>> = yup.object({
  id: yup.number().notRequired(),
  customerId: yup.number().notRequired(), // "not required" in the sense of not user-editable
  customerUserId: yup.number()
  .transform((value: any) => value || undefined) // otherwise it complains about NaN
  .notRequired()
  .nullable(),
  firstName: yup.string()
    .required("First name is required")
    .max(150, "First name cannot be longer than 150 characters"),
  lastName: yup.string()
    .required("Last name is required")
    .max(150, "Last name cannot be longer than 150 characters"),
  nameSuffixId: yup.number()
    .transform((value: any) => value || undefined) // otherwise it complains about NaN
    .notRequired()
    .nullable(),
  emailAddress: yup.string()
    .required("Email is required")
    .email("Invalid email")
    .max(250, "Max length can not exceed 250 characters"),
  phoneNumber: yup.string()
    .required("Phone number is required")
    .phoneNumber("Invalid phone number")
    .max(20, "Phone number cannot exceed 20 characters"),
  cellNumber: yup.string()
    .notRequired()
    .phoneNumber("Invalid cell number")
    .max(20, "Cell number cannot exceed 20 characters")
    .nullable(),
  title: yup.string()
    .required("Title is required")
    .max(60, "Title cannot be longer than 60 characters"),
  notes: yup.string()
    .notRequired()
    .nullable()
    .allowEmpty()
    .max(250, "Notes cannot be longer than 250 characters"),
  contactTypeId: yup.number()
    .required("Contact type is required"),
  isPrimary: yup.boolean().notRequired(), // only one primary enforced serverside
  isActive: yup.boolean().notRequired(),
  isHidden: yup.boolean().notRequired(),
  createdOn: yup.date().notRequired(),
  modifiedOn: yup.date().notRequired(),
  // related objects
  contactType: yup.object().notRequired().nullable(),
  customer: yup.object().notRequired().nullable(),
  customerUser: yup.object().notRequired().nullable(),
  nameSuffix: yup.object().notRequired().nullable()
});

interface ICustomerDetailModalState {
  isOpen: boolean;
  editCustomer: Customer | undefined;
}

interface IContactModalState {
  isOpen: boolean;
  editContact: CustomerContact | undefined;
  validationErrors: ValidationError | null;
}

interface ICustomerDetailServiceState {
  customerDetailFetchResults: IAjaxState<Customer>;
  contactsFetchResults: IAjaxState<CustomerContact[]>;
  contactTypeFetchResults: IAjaxState<ContactType[]>;
  saveCustomerResults: IAjaxState<CustomerResponseBase>;
  saveContactResults: IAjaxState<CustomerContactResponseBase>;
  detailModalState: ICustomerDetailModalState;
  contactModalState: IContactModalState;
  contactSortState: ISortState;
  showInactiveContacts: boolean;
  commodityExclusionsFetchResults: IAjaxState<CommodityExclusion[]>;
}

const InjectedPropName = "customerDetailService";

const initialState = {
  customerDetailFetchResults: managedAjaxUtil.createInitialState(),
  contactsFetchResults: managedAjaxUtil.createInitialState(),
  contactTypeFetchResults: managedAjaxUtil.createInitialState(),
  saveCustomerResults: managedAjaxUtil.createInitialState(),
  saveContactResults: managedAjaxUtil.createInitialState(),
  commodityExclusionsFetchResults: managedAjaxUtil.createInitialState(),
  detailModalState: {
    isOpen: false,
    editCustomer: undefined,
  },
  contactModalState: {
    isOpen: false,
    editContact: undefined,
    validationErrors: null
  },
  contactSortState: {
    sortColumnName: "name",
    sortDirection: "asc",
  },
  showInactiveContacts: false
} as ICustomerDetailServiceState;

class CustomerDetailFreezerService extends FreezerService<ICustomerDetailServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  @bind
  public updateShowInactiveToggle(toggle: boolean) {
    this.freezer.get().set({showInactiveContacts: toggle});
  }
  
  public async fetchContactTypes(forceUpdate: boolean = false) {
    const { contactTypeFetchResults } = this.freezer.get();

    if (contactTypeFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "contactTypeFetchResults",
      params: {},
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getContactTypes();
      },
      onError: (err, errMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch contact type data.");
      }
    });
  }

  public async fetchCustomerDetailData(customerId: number, forceUpdate: boolean = false) {
    const { customerDetailFetchResults } = this.freezer.get();

    if (customerDetailFetchResults.hasFetched && customerDetailFetchResults.data?.id === customerId && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "customerDetailFetchResults",
      params: {
        customerId
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getCustomerById(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? "Failed to fetch customer data.");
      }
    });
  }

  public fetchCustomerContacts(customerId: number, forceUpdate: boolean = false, showInactive: boolean = false) {
    const { contactsFetchResults } = this.freezer.get();

    if (contactsFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "contactsFetchResults",
      params: {
        customerId,
        activeOnly: !showInactive
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getContactsForCustomer(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch customer contacts.");
      }
    });
  }

  public async saveCustomer() {
    let editCustomer = this.freezer.get().detailModalState.editCustomer?.toJS();

    if (editCustomer && editCustomer.id) {
      managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        ajaxStateProperty: "saveCustomerResults",
        params: {
          body: editCustomer
        },
        onExecute: (apiOptions, params, options) => {
          const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return factory.updateCustomer(params);
        },
        onError: (err, errorMessage) => {
          ErrorService.pushErrorMessage(err.body?.message ?? "Failed to save updated customer.");
        },
        onOk: (data: CustomerResponseBase) => {
          if (data.error) {
            ErrorService.pushErrorMessage("Failed to save updated customer.");
          } 
          else if (data.success) {
            if (data.data?.id) {
              this.fetchCustomerDetailData(data.data?.id, true);
            }
          }
        }
      });
    }

    this.closeDetailModal();
  }

  public async saveCustomerContact(attachToQuote: boolean = false) {
    const {
      showInactiveContacts,
      contactModalState
    } = this.freezer.get();
    
    const editContact = contactModalState.editContact?.toJS();
    const errors = await validateSchema(CustomerContactValidationSchema, editContact, {
      abortEarly: false
    });

    this.freezer.get().contactModalState?.set({ validationErrors: errors });

    if (errors) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "saveContactResults",
      params: {
        body: editContact
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        if (editContact?.id) {
          return factory.updateContact(params);
        } else {
          return factory.addContact(params);
        }
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? "Failed to add customer contact.");
      },
      onOk: (data: CustomerContactResponseBase) => {
        this.closeCustomerContactModal();
        
        if (attachToQuote && data.data?.id) {
          QuoteEntryService.updateSelectedContact(data.data.id);
        }

        if (editContact?.customerId) {
          return this.fetchCustomerContacts(editContact.customerId, true, showInactiveContacts);
        }
      }
    });
  }

  public openDetailModal() {
    const customerDetailFetchResults = this.freezer.get().customerDetailFetchResults.toJS();
    const customerData = customerDetailFetchResults.data;

    if (customerData?.customerHours) {
      customerData.customerHours = _.map(customerData.customerHours, (h) => ({
        ...h,
        openTime: h.openTime ? h.openTime.substring(0, 5) : h.openTime,
        closeTime: h.closeTime ? h.closeTime.substring(0, 5) : h.closeTime
      }));
    }

    if (customerData) {
      this.freezer.get().set({
        detailModalState: {
          isOpen: true,
          editCustomer: customerData
        }
      });
    }
  }

  public customerOnChange(updatedCustomer: Partial<Customer>) {
    this.freezer.get().detailModalState.editCustomer?.set(updatedCustomer);
  }

  public closeDetailModal() {
    this.freezer.get().set({
      detailModalState: initialState.detailModalState
    });
  }

  public openCustomerContactModal(contact?: CustomerContact) {
    const customerId = this.freezer.get().customerDetailFetchResults.data?.id;

    this.freezer.get().set({
      contactModalState: {
        isOpen: true,
        editContact: contact !== undefined ? _.clone(contact) :
        {
          customerId,
          isActive: true
        },
        validationErrors: null
      }
    });
  }

  public closeCustomerContactModal() {
    this.freezer.get().set({
      contactModalState: initialState.contactModalState
    });
  }

  public setContactSortState(sortState: Partial<ISortState>) {
    this.freezer.get().contactSortState.set(sortState);
  }

  public contactOnChange(updatedContact: Partial<CustomerContact>) {
    this.freezer.get().contactModalState.editContact?.set(updatedContact);
  }

  public async fetchCustomerCommodityExclusions(customerId: number | undefined, forceUpdate: boolean = false) {
    const {
      commodityExclusionsFetchResults
    } = this.freezer.get();

    if (!customerId || (commodityExclusionsFetchResults.hasFetched && !forceUpdate)) {
      return;
    }
    if (!customerId) {
      return;
    }
    
    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "commodityExclusionsFetchResults",
      params: {
        customerId
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getCommodityExclusionForCustomer(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body ?? "Failed to fetch commodity exclusions.");
      }
    })
  }
}

export const CustomerDetailService = new CustomerDetailFreezerService();
export type ICustomerDetailServiceInjectedProps = ReturnType<CustomerDetailFreezerService["getPropsForInjection"]>;