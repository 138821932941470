// extracted by mini-css-extract-plugin
var _1 = "QuoteView__actions__cabE7";
var _2 = "QuoteView__clearIcon__Sqdev";
var _3 = "QuoteView__errorIcon__i3YTl";
var _4 = "QuoteView__header__fbLkF";
var _5 = "QuoteView__icon__ydn2o";
var _6 = "QuoteView__infoIcon__WRrO1";
var _7 = "QuoteView__input__UXDr8";
var _8 = "QuoteView__mainContainer__EbmDO";
export { _1 as "actions", _2 as "clearIcon", _3 as "errorIcon", _4 as "header", _5 as "icon", _6 as "infoIcon", _7 as "input", _8 as "mainContainer" }
