import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState
} from "$Imports/Imports";

import {
  EquipmentType,
  EquipmentApiFactory
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

interface IEquipmentTypeServiceState {
  equipmentTypeFetchResults: IAjaxState<EquipmentType[]>;
  activeEquipmentTypes: EquipmentType[];
}

const InjectedPropName = "equipmentTypeService";

const initialState = {
  equipmentTypeFetchResults: managedAjaxUtil.createInitialState(),
  activeEquipmentTypes: []
} as IEquipmentTypeServiceState;

class EquipmentTypeFreezerService extends FreezerService<IEquipmentTypeServiceState, typeof InjectedPropName> {

  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchEquipmentTypes(forceUpdate: boolean = false) {
    const {
      equipmentTypeFetchResults
    } = this.freezer.get();

    if (equipmentTypeFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "equipmentTypeFetchResults",
      params: {},
      onExecute: (apiOptions, params, options) => {
        const factory = EquipmentApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getEquipmentTypes(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch equipment types.");
      },
      onOk: (data: EquipmentType[]) => {
        this.freezer.get().set({ activeEquipmentTypes: data });
      }
    });
  }
}

export const EquipmentTypeService = new EquipmentTypeFreezerService();
export type IEquipmentTypeServiceInjectedProps = ReturnType<EquipmentTypeFreezerService["getPropsForInjection"]>;
