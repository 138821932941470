import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  Card,
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  Edit
} from "$Imports/MaterialUIIcons";

import {
  Customer
} from "$Generated/api";

import {
  AjaxActionIndicator,
  CardLinedHeader,
  UserAccessControl
} from "$Imports/CommonComponents";

import { getFormattedZipPostalCode } from "$Shared/utilities/helpers";

interface ICustomerDetailsCardProps {
  customerId: number | undefined;
  customerData: Customer | null | undefined;
  isFetching: boolean;
  openDetailModal: () => void;
}

const styles: {
  card: string;
  infoContainer: string;
  subcolumnSmall: string;
  subcolumnLarge: string;
  address: string;
  fieldBlock: string;
  fieldPair: string;
  fieldLabel: string;
  fieldValue: string;
} = require("./CustomerDetailsCard.scss");

export class CustomerDetailsCard extends React.PureComponent<ICustomerDetailsCardProps> {

  @bind
  private _openDetailModal() {
    this.props.openDetailModal();
  }

  render() {
    const {
      isFetching,
      customerData
    } = this.props;

    return (
      <Card className={styles.card}>
        <CardLinedHeader
          title={<span>
            Our Information
          </span>}
          color="white"
          titleComponents={<UserAccessControl roles={["customer:edit"]}>
            <IconButton onClick={this._openDetailModal} size="small">
              <Edit fontSize="small" style={{ verticalAlign: "middle" }} />
            </IconButton>
          </UserAccessControl>}
        >
          <AjaxActionIndicator showProgress={isFetching} />
          <div className={styles.infoContainer}>
            <div className={styles.subcolumnSmall}>
              <div className={styles.address}>
                <div>{`${customerData?.address1 ?? ""}`}</div>
                <div>{`${customerData?.address2 ?? ""}`}</div>
                <div>{`${customerData?.city ?? ""}, ${customerData?.region?.regionAbbreviation ?? ""}, ${getFormattedZipPostalCode(customerData?.zipPostalCode) ?? ""}`}</div>
              </div>

              <div className={styles.fieldBlock}>
                <div className={styles.fieldPair}>
                  <div className={styles.fieldLabel}>Email:</div>
                  <div className={styles.fieldValue} title={customerData?.emailAddress || "None"}>{customerData?.emailAddress || "None"}</div>
                </div>
              </div>
            </div>

            <div className={styles.subcolumnLarge}>
              <div className={styles.fieldBlock}>
                <div className={styles.fieldPair}>
                  <div className={styles.fieldLabel}>Phone:</div>
                  <div className={styles.fieldValue}>{customerData?.phoneNumber || "None"}</div>
                </div>

                <div className={styles.fieldPair}>
                  <div className={styles.fieldLabel}>Cell:</div>
                  <div className={styles.fieldValue}>{customerData?.cellNumber || "None"}</div>
                </div>
              </div>

              <div className={styles.fieldBlock} style={{marginTop: "1rem"}}>
                <div className={styles.fieldPair}>
                  <div className={styles.fieldLabel}>Website:</div>
                  <div className={styles.fieldValue} title={customerData?.website || "None"}>{customerData?.website ? <a href={customerData?.website} target="_blank">{customerData?.website}</a> : "None"}</div>
                </div>
              </div>
            </div>
          </div>
        </CardLinedHeader>
      </Card>
    )
  }
}