import {
  moment
} from "$Imports/Imports";

export const getTimeZone = (value: Date): string =>
  new Date(value).toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(/\s+/)[2];

export function jsDateConverter(date: Date | undefined): Date | undefined {
  if (!date) {
    return;
  }

  return new Date(moment.utc(date).format('YYYY/MM/DD'));
}