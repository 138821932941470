import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  SimplifiedPortalCustomerQuote,
  CustomerQuotesSearchCriteria
} from "$Generated/api";

import {
  CustomerQuotesSearchForm
} from "./CustomerQuotesSearchForm";

import {
  AjaxActionIndicator,
  CardLinedHeader,
  DisplayFormattedDatetime,
  DisplayFormattedNumber,
  ToggleBox,
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  Card,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
  DataGridPro,
  Link
} from "$Imports/MaterialUIComponents";

import {
  ICustomerQuoteServiceInjectedProps,
  CustomerQuoteService,
  DEFAULT_CUSTOMER_QUOTE_SEARCH,
} from "$State/CustomerQuotesFreezerService";

const styles: {
  searchFormContainer: string;
  cardStyle: string;
  resultsGrid: string;
  resultsMessage: string;
  resultCell: string;
} = require("./CustomerQuotes.scss");

const quoteListTypes = ["Just Me", "Everyone"];
type quoteListType = typeof quoteListTypes[number];

interface IOwnProps {
  onViewQuote: (quoteNumber: string | undefined) => void;
}

interface IOwnState {
  selectedQuoteListType: quoteListType;
}

type  ICustomerQuotesProps = IOwnProps
  & ICustomerQuoteServiceInjectedProps;

export class _CustomerQuotes extends React.Component<ICustomerQuotesProps, IOwnState> {
  state: IOwnState = {
    selectedQuoteListType: "Just Me"
  };
  
  componentDidMount() {
    this.props.customerQuotesService.clearFreezer();

    CustomerQuoteService.freezer.get().searchCriteria.set(
      DEFAULT_CUSTOMER_QUOTE_SEARCH
    );

    this.props.customerQuotesService.fetchMyQuotes(true);
  }

  @bind
  private _getColumns(): GridColDef[] {
    return [{
      field: "quoteNumber",
      headerName: "Quote #",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any, SimplifiedPortalCustomerQuote>) => {
        return (
          <Link
            style={{cursor: "pointer"}}
            color="secondary"
            onClick={() => this.props.onViewQuote(params.row.quoteNumber)}
          >
            {`EQ${params.value}`}
          </Link>
        );
      },
      width: 80
    }, {
      field: "createdByCustomerName",
      headerName: "Creator",
      disableColumnMenu: true,
      width: 150
    }, {
      field: "quoteDate",
      headerName: "Quote Date",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<Date | undefined>) => 
        <DisplayFormattedDatetime value={params.value} formatString={'MM/DD/YYYY hh:mm A'} showTimeZone />,
      width: 195
    }, {
      field: "quoteStatus",
      headerName: "Status",
      width: 100
    }, {
      field: "deliveryDate",
      headerName: "Delivery Date",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<Date | undefined>) =>  
        params.value 
          ? params.value.toString().includes('00:00:00')
            ? <DisplayFormattedDatetime value={params.value} formatString={'MM/DD/YYYY'} manualOffset={true} /> 
            : <DisplayFormattedDatetime value={params.value} formatString={'MM/DD/YYYY hh:mm A'} manualOffset={true} showTimeZone />
          : "",
      width: 195
    }, {
      field: "expirationDate",
      headerName: "Expiration Date",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<Date | undefined>) => {
        if (params.row.quoteStatus === "Requested" || params.row.quoteStatus === "Declined") {
          return "--";
        }
        return params.value ? <DisplayFormattedDatetime value={params.value} formatString={'MM/DD/YYYY'} /> : "";
      },
      width: 125
    }, {
      field: "rate",
      headerName: "Estimate",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<number | undefined>) => 
        params.value ? <DisplayFormattedNumber value={params.value} formatString={"$0,0.00"} /> : "-",
      width: 100
    }, {
      field: "shipperZip",
      headerName: "Shipper",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any, SimplifiedPortalCustomerQuote>) => 
      params.row.shipperZipCode ?? "",
      flex: 1
    }, {
      field: "consigneeZip",
      headerName: "Consignee",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any, SimplifiedPortalCustomerQuote>) =>
        params.row.consigneeZipCode ?? "",
      flex: 1
    }, {
      field: "miles",
      headerName: "Miles",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<number | undefined>) => 
        params.value ? <DisplayFormattedNumber value={params.value} formatString={"0"} /> : "",
      flex: 1
    }, {
      field: "poNumber",
      headerName: "PO #",
      flex: 1
    }];
  }

  @bind
  private _onSortChange(sortModel: GridSortModel) {
    if (sortModel.length === 0) {
      const { searchCriteria } = this.props.customerQuotesService.getState();
      sortModel = [{
        field: searchCriteria.sortColumn ?? "deliveryDate",
        sort: searchCriteria.sortAscending ? "asc" : "desc"
      }];
    }
    this.props.customerQuotesService.onSearchModelChanged({
      sortColumn: sortModel[0].field,
      sortAscending: sortModel[0].sort === "asc"
    });

    this.props.customerQuotesService.fetchMyQuotes(true);
  }

  @bind
  selectQuoteListType(quoteListType: string) {
    if (quoteListType === this.state.selectedQuoteListType) {
      return;
    }

    this.setState({
      selectedQuoteListType: quoteListType as quoteListType
    });

    CustomerQuoteService.freezer.get().searchCriteria.set({
      quoteListType: quoteListType
    });

    this.props.customerQuotesService.fetchMyQuotes(true);
  }

  @bind
  private async _onSearchSubmit(search: CustomerQuotesSearchCriteria): Promise<void> {
    search.quoteListType = this.state.selectedQuoteListType;

    CustomerQuoteService.freezer.get().set({
      searchCriteria: {...search}
    });

    this.props.customerQuotesService.fetchMyQuotes(true);
  }

  render() {

    const {
      searchCriteria,
      quotesFetchResults
    } = this.props.customerQuotesService.getState();
    
    const {
      selectedQuoteListType
    } = this.state;

    let quoteData = quotesFetchResults.data?.results ?? [];

    const sortModel: GridSortModel = [{
      field: searchCriteria.sortColumn ?? "deliveryDate",
      sort: searchCriteria.sortAscending ? "asc" : "desc"
    }];
    
    return (
      <Card className={styles.cardStyle}>
        <CardLinedHeader 
          title="My Quotes"
          color="white"
          style={{ display: "flex", flexDirection: "column", padding: "0.625rem 0.625rem 0rem 0.625rem" }}
          titleComponents={<UserAccessControl roles={["customer-portal:manager"]}>
            <ToggleBox
              selectedValue={selectedQuoteListType}
              options={quoteListTypes}
              toggleClick={this.selectQuoteListType}
            />
          </UserAccessControl>}
        />

        <AjaxActionIndicator
          state={[quotesFetchResults]}
        />

        <div className={styles.resultsGrid}>
          <div className={styles.searchFormContainer}>
            <CustomerQuotesSearchForm
              onSubmit={this._onSearchSubmit}
            />
          </div>
        
          {(quotesFetchResults.hasFetched && (quotesFetchResults.data?.totalRecords ?? 0) > (quotesFetchResults.data?.numberOfRecords ?? 0)) ? (
            <div className={styles.resultsMessage}>
              {quotesFetchResults.data?.totalRecords} results found, {quotesFetchResults.data?.numberOfRecords} shown - please refine your search.
            </div>
          ) : undefined}

          <DataGridPro
            columns={this._getColumns()}
            rows={quoteData}
            classes={{
              cell: styles.resultCell
            }}
            density="compact"
            initialState={{
              sorting: { sortModel: sortModel }
            }}
            sortingMode="server"
            onSortModelChange={this._onSortChange}
            disableSelectionOnClick
            hideFooter
            getRowId={(row: any) => row.quoteNumber}
            columnVisibilityModel={{
              createdByCustomerName: selectedQuoteListType === "Everyone"
            }}
          />
        </div>
      </Card>
    );
  }
}

export const CustomerQuotes = CustomerQuoteService.inject(
  _CustomerQuotes
);