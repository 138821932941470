// extracted by mini-css-extract-plugin
var _1 = "PrintQuoteView__aboveSum__CBR3l";
var _2 = "PrintQuoteView__customerCard__ysmRj";
var _3 = "PrintQuoteView__disclaimerText__iTEew";
var _4 = "PrintQuoteView__expired__nc_4R";
var _5 = "PrintQuoteView__logo__STx6M";
var _6 = "PrintQuoteView__printButton__oWclo";
var _7 = "PrintQuoteView__printQuoteCard__W47gp";
var _8 = "PrintQuoteView__sum__C3sOt";
export { _1 as "aboveSum", _2 as "customerCard", _3 as "disclaimerText", _4 as "expired", _5 as "logo", _6 as "printButton", _7 as "printQuoteCard", _8 as "sum" }
