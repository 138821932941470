import {
  React,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  CustomerQuote
} from "$Generated/api";

import {
  TimeInputField
} from "$Imports/CommonComponents";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  DateEntity,
  DateType
} from "./QuoteView";

import {
  FormControlLabel,
  Checkbox,
  KeyboardDatePicker,
  TextFieldProps,
  TextField
} from "$Imports/MaterialUIComponents";

interface IQuoteStopDatesProps {
  dateEntity: DateEntity,
  startDate?: Date,
  endDate?: Date,
  isAppointmentRequired?: boolean,
  hardTime?: string,
  viewOnly?: boolean,
  onDateChange?: (dateEntity: DateEntity, dateType: DateType, date: Date | null | undefined) => void,
  onHardTimeChange?: (dateEntity: DateEntity, time: string | undefined) => void,
  onApptRequiredChange?: (dateEntity: DateEntity, checked: boolean) => void,
  validationErrors?: ValidationError | null
}

const styles: {
  label: string,
  quoteStopDatesEntry: string
} = require("./QuoteDatesEntry.scss");

export class QuoteDatesEntry extends React.PureComponent<IQuoteStopDatesProps> {
  
  @bind
  private _onDateChange(date: Date | null | undefined, dateType: DateType) {
    if (this.props.onDateChange) {
      this.props.onDateChange(this.props.dateEntity, dateType, date);
    }
  }

  @bind
  private _onHardTimeChange(time: string | undefined) {
    if (this.props.onHardTimeChange) {
      this.props.onHardTimeChange(this.props.dateEntity, time);
    }
  }

  @bind
  private _onApptRequiredChange(checked: boolean) {
    if (this.props.onApptRequiredChange) {
      this.props.onApptRequiredChange(this.props.dateEntity, checked);
    }
  }

  render() {

    const {
      startDate,
      endDate,
      isAppointmentRequired,
      hardTime,
      viewOnly,
      validationErrors,
      dateEntity
    } = this.props;

    const validationsParserQuote = new ValidationErrorParser<CustomerQuote>(validationErrors);

    return (
      <>
        <div className={styles.label}>{`${dateEntity === "shipper" ? "Shipper" : "Consignee"} Schedule`}</div>
        <div className={styles.quoteStopDatesEntry}>
          <KeyboardDatePicker
            value={startDate ?? null}
            onChange={(date: Date | null | undefined) => this._onDateChange(date, "Start")}
            disabled={viewOnly}
            disablePast
            inputFormat="MM/DD/YYYY"
            renderInput={(props: TextFieldProps) => <TextField 
              {...props}
              label="From"
              required
              style={{ width: "25%" }}
              error={!validationsParserQuote.isValidDeep(`${dateEntity}StartDate`)}
              helperText={validationsParserQuote.validationMessageDeep(`${dateEntity}StartDate`)}
            />}
          />
          <KeyboardDatePicker
            value={endDate ?? null}
            onChange={(date: Date | null | undefined) => this._onDateChange(date, "End")}
            disabled={viewOnly}
            disablePast
            inputFormat="MM/DD/YYYY"
            renderInput={(props: TextFieldProps) => <TextField 
              {...props}
              label="To"
              style={{ width: "25%" }}
              error={!validationsParserQuote.isValidDeep(`${dateEntity}EndDate`)}
              helperText={validationsParserQuote.validationMessageDeep(`${dateEntity}EndDate`)}
            />}
          />
          <FormControlLabel
            control={
              (
                <Checkbox
                  color="primary"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => this._onApptRequiredChange(checked)}
                  checked={isAppointmentRequired ?? false}
                  disabled={viewOnly}
                />
              )
            }
            label="Appointment Required"
            labelPlacement="start"
            style={{ whiteSpace: "nowrap" }}
          />
          {isAppointmentRequired &&
            <TimeInputField
              onTimeChange={this._onHardTimeChange}
              value={hardTime ?? ""}
              disabled={viewOnly}
              label="Time"
              error={!validationsParserQuote.isValidDeep(`${dateEntity}HardTime`)}
              helperText={validationsParserQuote.validationMessageDeep(`${dateEntity}HardTime`)}
            />
          }
        </div>
      </>
    )
  }
}