import {
  React
} from "$Imports/Imports";

import {
  CustomerPortalRateEngineResult
} from "$Generated/api";

import {
  DisplayFormattedDatetime,
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  IQuoteEntryServiceInjectedProps,
  QuoteEntryService
} from "$State/QuoteEntryFreezerService";

import {
  QuoteDetailCard
} from "./QuoteDetailCard";

import {
  ICustomerQuoteServiceInjectedProps,
  CustomerQuoteService,
} from "$State/CustomerQuotesFreezerService";

import {
  CustomerQuoteLink
} from "./CustomerQuoteLink"

import {
  Card
} from "$Imports/MaterialUIComponents";

interface IOwnProps {}

type OwnProps = IOwnProps
  & IQuoteEntryServiceInjectedProps
  & ICustomerQuoteServiceInjectedProps;

const styles: {
  card: string;
  header: string;
  notification: string;
  recalcRequired: string;
  error: string;
} = require("./QuoteDetails.scss");

class _QuoteDetails extends React.Component<OwnProps> {
  render() {
    const {
      customerQuote,
      isOverdimensional,
      hasQuoteChanged
    } = this.props.QuoteEntryService.getState();

    const rateEngineResult: CustomerPortalRateEngineResult | undefined = customerQuote?.rateEngineResults
      ? JSON.parse(customerQuote.rateEngineResults)
      : undefined;

    const showNegotiatedRate = customerQuote.quoteStatus === "Accepted" || customerQuote.quoteStatus === "Completed";
    const recalcRequired = customerQuote?.quoteStatus === "Pending" && hasQuoteChanged;
    const notifyEstimate = !isOverdimensional && !recalcRequired && !showNegotiatedRate && !!rateEngineResult;

    return (
      <>
        <QuoteDetailCard
          title={showNegotiatedRate ? "Negotiated Rate" : "Estimate"}
          majorValue={
            <div className={customerQuote?.quoteStatus === "Pending" && hasQuoteChanged ? styles.recalcRequired : ""}>
              {showNegotiatedRate ?
                <DisplayFormattedNumber
                  value={customerQuote.fullQuote?.negotiatedRate}
                  formatString="$0,0.00"
                  emptyDisplay="-"
                /> :
                !isOverdimensional ?
                  <DisplayFormattedNumber
                    value={rateEngineResult?.highTotalRate}
                    formatString="$0,0.00"
                    emptyDisplay="-"
                  />
                : "-"}
              {recalcRequired && 
                <span className={styles.error}>Recalculation required</span>
              }
              {notifyEstimate &&
                <div className={styles.notification}>
                  This estimated rate is subject to change.<br/>
                  Contact your Kaiser representative with any questions.
                </div>}
            </div>
          }
        />

        <QuoteDetailCard
          title="Quote Date"
          majorValue={
            <DisplayFormattedDatetime
              value={customerQuote?.quoteDate}
              formatString="MM/DD/YYYY hh:mm A"
              emptyDisplay="-"
              showTimeZone
            />
          }
        />

        <QuoteDetailCard
          title="Expiration Date"
          majorValue={
            <DisplayFormattedDatetime
              value={customerQuote?.expirationDate}
              formatString="MM/DD/YYYY hh:mm A"
              emptyDisplay="-"
              showTimeZone
            />
          }
        />

        {customerQuote?.copiedQuoteNumber &&
          <Card className={styles.card}>
            <div className={styles.header}>
              Copied Quote Information
            </div>
            <table>
              <tbody>
                <tr>
                  <td>Copied From</td>
                  <td>
                    <CustomerQuoteLink quoteNumber={customerQuote.copiedQuoteNumber?.toString()} />
                  </td>
                </tr>
                <tr>
                  <td>Previous Rate</td>
                  <td>
                    <DisplayFormattedNumber
                      value={customerQuote.copiedQuoteRate}
                      formatString={"$0,0.00"}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Card>
        }
      </>
    );
  }
}

export const QuoteDetails = QuoteEntryService.inject(
  CustomerQuoteService.inject(
    _QuoteDetails
));