import {
  React,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  CustomerQuote,
  EquipmentType
} from "$Generated/api";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormHelperText
} from "$Imports/MaterialUIComponents"

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

interface IEquipmentTypeEntryBaseProps {
  equipmentTypeId?: number,
  viewOnly?: boolean,
  equipmentTypeOptions?: EquipmentType[],
  onEquipmentTypeChange?: (equipmentTypeId: string) => void,
  validationErrors?: ValidationError | null
}

export class EquipmentTypeEntry extends React.PureComponent<IEquipmentTypeEntryBaseProps> {

  @bind
  private _onEquipmentTypeChange(event: SelectChangeEvent){
    if (this.props.onEquipmentTypeChange) {
      this.props.onEquipmentTypeChange(event.target.value as string)
    }
  }

  render() {

    const {
      equipmentTypeId,
      equipmentTypeOptions,
      validationErrors,
      viewOnly
    } = this.props;

    const validationsParserQuote = new ValidationErrorParser<CustomerQuote>(validationErrors);

    return (
      <FormControl fullWidth error={!validationsParserQuote.isValidDeep(`equipmentTypeId`)}>
        <InputLabel>Equipment Type *</InputLabel>
        <Select
          value={equipmentTypeId?.toString() ?? ""}
          disabled={viewOnly}
          onChange={this._onEquipmentTypeChange}>
          {
            equipmentTypeOptions?.map((et, i) =>
              <MenuItem value={et.id} key={i}>
                {et.name}
              </MenuItem>
            )
          }
        </Select>
        <FormHelperText>
          {validationsParserQuote.validationMessageDeep(`equipmentTypeId`)}
        </FormHelperText>
      </FormControl>
    )
  }
}