export interface IErrorMessage<T = any> {
  statusCode: number;
  statusText: string;
  body: T | null;
}

export interface IError {
  message: string | null;
  innerMesssage: string | null;
}

export type ajaxStateType = "initial" | "error" | "fetching" | "ok" | "cleared";

export type IFetcher = typeof fetch;

export interface IAjaxState<T = any, E = IError> {
  isFetching: boolean;
  error?: IErrorMessage<E> | null;
  errorMessage?: string | null;
  hasFetched: boolean;
  requestId?: number;
  data?: T | null;
  state?: ajaxStateType;
}

let startingRequestId: number = -1;

function getStartingRequestId(): number {
  startingRequestId += 1;
  return startingRequestId;
}

export function createInitialState<T>(startRequestId: number | null = null): IAjaxState<T> {
  const newId = startRequestId === null ? getStartingRequestId() : startRequestId;

  return {
    isFetching: false,
    error: null,
    errorMessage: null,
    hasFetched: false,
    requestId: newId,
    data: null,
    state: "initial",
  };
}

export function clear<T>(state: IAjaxState<T>) {
  state.isFetching = false;
  state.error = null;
  state.errorMessage = null;
  state.hasFetched = false;
  state.data = null;
  state.state = "cleared";
  return state;
}

export function clearError<T>(state: IAjaxState<T>) {
  state.isFetching = false;
  state.error = null;
  state.errorMessage = null;
  state.state = "cleared";
  return state;
}