import { 
  _
} from "$Imports/Imports";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings"

// Global Settings and User Manager

class ApplicationSecuritySettings {
  public canAccessCustomerPortal(): boolean {
    const accessRoles = ["customer-portal:manager", "customer-portal:salesrep"];
    return SharedSecurityContext.hasRole(accessRoles);
  }
}

// Create a singleton for securiry settings.
export const SecurityContext = new ApplicationSecuritySettings();