import {
  React,
  bind
} from "$Imports/Imports";

import {
  DialogActions,
  DialogContentText,
  DialogContent,
  Dialog,
  Button
} from "$Imports/MaterialUIComponents";

interface IConfirmDeleteModalProps {
  isOpen: boolean;
  onDelete: () => void;
  onDeleteCancel: () => void;
  deleteMessage: string;
  deleteTypography?: JSX.Element;
}

export class ConfirmDeleteModal extends React.PureComponent<IConfirmDeleteModalProps> {

  @bind
  private _onDeleteCancel(): void {
    if (this.props.onDeleteCancel) {
      this.props.onDeleteCancel();
    }
  }

  @bind
  private _onDelete(): void {
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
      >
        <DialogContent>
          {this.props.deleteTypography ? this.props.deleteTypography :
            <DialogContentText>
              {this.props.deleteMessage ? this.props.deleteMessage : "Are you sure you want to delete this?"}
            </DialogContentText>
          }
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={this._onDeleteCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this._onDelete}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
