// extracted by mini-css-extract-plugin
var _1 = "CustomerQuotesListViewPage__cardStyle__nKRH_";
var _2 = "CustomerQuotesListViewPage__filterContainer__NcAUX";
var _3 = "CustomerQuotesListViewPage__inputStyle__y9Gas";
var _4 = "CustomerQuotesListViewPage__mainContainer__VomrK";
var _5 = "CustomerQuotesListViewPage__quoteStatusAccepted__Ni6qX";
var _6 = "CustomerQuotesListViewPage__quoteStatusCompleted__uuV8g";
var _7 = "CustomerQuotesListViewPage__quoteStatusExpired__ZGKI2";
var _8 = "CustomerQuotesListViewPage__quoteStatusPending__PQFRF";
var _9 = "CustomerQuotesListViewPage__quoteStatusRequested__zFIVS";
var _a = "CustomerQuotesListViewPage__resultCell__qTHDc";
var _b = "CustomerQuotesListViewPage__resultsGrid__DLkJj";
var _c = "CustomerQuotesListViewPage__resultsMessage__to5BG";
export { _1 as "cardStyle", _2 as "filterContainer", _3 as "inputStyle", _4 as "mainContainer", _5 as "quoteStatusAccepted", _6 as "quoteStatusCompleted", _7 as "quoteStatusExpired", _8 as "quoteStatusPending", _9 as "quoteStatusRequested", _a as "resultCell", _b as "resultsGrid", _c as "resultsMessage" }
