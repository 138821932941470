import {
  React,
  bind,
  moment
} from "$Imports/Imports";

import {
  Icon
} from "$Imports/MaterialUIComponents";

import {
  HourglassEmptyOutlined
} from "$Imports/MaterialUIIcons";

import {
  DisplayFormattedDatetime
} from "./DisplayFormattedDatetime";

interface IOwnProps {
  value: Date | undefined;
  soonCutoff?: Date;
  formatString?: string;
}

interface IOwnState {
  isDueSoon: boolean;
  isOverdue: boolean;
}

const styles: {
  overdue: string;
} = require("./DueDateField.scss");

class _DueDateField extends React.PureComponent<IOwnProps, IOwnState> {
  state: IOwnState = {
    isDueSoon: false,
    isOverdue: false
  };
  
  componentDidMount() {
    this._checkDate();
  }

  componentDidUpdate(prev: IOwnProps) {
    if(this.props.value !== prev.value) {
      this._checkDate();
    }
  }

  @bind
  private _checkDate() {
    const valueMoment = moment.utc(this.props.value).startOf('day');
    const valueDate = valueMoment.toDate();
    
    const today = moment.utc().startOf('day').toDate();

    const isOverdue = today > valueDate;
    let isDueSoon = false;

    if(!isOverdue) {
      const soonCutoff = this.props.soonCutoff ?? valueMoment.subtract(3, 'day').toDate();

      isDueSoon = today >= soonCutoff;
    }    

    this.setState({
      isDueSoon: isDueSoon,
      isOverdue: isOverdue
    });
  }
  
  render() {
    const {
      value,
      formatString
    } = this.props;

    const {
      isOverdue,
      isDueSoon
    } = this.state;

    const formattedValue = <DisplayFormattedDatetime value={value} formatString={formatString ?? "MM/DD/YYYY"} />

    return isOverdue
      ? (
        <span className={styles.overdue}>
          {formattedValue}
        </span>
      )
      : isDueSoon
      ? (
        <>
          {formattedValue}
          <Icon title="Due soon">
            <HourglassEmptyOutlined style={{ verticalAlign: 'top' }} />
          </Icon>
        </>
      )
      : formattedValue;
  }
}

export const DueDateField = _DueDateField;