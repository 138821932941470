import {
  React,
  bind,
  _,
  User
} from "$Imports/Imports";

import {
  MenuItem,
  ListItemIcon
} from "$Imports/MaterialUIComponents";

import {
  INavigationItem,
} from "$Utilities/navigation";

interface ISideNavigationMenuItemProps<T = User> {
  navigationItem: INavigationItem;
  securityContext: T | null;
  selected?: boolean;
  onMenuItemClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>, navigationItem: INavigationItem) => void;
}

export class SideNavigationMenuItem extends React.PureComponent<ISideNavigationMenuItemProps> {
  static defaultProps: Partial<ISideNavigationMenuItemProps> = {
    selected: false,
  };

  @bind
  private _onClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick(event, this.props.navigationItem);
    }
  }

  render() {
    const {
      navigationItem,
      selected,
      securityContext
    } = this.props;

    const disabled = !(typeof navigationItem.enabled === "function" ? navigationItem.enabled(navigationItem, securityContext) : navigationItem.enabled);

    const hasAccess = navigationItem.hasAccess !== undefined ?
      typeof navigationItem.hasAccess === "function" ? navigationItem.hasAccess(navigationItem, securityContext) : navigationItem.hasAccess :
      true;


    return hasAccess ? (
      <MenuItem
        onClick={this._onClick}
        selected={selected}
        disabled={disabled}
      >
        {
          navigationItem.icon ? (
            <ListItemIcon>
              {React.createElement(navigationItem.icon)}
            </ListItemIcon>
          ) : null
        }
        {navigationItem.label}
      </MenuItem>
    ) : null;
  }
}
