import {
  bind,
  React,
  moment,
  _
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  DateRangePicker,
  SearchControlsContainer,
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  CustomerQuotesSearchCriteria,
  CustomerQuotesSearchCriteriaQuoteStatusesEnum
} from "$Generated/api";

import {
  DEFAULT_CUSTOMER_QUOTES_LIST_SEARCH,
  ICustomerQuoteServiceInjectedProps,
  CustomerQuoteService,
  QuoteSearchValidationSchema
} from "$State/CustomerQuotesFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent
} from "$Imports/MaterialUIComponents";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

interface IOwnProps {
  onSubmit: (search: CustomerQuotesSearchCriteria) => void;
}

type OwnProps = IOwnProps
  & ICustomerQuoteServiceInjectedProps;

  interface IOwnState {
    searchCriteria: CustomerQuotesSearchCriteria;
    errors: ValidationError | null;
    isManagerUser: boolean;
  }

const styles: {
  container: string;
  inputStyle: string;
} = require("./CustomerQuotesListSearchForm.scss");

const statusArray: CustomerQuotesSearchCriteriaQuoteStatusesEnum[] = ["Accepted", "Completed", "Declined", "Expired", "Requested", "Pending"];

class _CustomerQuotesListSearchForm extends React.PureComponent<OwnProps, IOwnState> {
  state: IOwnState = {
    searchCriteria: {
      ...DEFAULT_CUSTOMER_QUOTES_LIST_SEARCH
    },
    errors: null,
    isManagerUser: SharedSecurityContext.hasRole(["customer-portal:manager"])
  };

  @bind
  private _onDateRangeChange(start: Date | null, end: Date | null): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        startDate: start ? moment(start).startOf('day').toDate() : undefined,
        endDate: end ? moment(end).endOf('day').toDate() : undefined
      }
    }));
  }

  @bind
  private _onChange(e: React.ChangeEvent<{ name: string; value: string; }>) {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        [e.target.name]: e.target.value
      }
    }));
  }

  @bind
  private _onQuoteStatusChange(e: SelectChangeEvent<CustomerQuotesSearchCriteriaQuoteStatusesEnum[]>) {
    const allClicked = e.target.value.includes("All" as CustomerQuotesSearchCriteriaQuoteStatusesEnum);
    if (allClicked) {  
      this.setState((prev) => ({
        searchCriteria: {
          ...prev.searchCriteria,
          quoteStatuses: prev.searchCriteria?.quoteStatuses && prev.searchCriteria.quoteStatuses.length < 6 ? statusArray : []
        }
      }));
    } else {
      this.setState((prev) => ({
        searchCriteria: {
          ...prev.searchCriteria,
          quoteStatuses: e.target.value as CustomerQuotesSearchCriteriaQuoteStatusesEnum[]
        }
      }));
    }
  }

  @bind
  private _searchFieldOnKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      this._onSubmit();
    }
  }

  @bind
  private async _onSubmit(): Promise<void> {
    const { searchCriteria } = this.state;

    const errors =await validateSchema(QuoteSearchValidationSchema, searchCriteria, {
      abortEarly: false
    });
    this.setState({ errors: errors });
    if (errors) {
      return;
    }

    this.props.onSubmit(searchCriteria);
  }

  @bind
  private _onClear(): void {
    const searchCriteria = {
      ...DEFAULT_CUSTOMER_QUOTES_LIST_SEARCH,
      quoteListType: this.state.isManagerUser ? "Everyone" : "Just Me"
    };

    this.setState({
      searchCriteria: searchCriteria
    });

    this.props.onSubmit(searchCriteria);

    this.setState({ errors: null });
  }

  componentDidMount() {
    const { searchCriteria } = this.props.customerQuotesService.getState();
    
    this.setState({
      searchCriteria: {
        ...searchCriteria,
        quoteListType: this.state.isManagerUser ? "Everyone" : "Just Me"
      }
    });
  }

  componentWillUnmount() {
    this.props.customerQuotesService.clearFreezer();
  }

  render() {
    const {
      searchCriteria,
      errors
    } = this.state;
    
    const validationsParser = new ValidationErrorParser<CustomerQuotesSearchCriteria>(errors);
    const startError = validationsParser.validationMessage("startDate");
    const endError = validationsParser.validationMessage("endDate");

    return (
      <SearchControlsContainer
        className={styles.container}
        onSubmit={this._onSubmit}
        onClear={this._onClear}
      >
        <AdvanceTextField
          style={{ flex: "1" }}
          label="Quote # or PO #"
          name="quoteOrPONumber"
          value={searchCriteria.quoteOrPONumber ?? ""}
          onChange={this._onChange}
          onKeyPress={this._searchFieldOnKeyPress}
          error={!validationsParser.isValid("quoteOrPONumber")}
          helperText={validationsParser.validationMessage("quoteOrPONumber")}
        />

        <AdvanceTextField
          style={{ flex: "1" }}
          label="Description"
          name="description"
          value={searchCriteria.description ?? ""}
          onChange={this._onChange}
          onKeyPress={this._searchFieldOnKeyPress}
          error={!validationsParser.isValid("description")}
          helperText={validationsParser.validationMessage("description")}
        />

        <AdvanceTextField
          style={{ flex: "1" }}
          label="Notes"
          name="notes"
          value={searchCriteria.notes ?? ""}
          onChange={this._onChange}
          onKeyPress={this._searchFieldOnKeyPress}
          error={!validationsParser.isValid("notes")}
          helperText={validationsParser.validationMessage("notes")}
        />

        <div style={{ display: "inline-flex", flex: "0 0 17rem", gap: "0.5rem" }}>
          <DateRangePicker
            startDate={searchCriteria.startDate}
            endDate={searchCriteria.endDate}
            startError={startError}
            endError={endError}
            onChange={this._onDateRangeChange}
          />
        </div>

        <FormControl className={styles.inputStyle}>
          <InputLabel>Status</InputLabel>
          <Select
            value={searchCriteria?.quoteStatuses ?? []}
            onChange={this._onQuoteStatusChange}
            multiple
            renderValue={(selected) => {
              if (selected.length === 6) {
                return <i>All</i>;
              }
              else {
                return _.map(selected as CustomerQuotesSearchCriteriaQuoteStatusesEnum[], (s, idx) => {
                  return (
                    <span key={idx}>
                      {s}
                      <>{idx !== (selected as CustomerQuotesSearchCriteriaQuoteStatusesEnum[]).length - 1 ? ", " : ""}</>
                    </span>
                  )
                })
              }
            }}
          >
            <MenuItem value={"All"}>
              <Checkbox checked={searchCriteria?.quoteStatuses?.length === 6} />
              <i><ListItemText primary={"All"} /></i>
            </MenuItem>
            <hr/>
            {
              statusArray.map((status) => {
                return (
                  <MenuItem key={status} value={status}>
                    <Checkbox checked={_.findIndex(searchCriteria?.quoteStatuses, s => s === status) > -1} />
                    <ListItemText primary={status} />
                  </MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
        
      </SearchControlsContainer>
    );
  }
}

export const CustomerQuotesListSearchForm = CustomerQuoteService.inject(_CustomerQuotesListSearchForm);
