import {
  React
} from "$Imports/Imports";

interface IDisplayFormattedFeetProps {
  value?: number
}

export class DisplayFormattedFeet extends React.PureComponent<IDisplayFormattedFeetProps> {
  
  render() {
    const {
      value
    } = this.props;
  
    const feet = Math.floor((value ?? 0) / 12);
    const inches = (value ?? 0) % 12;
    return `${feet}'${inches}\"`
  }
}