import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  CustomerContact
} from "$Generated/api";

import {
  Button,
  Card,
  Checkbox,
  DataGridPro,
  FormControlLabel,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  Link,
  Tooltip
} from "$Imports/MaterialUIComponents";

import {
  ISortState,
  directionType,
  UserAccessControl,
  CardLinedHeader,
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
  ActionMenu
} from "./ActionMenu";

const styles: {
  card: string;
  contactTable: string;
  tableRow: string;
  contactCell: string;
  fieldValue: string;
  contactName: string;
  contactNotes: string;
  inactive: string;
} = require("./CustomerContacts.scss");

interface IContactsCardProps {
  contacts: CustomerContact[];
  addEditContact: (contact?: CustomerContact) => void;
  onShowInactive: (showInactive: boolean) => void;
  sortState: ISortState;
  onSortChange: (columnName: string | undefined, direction: directionType) => void;
  isFetching: boolean;
  isManagerUser: boolean;
  showInactiveContacts: boolean;
}

export class ContactsCard extends React.Component<IContactsCardProps> {

  private readonly columns: GridColDef[] = [
    {
      headerName: "Contact Name",
      field: "firstName",
      flex: 2,
      cellClassName: styles.contactCell,
      renderCell: (params: GridRenderCellParams<any, CustomerContact>) => (
        <>
          <div className={styles.contactName}>{params.row.firstName} {params.row.lastName}{params.row.nameSuffix ? `, ${params.row.nameSuffix.suffixValue}` : ""}</div>
          { params.row.emailAddress &&
            <Link
              color="secondary"
              href={`mailto:${params.row.emailAddress}`}
            >
              {params.row.emailAddress}
            </Link>
          }
        </>
      ),
      sortable: true
    },
    {
      headerName: "Type",
      field: "type",
      flex: 2,
      cellClassName: styles.contactCell,
      renderCell: (params: GridRenderCellParams<any, CustomerContact>) => (
        <>
          <div style={{display: "flex", flexDirection: "column"}}>
            <div className={styles.fieldValue}>{params.row.contactType ? params.row.contactType.type : ""}</div>
            <div className={styles.fieldValue}>{params.row.title}</div>
          </div>
        </>
      ),
      sortable: false
    },
    {
      headerName: "Phone",
      field: "phoneNumber",
      flex: 2,
      cellClassName: styles.contactCell,
      renderCell: (params: GridRenderCellParams<any, CustomerContact>) =>
        params.row.phoneNumber && (
          <Tooltip
            className={styles.contactNotes}
            title={params.row.phoneNumber}
            placement="left"
          >
            <span>{ params.row.phoneNumber }</span>
          </Tooltip>
        ),
      sortable: false
    },
    {
      headerName: "",
      field: "actions",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, CustomerContact>) => (
        <ActionMenu
          contact={params.row}
          editContact={this._editContact}
        />
      ),
      sortable: false
    }
  ];

  @bind
  private _addContact() {
    this.props.addEditContact();
  }

  @bind
  private _editContact(contact: CustomerContact) {
    this.props.addEditContact(contact);
  }

  @bind
  private _onShowInactiveChange(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.onShowInactive(checked);
  }

  render() {
    const {
      contacts,
      isFetching,
      isManagerUser,
      showInactiveContacts
    } = this.props;

    return (
      <>
        <Card className={styles.card}>
          <CardLinedHeader
            title={isManagerUser ? "Our Contacts" : "My Contact Information"}
            color="white"
            titleComponents={isManagerUser ? (
              <div style={{ marginBottom: "5px" }}>
                <FormControlLabel
                  label="Show inactive"
                  control={(
                    <Checkbox
                      checked={showInactiveContacts}
                      onChange={this._onShowInactiveChange}
                      name="isPrimary"
                    />
                  )}
                />
                <UserAccessControl roles={["contact:edit"]}>
                  <Button onClick={this._addContact}>
                    Add
                  </Button>
                </UserAccessControl>
              </div>
            ) : ""}
          >
            <AjaxActionIndicator showProgress={isFetching} />
            <DataGridPro
              className={styles.contactTable}
              rows={contacts ?? []}
              columns={this.columns}
              getRowClassName={(params: GridRowParams<CustomerContact>) => `${styles.tableRow} ${!params.row.isActive ? styles.inactive : ""}`}
              density="compact"
              getRowHeight={() => 44}
              initialState={{
                columns: {
                  columnVisibilityModel: { isPrimary: false }
                },
                sorting: {
                  sortModel: [{ field: "firstName", sort: "asc" }]
                }
              }}
              components={{
                NoRowsOverlay: () => 
                <div style={{margin: "0.5rem"}}>
                  <span>No contact assigned to your user record.</span><br /><br />
                  <span>Please reach out to your manager to create or assign your contact.</span>
                </div>
              }}
              hideFooter
              disableColumnMenu
              disableColumnFilter
              disableSelectionOnClick
              />
          </CardLinedHeader>
        </Card>
      </>
    )
  }
}