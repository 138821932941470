// extracted by mini-css-extract-plugin
var _1 = "KaiserDetailsCard__card__D6vDQ";
var _2 = "KaiserDetailsCard__column__F2NNo";
var _3 = "KaiserDetailsCard__fieldBlock__rCX4H";
var _4 = "KaiserDetailsCard__fieldLabel__XtN1g";
var _5 = "KaiserDetailsCard__fieldPair__sULvm";
var _6 = "KaiserDetailsCard__fieldValue__aphhR";
var _7 = "KaiserDetailsCard__infoContainer__DqT2h";
var _8 = "KaiserDetailsCard__subcolumnLarge___LHIr";
var _9 = "KaiserDetailsCard__subcolumnSmall__jJYU3";
export { _1 as "card", _2 as "column", _3 as "fieldBlock", _4 as "fieldLabel", _5 as "fieldPair", _6 as "fieldValue", _7 as "infoContainer", _8 as "subcolumnLarge", _9 as "subcolumnSmall" }
