import {
  React,
  bind,
  moment
} from "$Imports/Imports";

import {
  SimplifiedPortalCustomerQuote,
  CustomerQuote,
  QuoteStatusEnum,
  CustomerQuotesSearchCriteria
} from "$Generated/api";

import {
  AjaxActionIndicator,
  DisplayFormattedDatetime,
  DisplayFormattedNumber,
} from "$Imports/CommonComponents";

import {
  Card,
  CardHeader,
  Button,
  GridValueFormatterParams,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
  DataGridPro
} from "$Imports/MaterialUIComponents";

import {
  ICustomerQuoteServiceInjectedProps,
  CustomerQuoteService,
  DEFAULT_CUSTOMER_QUOTES_LIST_SEARCH,
} from "$State/CustomerQuotesFreezerService";

import {
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps
} from "$State/QuoteEntryFreezerService"

import {
  NavigationService
} from "$State/NavigationFreezerService";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  CustomerQuotesListSearchForm
} from "./CustomerQuotesListSearchForm";

const styles: {
  mainContainer: string;
  cardStyle: string;
  filterContainer: string;
  inputStyle: string;
  resultsGrid: string;
  resultsMessage: string;
  resultCell: string;
  quoteStatusAccepted: string;
  quoteStatusCompleted: string;
  quoteStatusRequested: string;
  quoteStatusPending: string;
  quoteStatusExpired: string;
} = require("./CustomerQuotesListViewPage.scss");

type  ICustomerQuotesListPageProps = ICustomerQuoteServiceInjectedProps & IQuoteEntryServiceInjectedProps

export class _CustomerQuotesListViewPage extends React.Component<ICustomerQuotesListPageProps> {
  private _columns: GridColDef[] = this._getColumns(moment.utc().startOf('days'));

  componentDidMount() {
    this.props.customerQuotesService.clearFreezer();

    const isManagerUser = SharedSecurityContext.hasRole(["customer-portal:manager"]);
    if (isManagerUser){
      CustomerQuoteService.freezer.get().searchCriteria.set({
        quoteListType: "Everyone",
        ...DEFAULT_CUSTOMER_QUOTES_LIST_SEARCH
      });
    } else {
      CustomerQuoteService.freezer.get().searchCriteria.set(
        DEFAULT_CUSTOMER_QUOTES_LIST_SEARCH
      );
    }

    this.props.customerQuotesService.fetchQuotes(true);
  }
  
  @bind
  private _getColumns(now: any): GridColDef[] {
    return [{
      field: "quoteNumber",
      headerName: "Quote #",
      valueFormatter: (params: GridValueFormatterParams) => `EQ${params.value}`,
      width: 80
    }, {
      field: "quoteDate",
      headerName: "Quote Date",
      renderCell: (params: GridRenderCellParams<Date | undefined>) => 
        <DisplayFormattedDatetime value={params.value} formatString={'MM/DD/YYYY hh:mm A'} showTimeZone />,
      width: 195
    }, {
      field: "createdByCustomerName",
      headerName: "Creator",
      width: 150
    }, {
      field: "poNumber",
      headerName: "PO #",
      flex: 1
    }, {
      field: "shipperConsigneeZips",
      headerName: "Shipper/Consignee",
      renderCell: (params: GridRenderCellParams<any, SimplifiedPortalCustomerQuote>) => 
        params.row.shipperZipCode + "/" + params.row.consigneeZipCode,
      width: 140
    }, {
      field: "rate",
      headerName: "Est. Rate",
      renderCell: (params: GridRenderCellParams<number | undefined>) => 
        params.value ? <DisplayFormattedNumber value={params.value} formatString={"$0,0.00"} /> : "-",
      width: 100
    }, {
      field: "miles",
      headerName: "Miles",
      renderCell: (params: GridRenderCellParams<number | undefined>) => 
        params.value ? <DisplayFormattedNumber value={params.value} formatString={"0"} /> : "",
      width: 80
    }, {
      field: "deliveryDate",
      headerName: "Delivery Date",
      renderCell: (params: GridRenderCellParams<Date | undefined>) => 
      params.value 
        ? params.value.toString().includes('00:00:00')
          ? <DisplayFormattedDatetime value={params.value} formatString={'MM/DD/YYYY'} manualOffset={true} /> 
          : <DisplayFormattedDatetime value={params.value} formatString={'MM/DD/YYYY hh:mm A'} manualOffset={true} showTimeZone />
        : "",
      width: 195
    }, {
      headerName: "Description",
      field: "description",
      flex: 1
    }, {
      headerName: "Days To Expiration",
      field: "daysToExpiration",
      renderCell: (params: GridRenderCellParams<Date | undefined>) => {
        if (params.row.quoteStatus === "Pending" && params.row.expirationDate) {
          return moment.utc(params.row.expirationDate).diff(now, 'days');
        } else {
          return "-";
        }
      },
      maxWidth: 140,
      flex: 1
    }, {
      field: "quoteStatus",
      headerName: "Status",
      renderCell: (params: GridRenderCellParams<QuoteStatusEnum>) => {
        const statusClassName = params.value === "Accepted" ? styles.quoteStatusAccepted
        : params.value === "Completed" ? styles.quoteStatusCompleted 
        : params.value === "Requested" ? styles.quoteStatusRequested 
          : params.value === "Pending" ? styles.quoteStatusPending
          : params.value === "Expired" || params.value === "Declined" ? styles.quoteStatusExpired
          : "";

        return <span className={statusClassName}>{params.value}</span>;
      },
      width: 100
    }, {
      field: "viewQuote",
      headerName: "",
      renderCell: (params: GridRenderCellParams<any, SimplifiedPortalCustomerQuote>) => 
        <Button
          color="primary" 
          variant="contained" 
          onClick={() => this._viewQuote(params.row.quoteNumber)}
        >
            View
        </Button>,
        sortable: false,
        disableColumnMenu: true
    }];
  }

  @bind
  private async _viewQuote(quoteNumber: string | undefined){
    await this.props.customerQuotesService.fetchQuote(quoteNumber);
    const customerQuote = this.props.customerQuotesService.getState().quoteFetchResults.data as CustomerQuote;
    this.props.QuoteEntryService.setCustomerQuote(customerQuote);
    NavigationService.navigateTo(`/quotes/${customerQuote.id}`);
  }

  @bind
  private _onSearchClick(search: CustomerQuotesSearchCriteria) {
    CustomerQuoteService.freezer.get().set({
      searchCriteria: {...search}
    });
    
    this.props.customerQuotesService.fetchQuotes(true);
  }

  @bind
  private _onSortChange(sortModel: GridSortModel) {
    if (sortModel.length === 0) {
      const { searchCriteria } = this.props.customerQuotesService.getState();
      sortModel = [{
        field: searchCriteria.sortColumn ?? "quoteDate",
        sort: searchCriteria.sortAscending ? "asc" : "desc"
      }];
    }
    this.props.customerQuotesService.onSearchModelChanged({
      sortColumn: sortModel[0].field,
      sortAscending: sortModel[0].sort === "asc"
    });

    this.props.customerQuotesService.fetchQuotes(true);
  }

  render() {

    const {
      searchCriteria,
      quotesFetchResults
    } = this.props.customerQuotesService.getState();

    let quoteData = quotesFetchResults.data?.results ?? [];

    const sortModel: GridSortModel = [{
      field: searchCriteria.sortColumn ?? "quoteDate",
      sort: searchCriteria.sortAscending ? "asc" : "desc"
    }];
    
    return (
      <div className={styles.mainContainer}>
          <Card className={styles.cardStyle}>
            <CardHeader
              title="Quote List"
            />

            <div className={styles.filterContainer}>
              <CustomerQuotesListSearchForm
                onSubmit={this._onSearchClick}
              />
            </div>

            <AjaxActionIndicator
              state={[quotesFetchResults]}
            />

            <div className={styles.resultsGrid}>
              {(quotesFetchResults.hasFetched && (quotesFetchResults.data?.totalRecords ?? 0) > (quotesFetchResults.data?.numberOfRecords ?? 0)) ? (
                <div className={styles.resultsMessage}>
                  {quotesFetchResults.data?.totalRecords} results found, {quotesFetchResults.data?.numberOfRecords} shown - please refine your search.
                </div>
              ) : undefined}

              <DataGridPro
                columns={this._columns}
                rows={quoteData}
                classes={{
                  cell: styles.resultCell
                }}
                density="compact"
                initialState={{
                  sorting: { sortModel: sortModel }
                }}
                sortingMode="server"
                getRowHeight={() => 64}
                onSortModelChange={this._onSortChange}
                disableSelectionOnClick
                hideFooter
                getRowId={(row: any) => row.quoteNumber}
              />
            </div>
          </Card>
      </div>
    );
  }
}

export const CustomerQuotesListViewPage = QuoteEntryService.inject(
  CustomerQuoteService.inject(
    _CustomerQuotesListViewPage
  )
);