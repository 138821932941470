import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  CustomerQuote,
  CustomerContact,
  CustomerUser,
} from "$Generated/api";

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "$Imports/MaterialUIComponents";

import { 
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps
} from "$State/QuoteEntryFreezerService";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

interface IContactPersonDisplayBaseProps {
  currentQuote: CustomerQuote,
  currentCustomer: CustomerUser,
  contacts: CustomerContact[],
  disableContact: boolean,
  isPrintView: boolean,
}

const styles: {
  mainContainer: string,
  input: string,
  warning: string
} = require("./ContactPersonDisplay.scss");

type IContactPersonDisplayProps = IContactPersonDisplayBaseProps & IQuoteEntryServiceInjectedProps;

export class _ContactPersonDisplay extends React.PureComponent<IContactPersonDisplayProps> {

  @bind
  private _onChangeContact(event: SelectChangeEvent<number | string>) {
    this.props.QuoteEntryService.updateSelectedContact(event.target.value ? event.target.value as number : undefined);
  }

  render() {
    const {
      currentQuote,
      currentCustomer,
      contacts,
      disableContact,
      isPrintView,
    } = this.props;

    const userId = SharedSecurityContext.getUserId();

    const selectedContact = disableContact || currentQuote.customerContactId ? _.find(contacts, c => c.id == currentQuote.customerContactId)
    : currentCustomer.id ? _.find(contacts, c => c.customerUser?.userId == userId)
    : undefined;

    if (!currentQuote.id && selectedContact) {
      this.props.QuoteEntryService.updateCustomerQuote({ customerContactId: selectedContact.id }, true);
    }
    
    return (
      <div className={styles.mainContainer}>
        {isPrintView ?
          <div>
            <div>Contact Name: <b>{selectedContact ? `${selectedContact.firstName} ${selectedContact.lastName}` : "None"}</b></div>
            <div>Phone Number: <b>{selectedContact?.phoneNumber ? selectedContact.phoneNumber : ""}</b></div>
            <div>Email: <b>{selectedContact?.emailAddress ? selectedContact.emailAddress : ""}</b></div>
          </div>
          :
          <>
            <FormControl className={styles.input}>
              <InputLabel>Contact Person</InputLabel>
              <Select
                value={selectedContact?.id ?? ""}
                onChange={this._onChangeContact}
                disabled={disableContact || isPrintView}
              >
                {contacts.map((c, idx) =>
                  <MenuItem value={c.id} key={idx}>
                    {`${c.firstName} ${c.lastName}`}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            {selectedContact ?
              <div>
                <div>Contact Phone: <b>{selectedContact.phoneNumber ?? ""}</b></div>
                <div>Contact Email: <b>{selectedContact.emailAddress ? <a href={`mailto:${selectedContact.emailAddress}`} target="_blank">{selectedContact.emailAddress}</a> : ""}</b></div>
              </div>
              :
              !disableContact && <div className={styles.warning}>No contact assigned</div>
            }
          </>
        }
      </div>
    )
  }
}

export const ContactPersonDisplay = QuoteEntryService.inject(_ContactPersonDisplay);