import {
    React,
    _,
    bind
} from "$Imports/Imports";

import {
    Snackbar,
    SnackbarContent,
    IconButton,
    Button,
    SnackbarCloseReason
} from "$Imports/MaterialUIComponents";

import {
    ErrorService,
    IErrorServiceInjectedProps
} from "$State/ErrorFreezerService";

import {
    Close
} from "$Imports/MaterialUIIcons";

interface IErrorStackPanelBaseProps {

}

type IErrorStackPanelProps = IErrorStackPanelBaseProps & IErrorServiceInjectedProps;

const highPriority: React.CSSProperties = {
  backgroundColor: "#CC0000",
  color: "#FFFFFF"
};

const successful: React.CSSProperties = {
  backgroundColor: "#006400",
  color: "#FFFFFF"
}

class _ErrorStackPanel extends React.Component<IErrorStackPanelProps> {

  @bind
  private _onSnackClose(event: Event | React.SyntheticEvent<any>, reason: SnackbarCloseReason) {
    this.props.errorService.popLastItem();
  }

  @bind
  private _onClose(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.props.errorService.popLastItem();
  }

  @bind
  private _onCallback() {
    const { errorList } = this.props.errorService.getState();
    const isOpen = errorList.length !== 0;
    const messageObject = isOpen ? errorList[errorList.length - 1] : undefined;
    if (messageObject?.action) {
      messageObject.action();
    }

    this.props.errorService.popLastItem();
  }

  render() {
    const { errorList } = this.props.errorService.getState();

    const isOpen = errorList.length !== 0;

    const messageObject = isOpen ? errorList[errorList.length - 1] : undefined;

    const message = isOpen && messageObject ? messageObject.message : "";
    const priority = isOpen && messageObject ? messageObject.priority : "normal";

    const style = priority == "high" ? highPriority : priority === "successful" ? successful : undefined;
    const action = messageObject?.actionName && messageObject?.action ? 
      <Button onClick={this._onCallback} key="callback" style={{ fontWeight: "bold", color: "#D9D9D9" }}>
        {messageObject.actionName}
      </Button>
      :
      <IconButton key="close" color="inherit" onClick={this._onClose}>
        <Close />
      </IconButton>;

    return (
      <Snackbar
        onClose={this._onSnackClose}
        open={isOpen}
        autoHideDuration={6000}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top"
        }}
      >
        <SnackbarContent
          message={message}
          action={[action]}
          style={style}
        />
      </Snackbar>
    );
  }
}

export const ErrorStackPanel = ErrorService.inject(
  _ErrorStackPanel
);
