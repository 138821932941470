import {
  React,
  bind,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  Button
} from "$Imports/MaterialUIComponents";

import {
  Card,
  KeyboardDatePicker,
  TextField,
  TextFieldProps
} from "$Imports/MaterialUIComponents";

import {
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps
} from "$State/QuoteEntryFreezerService";

import {
  CustomerQuote,
  CustomerPortalRateEngineResult,
  CustomerQuoteQuoteStatusEnum,
  OtherQuoteInfoVM
} from "$Generated/api";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  OtherQuoteInfoSchema
} from "$State/QuoteEntryValidation";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

interface IRateModelCardBaseProps { }

type IRateModelCardProps = IRateModelCardBaseProps
  & IQuoteEntryServiceInjectedProps

interface IOwnState {
  hasRefreshed: boolean;
  poNumber: string;
  notes: string;
  hasOtherInfoChanged: boolean;
  errors: ValidationError | null;
}

const styles: {
  rateModelCard: string;
  header: string;
  sum: string;
  entry: string;
  label: string;
  input: string;
  dateInput: string;
  actions: string;
  quoteNumberText: string;
  quoteStatusAccepted: string;
  quoteStatusCompleted: string;
  quoteStatusRequested: string;
  quoteStatusPending: string;
  quoteStatusExpired: string;
  request: string;
  decline: string;
} = require("./RateQuoteCard.scss");

class _RateQuoteCard extends React.Component<IRateModelCardProps, IOwnState> {
  state = {
    hasRefreshed: false, // TODO: pass in customerQuote as a prop and remove this hasRefreshed (will likely require refactor elsewhere)
    poNumber: "",
    notes: "",
    hasOtherInfoChanged: false,
    errors: null
  };

  componentDidMount(): void {
    const { customerQuote } = this.props.QuoteEntryService.getState();
    this.setState({
      poNumber: customerQuote.poNumber ?? "",
      notes: customerQuote.notes ?? ""
    });
  }

  @bind
  private _updateState(isNewWorkaround: boolean): void {
    const { customerQuote } = this.props.QuoteEntryService.getState();
    this.setState({
      hasRefreshed: true,
      poNumber: customerQuote.poNumber ?? "",
      notes: customerQuote.notes ?? ""
    });

    if (isNewWorkaround) {
      this.props.QuoteEntryService.setNewWorkaround(false);
    }
  }

  @bind
  private _onPOChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      poNumber: event.target.value ?? ""
    });
  }

  @bind
  private _onNotesChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      notes: event.target.value ?? ""
    });
  }

  @bind
  private async _onValidateOtherInfo(otherInfo: OtherQuoteInfoVM): Promise<boolean> {
    const schema = OtherQuoteInfoSchema;
    const quoteErrors = await validateSchema(schema, otherInfo);
    this.setState({ errors: quoteErrors });
    if (quoteErrors) {
      return true;
    }
    return false;
  }

  @bind
  private async _onCommit() {
    const { customerQuote, editQuoteOtherInfoMode } = this.props.QuoteEntryService.getState();
    const { poNumber, notes } = this.state;

    if (customerQuote.quoteStatus === "Pending") {
      const hasChanges = this._runOtherInfoChangeDetection(customerQuote, poNumber, notes);

      if (hasChanges) {
        this.props.QuoteEntryService.updateCustomerQuote({ poNumber: poNumber, notes: notes });
        if (!editQuoteOtherInfoMode) {
          this.props.QuoteEntryService.updateEditQuoteOtherInfoMode(true);
        }
      }
    }
    else {
      // unsaved quote saves to state so first actual save persists it
      this.props.QuoteEntryService.updateCustomerQuote({ poNumber: poNumber, notes: notes });
    }
  }

  @bind
  private _saveQuoteStatus(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const status = event.currentTarget.value as CustomerQuoteQuoteStatusEnum

    if (status === "Declined") {
      this.props.QuoteEntryService.openDeclinedReasonModal();
    }
    else if (status === "PendingResave") {
      this.props.QuoteEntryService.updateEditMode(true);
    }
    else {
      this.props.QuoteEntryService.saveQuoteStatus(status);
    }
  }

  @bind
  private async _saveEdits() {
    const { customerQuote, editMode, editQuoteOtherInfoMode } = this.props.QuoteEntryService.getState();
    const { poNumber, notes } = this.state;

    if (editMode && editQuoteOtherInfoMode) {
      this.props.QuoteEntryService.updateCustomerQuote({ poNumber: poNumber, notes: notes });
      await this.props.QuoteEntryService.updateEditMode(false);

      await this.props.QuoteEntryService.rateAndSaveQuote();
    } else if (editMode) {
      this.props.QuoteEntryService.freezer.get().set({ editMode: false });
      await this.props.QuoteEntryService.rateAndSaveQuote();
    } else if (editQuoteOtherInfoMode) {
      const otherQuoteInfo: OtherQuoteInfoVM = {
        quoteId: customerQuote.id,
        poNumber: poNumber,
        notes: notes
      };

      const hasErrors = await this._onValidateOtherInfo(otherQuoteInfo);
      if (hasErrors) {
        return;
      }

      await this.props.QuoteEntryService.saveOtherQuoteInfo(otherQuoteInfo);

      this.setState({
        hasOtherInfoChanged: false
      });
    }
  }

  @bind
  private _runOtherInfoChangeDetection(currentQuote: CustomerQuote, poNumber: string | undefined, notes: string | undefined): boolean {
    const hasChanges = (currentQuote.poNumber ? currentQuote.poNumber !== poNumber : poNumber !== "")
      || (currentQuote.notes ? currentQuote.notes !== notes : notes !== "");

    this.setState({
      hasOtherInfoChanged: hasChanges
    });

    return hasChanges;
  }

  render() {
    const {
      quoteFetchResults,
      customerQuote,
      isOverdimensional,
      viewOnly,
      editMode,
      editQuoteOtherInfoMode,
      hasQuoteChanged,
      isNewWorkaround
    } = this.props.QuoteEntryService.getState();

    let {
      hasRefreshed,
      poNumber,
      notes,
      hasOtherInfoChanged,
      errors
    } = this.state;

    // clear local "other" info state for "New Quote" or refresh of page
    if (isNewWorkaround || (!hasRefreshed && quoteFetchResults.hasFetched)) {
      this._updateState(isNewWorkaround);
    }

    let hasRateEngineData = customerQuote.rateEngineResults != undefined

    let rateEngineResult: CustomerPortalRateEngineResult | undefined = undefined;
    if (hasRateEngineData) {
      rateEngineResult = JSON.parse(customerQuote.rateEngineResults as string) as CustomerPortalRateEngineResult;
    }

    const canEditPO = !viewOnly
      || customerQuote.quoteStatus === "Pending"
      || customerQuote.quoteStatus === "InProgress";

    const statusClassName = customerQuote.quoteStatus === "Accepted" ? styles.quoteStatusAccepted
    : customerQuote.quoteStatus === "Completed" ? styles.quoteStatusCompleted
      : customerQuote.quoteStatus === "Requested" ? styles.quoteStatusRequested
      : customerQuote.quoteStatus === "Pending" ? styles.quoteStatusPending
      : customerQuote.quoteStatus === "Expired" || customerQuote.quoteStatus === "Declined" ? styles.quoteStatusExpired
      : "";

    const validationParser = new ValidationErrorParser<CustomerQuote>(errors);
    const showNegotiatedRate = customerQuote.quoteStatus === "Accepted" || customerQuote.quoteStatus === "Completed";
    const showRequested = customerQuote.quoteStatus ? ["Requested", "Accepted", "Completed"].includes(customerQuote.quoteStatus) : false;

    return (
      <Card className={styles.rateModelCard}>
        <div className={styles.header}>
          Estimate Details
          {<span className={statusClassName}>{customerQuote.quoteStatus}</span>}
          {customerQuote.quoteNumber && <span className={styles.quoteNumberText}>{`EQ${customerQuote.quoteNumber}`}</span>}
        </div>
        <table>
          <tbody>
            <tr>
              <td>Line Haul</td>
              <td>
                {(hasRateEngineData && !isOverdimensional) &&
                  <DisplayFormattedNumber
                    value={rateEngineResult?.lineHaulRate}
                    formatString={"$0,0.00"}
                  />
                }
              </td>
            </tr>
            <tr>
              <td>Tarp</td>
              <td>
                {(hasRateEngineData && !isOverdimensional) &&
                  <DisplayFormattedNumber
                    value={rateEngineResult?.tarpRate}
                    formatString={"$0,0.00"}
                  />
                }
              </td>
            </tr>
            {(customerQuote.isMilitaryBase || customerQuote.isTwicCardRequired) &&
              <tr>
                <td>Location Fee</td>
                <td>
                  {(hasRateEngineData && !isOverdimensional) &&
                    <DisplayFormattedNumber
                      value={rateEngineResult?.locationFee}
                      formatString={"$0,0.00"}
                    />
                  }
                </td>
              </tr>
            }
            <tr>
              <td>
                Fuel Surcharge
                {(hasRateEngineData && !isOverdimensional) &&
                  <>
                    {" ("}
                    <DisplayFormattedNumber
                      value={rateEngineResult?.fuelSurchargePercentage}
                      formatString={"(0.00%)"}
                    />
                    {")"}
                  </>
                }
              </td>
              <td>
                {(hasRateEngineData && !isOverdimensional) &&
                  <DisplayFormattedNumber
                    value={rateEngineResult?.fuelSurcharge}
                    formatString={"$0,0.00"}
                  />
                }
              </td>
            </tr>
            <tr className={styles.sum}>
              <td>Estimated Rate</td>
              <td>
                {(hasRateEngineData && !isOverdimensional) &&
                  <DisplayFormattedNumber
                    value={rateEngineResult?.highTotalRate}
                    formatString={"$0,0.00"}
                  />}
              </td>
            </tr>
          </tbody>
        </table>

        {showNegotiatedRate &&
          <div className={styles.entry}>
            <div className={styles.label}>Kaiser Reference #</div>

            <TextField
              className={styles.input}
              disabled={true}
              value={customerQuote.fullQuote?.freightBillNumber}
            />
          </div>}

        <div className={styles.entry}>
          <div className={styles.label}>Enter PO #</div>

          <TextField
            className={styles.input}
            disabled={!canEditPO}
            inputProps={{ maxLength: 30 }}
            placeholder="PO #"
            value={poNumber}
            onChange={this._onPOChange}
            onBlur={() => this._onCommit()}
          />
        </div>

        <div className={styles.entry}>
          <div className={styles.label}>Enter Notes</div>

          <TextField
            className={styles.input}
            disabled={!canEditPO}
            inputProps={{ maxLength: 500 }}
            placeholder="Notes"
            value={notes}
            onChange={this._onNotesChange}
            onBlur={() => this._onCommit()}
          />
        </div>

        <div className={styles.actions}>
          <Button
            className={styles.request}
            variant="contained"
            value="Requested"
            disabled={customerQuote.quoteStatus === "Expired" || customerQuote.quoteStatus !== "Pending" || editMode || editQuoteOtherInfoMode}
            onClick={this._saveQuoteStatus}
          >
            {showRequested ? "Requested" : "Send Request"}
          </Button>
          {customerQuote.quoteStatus === "Pending" && !editMode && !editQuoteOtherInfoMode &&
            <Button
              variant="contained"
              value="PendingResave"
              disabled={customerQuote.quoteStatus !== "Pending"}
              onClick={this._saveQuoteStatus}
            >
              Edit
            </Button>
          }
          {customerQuote.quoteStatus === "Pending" && (editMode || editQuoteOtherInfoMode) &&
            <Button
              variant="contained"
              value="Save"
              disabled={customerQuote.quoteStatus !== "Pending" || hasQuoteChanged || (editQuoteOtherInfoMode && !hasOtherInfoChanged)}
              onClick={this._saveEdits}
            >
              Save
            </Button>
          }
          <Button
            className={styles.decline}
            variant="contained"
            value="Declined"
            disabled={customerQuote.quoteStatus === "Expired" || customerQuote.quoteStatus !== "Pending" || editMode || editQuoteOtherInfoMode}
            onClick={this._saveQuoteStatus}
          >
            Decline
          </Button>
        </div>
      </Card>
    )
  }
}

export const RateQuoteCard = QuoteEntryService.inject(_RateQuoteCard);