import {
  React,
  bind
} from "$Imports/Imports";

import {
  CustomerQuote
} from "$Generated/api";

import {
  FormControlLabel,
  Checkbox
} from "$Imports/MaterialUIComponents";

interface IAccessRestrictionsProps {
  customerQuote?: CustomerQuote,
  viewOnly?: boolean,
  onAccessCheckboxChange?: (value: Partial<CustomerQuote>) => void
}

const styles: {
  label: string
} = require("./AccessRestrictionsEntry.scss");

export class AccessRestrictionsEntry extends React.PureComponent<IAccessRestrictionsProps> {

  @bind
  private _onAccessCheckboxChange(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    if (this.props.onAccessCheckboxChange) {
      this.props.onAccessCheckboxChange({ [e.target.name]: checked });
    }
  }

  render() {

    const {
      customerQuote,
      viewOnly
    } = this.props;

    return (
      <>
        <div className={styles.label}>Shipper/Consignee Access Restrictions</div>
        <div style={{ display: "flex" }}>
          <FormControlLabel
            label="Military Base"
            control={(
              <Checkbox
                checked={customerQuote?.isMilitaryBase ?? false}
                name="isMilitaryBase"
                onChange={this._onAccessCheckboxChange}
                disabled={viewOnly}
              />
            )}
          />
          <FormControlLabel
            label="TWIC Card Required"
            control={(
              <Checkbox
                checked={customerQuote?.isTwicCardRequired ?? false}
                name="isTwicCardRequired"
                onChange={this._onAccessCheckboxChange}
                disabled={viewOnly}
              />
            )}
          />
        </div>
      </>
    )
  }
}