import {
  CustomerQuote,
  QuoteFreight
} from "$Generated/api";

import {
  IDataTableColumn,
  DataTable,
  DisplayFormattedNumber,
  DisplayFormattedFeet,
  CardLinedHeader,
} from "$Imports/CommonComponents";

import {
  React,
  bind,
  _,
  Helpers
} from "$Imports/Imports";

import {
  Button,
  Tooltip
} from "$Imports/MaterialUIComponents";

import {
  IQuoteEntryServiceInjectedProps,
  QuoteEntryService
} from "$State/QuoteEntryFreezerService";

import {
  QuoteFreightService,
  FreightTotalData,
  IQuoteFreightServiceInjectedProps
} from "$State/QuoteFreightFreezerService";

import {
  ICommodityServiceInjectedProps,
  CommodityService
} from "$State/CommodityFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

const styles: {
  freightButton: string,
  freightTable: string,
  freightColumns: string,
  disabled: string,
  sideBySideColumn: string,
  sideBySideColumnDisabled: string,
  totalRow: string
} = require("./CommodityDataEntryView.scss");

interface ICommodityDataEntryViewBaseProp {
  printMode?: boolean;
}

type ICommodityDataEntryViewProp = IQuoteEntryServiceInjectedProps
  & ICommodityServiceInjectedProps
  & ICommodityDataEntryViewBaseProp
  & IQuoteFreightServiceInjectedProps;

class _CommodityDataEntryView extends React.Component<ICommodityDataEntryViewProp> {

  @bind
  private _onFreightClick() {
    this.props.QuoteFreightService.openFreightModal();
  }

  render() {
    const {
      printMode
    } = this.props;

    const {
      activeCommodities
    } = this.props.commodityService.getState();

    const {
      freightTotalData
    } = this.props.QuoteFreightService.getState();

    const {
      customerQuote,
      quoteValidationErrors,
      viewOnly
    } = this.props.QuoteEntryService.getState();

    const quoteFreight = customerQuote.customerQuoteFreights ?? [];

    const columns: Array<IDataTableColumn<QuoteFreight>> = [
      {
        columnName: "commodity-name",
        columnFieldData: (d) => {
          const foundCommodity = _.find(activeCommodities, co => co.id == d.commodityId);
          let commodityString: string;

          if (!Helpers.isNullOrUndefined(foundCommodity?.commodityShortName)) {
            commodityString = foundCommodity?.commodityShortName ?? "";
          } else {
            commodityString = foundCommodity?.commodityName ?? "";
          }
          return foundCommodity?.commodityShortName ?? foundCommodity?.commodityName ?? "";
        },
        cellProps: (d) => {
          let style = viewOnly ? styles.disabled : styles.freightColumns
          return { className: style }
        },
        headerValue: "Commodity",
        headerProps: {
          className: styles.freightColumns,
          style: { width: "120px" }
        }
      },
      {
        columnName: "numberOfPieces",
        columnFieldData: (d) => {
          return d?.numberOfPieces;
        },
        cellProps: (d) => {
          let style = viewOnly ? styles.disabled : styles.freightColumns
          return { className: style };
        },
        headerValue: "# of Pieces",
        headerProps: {
          className: styles.freightColumns,
          style: { width: "50px" }
        },
        sortMethod: (d) => _.reduce(_.map(currentQuoteFreight, qsf => qsf.numberOfPieces), ((sum, p) => (sum ?? 0) + (p ?? 0))) ?? 0
      },
      {
        columnName: "length",
        columnFieldData: (d) => <DisplayFormattedFeet value={d.length} />,
        cellProps: (d) => {
          let style = viewOnly ? styles.disabled : styles.freightColumns
          return { className: style }
        },
        headerValue: "Length",
        headerProps: {
          className: styles.freightColumns,
          style: { width: "55px" }
        },
        sortMethod: (d) => d.length
      },
      {
        columnName: "width",
        columnFieldData: (d) => <DisplayFormattedFeet value={d.width} />,
        cellProps: (d) => {
          let style = viewOnly ? styles.disabled : styles.freightColumns
          return { className: style }
        },
        headerValue: "Width",
        headerProps: {
          className: styles.freightColumns,
          style: { width: "55px" }
        },
        sortMethod: (d) => d.width
      },
      {
        columnName: "height",
        columnFieldData: (d) => <DisplayFormattedFeet value={d.height} />,
        cellProps: (d) => {
          let style = viewOnly ? styles.disabled : styles.freightColumns
          return { className: style }
        },
        headerValue: "Height",
        headerProps: {
          className: styles.freightColumns,
          style: { width: "55px" }
        },
        sortMethod: (d) => d.height
      },
      {
        columnName: "weight",
        columnFieldData: (d) => <DisplayFormattedNumber value={d.weight} formatString={"0"} postfix={" lbs"} />,
        cellProps: (d) => {
          let style = viewOnly ? styles.disabled : styles.freightColumns
          return { className: style }
        },
        headerValue: "Weight",
        headerProps: {
          className: styles.freightColumns,
          style: { width: "75px" }
        },
        sortMethod: (d) => d.weight
      },
      {
        columnName: "isStackable",
        columnFieldData: (d) => d.isStackable && d.isStackable ? "Yes" : "No",
        cellProps: (d) => {
          let style = viewOnly ? styles.disabled : styles.freightColumns
          return { className: style }
        },
        headerValue: "Stackable",
        headerProps: {
          className: styles.freightColumns,
          style: { width: "50px" }
        },
        sortMethod: (d) => d.isStackable
      },
      {
        columnName: "isSideBySide",
        columnFieldData: (d) => d.isSideBySide && d.isSideBySide ? "Yes" : "No",
        cellProps: (d) => {
          let style = viewOnly ? styles.sideBySideColumnDisabled : styles.sideBySideColumn
          return { className: style }
        },
        headerValue: "Side by Side",
        headerProps: {
          className: styles.freightColumns,
          style: { width: "60px" }
        },
        sortMethod: (d) => d.isSideBySide
      }
    ];

    const validationsParserQuote = new ValidationErrorParser<CustomerQuote>(quoteValidationErrors);

    const currentQuoteFreight = quoteFreight;

    let freightCommodities: QuoteFreight[];

    if (currentQuoteFreight) {
      freightCommodities = currentQuoteFreight;
    }
    else {
      freightCommodities = [];
    }

    let totalRow: JSX.Element | undefined = undefined;
    if (freightTotalData.totalNumOfPieces !== undefined && freightTotalData.totalNumOfPieces > 0) {
      totalRow = <tr className={styles.totalRow}>
        <td>TOTAL</td>
        <td>{freightTotalData.totalNumOfPieces}</td>
        <td>{
          freightTotalData.isOverdimensional
          ?
          <div style={{ whiteSpace: "nowrap" }}>
            {"--"} <span style={{ color: "red", fontSize: "12px", paddingLeft: "20px" }}>{"Over Dimensional / Requires Approval"}</span>
          </div>
          :
          <div><DisplayFormattedFeet value={freightTotalData.totalLength} /></div>
        }</td>
        <td></td>
        <td></td>
        <td>{`${freightTotalData.totalWeight ?? 0} lbs`}</td>
        <td></td>
        <td></td>
      </tr>
    }

    return (
      <CardLinedHeader title={`${!printMode ? "Specify " : ""}Freight Dimensions`} color="black">
        <div className={!printMode ? styles.freightTable : undefined} style={freightCommodities.length >= 3 && !printMode ? { overflowY: "scroll", overflowX: "hidden" } : undefined}>
          <DataTable
            columns={columns}
            data={freightCommodities}
            tableFooterComponent={totalRow}
          />
        </div>
        {!printMode &&
          <>
            <div className={styles.freightButton}>
              <Tooltip title={"Add/Edit Freight"} placement={"top"}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => this._onFreightClick()}
                  disabled={viewOnly}
                >
                  Add/Edit Freight
                </Button>
              </Tooltip>
            </div>
            <div>
              {!validationsParserQuote.isValidDeep(`customerQuoteFreights`) &&
                <h3 style={{ color: "rgb(243, 17, 17)", textAlign: "center" }}>
                  {validationsParserQuote.validationMessageDeep(`customerQuoteFreights`)}
                </h3>}
            </div>
          </>
        }
      </CardLinedHeader>
    );
  }
}

export const CommodityDataEntryView = QuoteEntryService.inject(
  QuoteFreightService.inject(
    CommodityService.inject(
      _CommodityDataEntryView
    )
  )
);
