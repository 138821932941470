import {
  moment,
  NullableOptional,
  _
} from "$Imports/Imports";

import {
  SchemaOf
} from "$Shared/imports/Yup";

import {
  ZipCodePair,
  CustomerQuote,
  CustomerQuoteRateVariableFactorEnum,
  CustomerQuoteFreight,
  CustomerQuoteQuoteStatusEnum,
  OtherQuoteInfoVM
} from "$Generated/api";

import yup from "$Shared/utilities/yupExtension";

const CustomerQuoteSchema: SchemaOf<NullableOptional<CustomerQuote>> = yup.object({
  id: yup.number().notRequired(),
  companyId: yup.number().notRequired(),
  fullQuoteId: yup.number().notRequired().nullable(true),
  fullQuote: yup.object().notRequired().nullable(true),
  customerId: yup.number().notRequired(),
  quoteDate: yup.date().notRequired(),
  expirationDate: yup.date().notRequired(),
  shipperZipPostalCode: yup.string().notRequired(),
  shipperStartDate: yup.date().required("Date is required").test("shipperStartDate", "${message}", (value: Date | undefined, testContext: any) => {
    const currentDate = testContext.options.context.currentTime;
    const chosenDate = moment(value).startOf('day')

    if (chosenDate.isBefore(currentDate)) {
      return testContext.createError({ message: "Date cannot be in the past." });
    }

    return true;
  }),
  shipperEndDate: yup.date()
    .test("shipperEndDate", "${message}", (value: Date | undefined, testContext: any) => {
      const currentDate = testContext.options.context.currentTime;
      const chosenDate = moment(value).startOf('day');

      if (chosenDate.isBefore(currentDate)) {
        return testContext.createError({ message: "Date cannot be in the past." });
      }

      return true;
    })
    .when('shipperStartDate', (shipperStartDate: Date, schema: any) => {
      return shipperStartDate ? schema.min(shipperStartDate, "The Shipping End Date must be after the Shipper Start Date")
        : schema.notRequired();
    })
    .typeError("Invalid Date")
    .transform((value: any) => value && !isNaN(value.valueOf()) ? value : undefined),
  shipperHardTime: yup.string()
    .when('isShipperAppointmentRequired', (isShipperAppointmentRequired: boolean, schema: any) => {
      return isShipperAppointmentRequired ? schema.required("Time is required if an appointment is required").timeHHmm()
        : schema.notRequired();
    })
    .transform((value: any) => value || undefined),
  isShipperAppointmentRequired: yup.boolean().notRequired(),
  consigneeZipPostalCode: yup.string().notRequired(),
  consigneeStartDate: yup.date().required("Date is required")
    .test("consigneeStartDate", "${message}", (value: Date | undefined, testContext: any) => {
      const currentDate = testContext.options.context.currentTime;
      const chosenDate = moment(value).startOf('day');

      if (chosenDate.isBefore(currentDate)) {
        return testContext.createError({ message: "Date cannot be in the past." });
      }

      return true;
    })
    .typeError("Invalid Date")
    .when('shipperEndDate', (shipperEndDate: Date, schema: any) => {
      return shipperEndDate && !isNaN(shipperEndDate.valueOf()) ? schema.min(shipperEndDate, "The Consignee dates must begin after the shipper dates")
        : schema.min(yup.ref('shipperStartDate'), "The Consignee dates must begin after the shipper dates")
    })
    .transform((value: any) => value && !isNaN(value.valueOf()) ? value : undefined),
  consigneeEndDate: yup.date()
    .test("consigneeEndDate", "${message}", (value: Date | undefined, testContext: any) => {
      const currentDate = testContext.options.context.currentTime;
      const chosenDate = moment(value).startOf('day');

      if (chosenDate.isBefore(currentDate)) {
        return testContext.createError({ message: "Date cannot be in the past." });
      }

      return true;
    })
    .typeError("Invalid Date")
    .when('consigneeStartDate', (consigneeStartDate: Date, schema: any) => {
      return consigneeStartDate ? schema.min(consigneeStartDate, "The Consignee End Date must be after the Consignee Start Date")
        : schema.notRequired();
    })
    .transform((value: any) => value && !isNaN(value.valueOf()) ? value : undefined),
  consigneeHardTime: yup.string()
    .when('isConsigneeAppointmentRequired', (isConsigneeAppointmentRequired: boolean, schema: any) => {
      return isConsigneeAppointmentRequired ? schema.required("Time is required if an appointment is required").timeHHmm()
        : schema.notRequired();
    })
    .transform((value: any) => value || undefined),
  isConsigneeAppointmentRequired: yup.boolean().notRequired(),
  isExpedited: yup.boolean().notRequired(),
  declaredValue: yup.number().required("Declared Value is required"),
  description: yup.string().notRequired().max(250, "Description cannot exceed 250 characters.").transform((value: any) => value || undefined),
  tarpId: yup.number().required("Tarp is required").transform((value: any) => value || undefined),
  rateEngineResults: yup.string().notRequired(),
  quoteStatus: yup.mixed<CustomerQuoteQuoteStatusEnum>().oneOf(["Accepted", "Pending", "Declined", "Expired"]).notRequired().nullable(true),
  mileage: yup.number().notRequired(),
  datRate: yup.number().notRequired(),
  quoteStatusMessage: yup.string().notRequired().nullable(true),
  quoteNumber: yup.number().notRequired(),
  copiedQuoteNumber: yup.number().notRequired().allowNaN(),
  rateVariable: yup.number().notRequired(),
  rateVariableFactor: yup.mixed<CustomerQuoteRateVariableFactorEnum>().oneOf(["Length", "Weight", "Overdimensional"]).notRequired().nullable(true),
  rate: yup.number().notRequired(),
  copiedQuoteRate: yup.number().notRequired().allowNaN(),
  createdOn: yup.date().notRequired(),
  createdById: yup.number().notRequired(),
  company: yup.object().notRequired().nullable(true),
  createdBy: yup.object().notRequired().nullable(true),
  customer: yup.object().notRequired().nullable(true),
  tarp: yup.object().notRequired().nullable(true),
  poNumber: yup.string().nullable().notRequired().max(30, "PO Number cannot be more than 30 characters."),
  customerContactId: yup.number().notRequired().nullable(true), // validation is contingent on the status being set; handled in QuoteEntryFreezerService.save
  customerContact: yup.mixed().notRequired().nullable(true),
  notes: yup.string().nullable().notRequired().max(500, "Notes cannot exceed 500 characters.").transform((value: any) => value || undefined),
  customerQuoteFreights: yup.array().notRequired()
    .test("customerQuoteFreights", "${message}", (value: CustomerQuoteFreight[] | undefined, testContext: any) => {
      if(value === undefined || value.length === 0)
        return testContext.createError({ message: "**There must be at least one commodity included**" });
      else
        return true;
  }),
  equipmentTypeId: yup.number().required("Equipment type is required"),
  equipmentType: yup.mixed().notRequired(),
  isMilitaryBase: yup.boolean().notRequired(),
  isTwicCardRequired: yup.boolean().notRequired(),
  isReviewed: yup.boolean().notRequired()
});

const OtherQuoteInfoSchema: SchemaOf<NullableOptional<OtherQuoteInfoVM>> = yup.object({
  quoteId: yup.number().notRequired(),
  poNumber: yup.string().nullable().notRequired().max(30, "PO Number cannot be more than 30 characters."),
  notes: yup.string().nullable().notRequired().max(500, "Notes cannot exceed 500 characters.").transform((value: any) => value || undefined)
});

const ZipCodePairSchema: SchemaOf<NullableOptional<ZipCodePair>> = yup.object({
  originZipPostalCode: yup.string()
    .required("Shipper zip code is required")
    .zipCode()
    .test("originZipPostalCode", "${message}", (value: string | undefined, testContext: any) => {
      const isShipperZipInvalid = testContext.options.context.isShipperZipInvalid;
      const validateZips = testContext.options.context.validateZips;
      const shipperZipPCMilerMessage = testContext.options.context.shipperZipPCMilerMessage;

      if (validateZips && isShipperZipInvalid) {
        return testContext.createError({ message: shipperZipPCMilerMessage ?? "Shipper zip code could not be found." });
      }
      return true;
    }),
  destZipPostalCode: yup.string()
    .required("Consignee zip code is required")
    .zipCode()
    .test("destZipPostalCode", "${message}", (value: string | undefined, testContext: any) => {
      const isConsigneeZipInvalid = testContext.options.context.isConsigneeZipInvalid;
      const validateZips = testContext.options.context.validateZips;
      const consigneeZipPCMilerMessage = testContext.options.context.consigneeZipPCMilerMessage;

      if (validateZips && isConsigneeZipInvalid) {
        return testContext.createError({ message: consigneeZipPCMilerMessage ?? "Consignee zip code could not be found." });
      }
      return true;
    }),
});

const DeclinedReasonSchema: SchemaOf<string> = yup.string().required("Declined reason is required").max(200, "Declined reason can not exceed 200 characters").nullable(true);


export {
  CustomerQuoteSchema,
  ZipCodePairSchema,
  DeclinedReasonSchema,
  OtherQuoteInfoSchema
}