import {
  React,
  bind
} from "$Imports/Imports";

import {
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  Button
} from "$Imports/MaterialUIComponents";

import {
  IQuoteEntryServiceInjectedProps,
  QuoteEntryService
} from "$State/QuoteEntryFreezerService";

import {
  IQuoteFreightServiceInjectedProps,
  QuoteFreightService
} from "$State/QuoteFreightFreezerService";

import {
  IQuoteZipCodeMileageServiceInjectedProps,
  QuoteZipCodeMileageService
} from "$State/QuoteZipCodeMileageFreezerService";

import {
  TarpService,
  ITarpServiceInjectedProps
} from "$State/TarpFreezerService";

import {
  printQuote
} from "$Utilities/windowUtil";

interface IOwnProps { }

type OwnProps = IOwnProps
  & IQuoteEntryServiceInjectedProps
  & IQuoteFreightServiceInjectedProps
  & IQuoteZipCodeMileageServiceInjectedProps
  & ITarpServiceInjectedProps;

const styles: {
  container: string;
} = require("./QuoteActions.scss");

class _QuoteActions extends React.Component<OwnProps> {
  @bind
  private _clearQuote() {
    this.props.QuoteEntryService.clearFreezer();
    this.props.QuoteEntryService.setNewWorkaround(true);
    this.props.QuoteZipCodeMileageService.clearFreezer();
    this.props.TarpService.fetchTarps(true);
    this.props.QuoteFreightService.calculateFreightTotalResults([]);
  }

  @bind
  private _onCopyQuote() {
    this.props.QuoteEntryService.copyCurrentQuote();
  }

  @bind
  private _printQuote() {
    const quoteId = this.props.QuoteEntryService.freezer.get().customerQuote.toJS().id;

    if (quoteId) {
      printQuote(quoteId);
    }
  }

  render() {
    const {
      quoteStatusSaveResults,
      viewOnly,
      editMode,
      editQuoteOtherInfoMode
    } = this.props.QuoteEntryService.getState();

    return (quoteStatusSaveResults.hasFetched || viewOnly || editMode) && (
      <UserAccessControl roles={["quote:create", "quote:edit"]}>
        <div className={styles.container}>
          <Button
            color="primary"
            variant="contained"
            disabled={editMode || editQuoteOtherInfoMode}
            onClick={this._printQuote}
          >
            Print Quote
          </Button>

          <Button
            color="primary"
            variant="contained"
            disabled={editMode || editQuoteOtherInfoMode}
            onClick={this._onCopyQuote}
          >
            Copy Quote
          </Button>

          <Button
            color="primary"
            variant="contained"
            disabled={editMode || editQuoteOtherInfoMode}
            onClick={this._clearQuote}
          >
            New Quote
          </Button>
        </div>
      </UserAccessControl>
    );
  }
}

export const QuoteActions = QuoteEntryService.inject(
  QuoteFreightService.inject(
    QuoteZipCodeMileageService.inject(
      TarpService.inject(
        _QuoteActions
      )
    )
  )
);