import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  TableRow,
} from "$Imports/MaterialUIComponents";

import {
  TableContextConsumer
} from "./DataTable";

import {
  DataTableCell
} from "./DataTableCell";

import {
  IDataTableColumn
} from "./IDataTableColumn";

export interface IDataTableRowProps<T = unknown> {
  data: T;
  columns: Array<IDataTableColumn<T>>;
  dataIndex: number;
  onRowClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, data: T) => void;
  onRowDoubleClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, data: T) => void;
  onCellClick?: (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, data: T, column: IDataTableColumn<T>) => void;
}

export class DataTableRow<T = unknown> extends React.PureComponent<IDataTableRowProps<T>> {

  @bind
  private _onRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
    if (this.props.onRowClick) {
      this.props.onRowClick(event, this.props.data);
    }
  }

  @bind 
  private _onRowDoubleClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
    if (this.props.onRowDoubleClick) {
      this.props.onRowDoubleClick(event, this.props.data);
    }
  }

  @bind
  private _onCellClick(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, data: T, column: IDataTableColumn<T>) {
    if (this.props.onCellClick) {
      this.props.onCellClick(event, data, column);
    }
  }

  render() {
    const { data, columns, dataIndex } = this.props;

    return (
      <TableContextConsumer>
        {(context) => {
          const rowStyle = context && context.rowStyle && context.rowStyle(data);
          const isSelected = context && context.selectedRow && _.isEqual(context.selectedRow, data);

          return (
            <TableRow
              onClick={this._onRowClick}
              onDoubleClick={this._onRowDoubleClick}
              selected={isSelected}
              ref={(instance: HTMLTableRowElement | null) => (isSelected && context && context.setScroll) ? context.setScroll(instance, context?.containerDivRef) : null}
            >
              {
                _.map(columns, (c, cidx) => {
                  if (context && context.hiddenColumns && context.hiddenColumns.includes(c.columnName)) {
                    return;
                  }

                  if (context && context.disableAction && c.columnName === 'action') {
                    return;
                  }

                  return (
                    <DataTableCell
                      column={c}
                      data={data}
                      dataIndex = {dataIndex}
                      key={cidx}
                      onCellClick={this._onCellClick}
                      style={rowStyle}
                    />
                  );
                })
              }
            </TableRow>)
        }}
      </TableContextConsumer>
    );
  }
}
