import {
  React
} from "$Imports/Imports";

import {
  Link
} from "$Imports/MaterialUIComponents";

import {
  IQuoteEntryServiceInjectedProps,
  QuoteEntryService
} from "$State/QuoteEntryFreezerService";

import {
  ICustomerQuoteServiceInjectedProps,
  CustomerQuoteService,
} from "$State/CustomerQuotesFreezerService";

import {
  CustomerQuote
} from "$Generated/api";

import {
  openCustomerQuoteInNewWindow
} from "$Utilities/windowUtil";

interface ICustomerQuoteLinkBaseProps {
  quoteNumber?: string;
}

type ICustomerQuoteLinkProps = ICustomerQuoteLinkBaseProps
  & IQuoteEntryServiceInjectedProps
  & ICustomerQuoteServiceInjectedProps;

class _CustomerQuoteLink extends React.PureComponent<ICustomerQuoteLinkProps> {
  private async _onViewQuote(event: React.MouseEvent, url: string){
    // prevent "href" from navigating + reloading app in current window
    event.preventDefault();
    
    // TODO: Update to use quote Id or quoteNumber consistently
    await CustomerQuoteService.fetchQuote(this.props.quoteNumber);
    const customerQuote = CustomerQuoteService.getState().quoteFetchResults.data as CustomerQuote;
    if (customerQuote.id) {
      openCustomerQuoteInNewWindow(customerQuote.id)
    }
  }

  render() {
    const {
      quoteNumber
    } = this.props;

    const url = `/quotes/`;

    return (
      <Link
        href={`/quotes/`}
        color="#0000EE"
        onClick={(event) => this._onViewQuote(event, url)}
      >
        {`EQ${quoteNumber}`}
      </Link>
    );
  }
}

export const CustomerQuoteLink = QuoteEntryService.inject(
  CustomerQuoteService.inject(
    _CustomerQuoteLink
));