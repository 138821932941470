import { defaultTheme } from "./defaultTheme";
import {
  blueTheme,
  transportBlue,
  highlightBlue
} from "./blueTheme";
import {
  greenTheme,
  logisticsGreen,
  highlightGreen
} from "./greenTheme";

export {
  defaultTheme,
  blueTheme,
  transportBlue,
  highlightBlue,
  greenTheme,
  highlightGreen,
  logisticsGreen
};
