import {
  managedAjaxUtil,
  FreezerService,
  bind,
  _,
  IAjaxState,
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  ZipCodePair,
  ZipCodePairMileage,
  PCMilerApiFactory,
  Place
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import yup from "$Shared/utilities/yupExtension";

import {
  ZipCodePairSchema
} from "./QuoteEntryValidation";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

const InjectedPropName = "QuoteZipCodeMileageService";

interface IQuoteZipCodeMileageServiceState {
  shipperConsigneeMileageResults: IAjaxState<ZipCodePairMileage>;
  shipperPlaceResults: IAjaxState<Place[]>;
  consigneePlaceResults: IAjaxState<Place[]>;
  isZipValidationSuccessful: boolean;
  isShipperZipInvalid: boolean;
  shipperZipPCMilerMessage: string;
  isConsigneeZipInvalid: boolean;
  consigneeZipPCMilerMessage: string;
  zipCodePair: ZipCodePair;
  shipperConsigneeValidationErrors: ValidationError | null;
}

const initialState = {
    shipperConsigneeMileageResults: managedAjaxUtil.createInitialState(),
    shipperPlaceResults: managedAjaxUtil.createInitialState(),
    consigneePlaceResults: managedAjaxUtil.createInitialState(),
    isZipValidationSuccessful: false,
    isShipperZipInvalid: false,
    isConsigneeZipInvalid: false,
    zipCodePair:{
      destZipPostalCode:undefined,
      originZipPostalCode:undefined
    }
} as IQuoteZipCodeMileageServiceState

class QuoteZipCodeMileageFreezerService extends FreezerService<IQuoteZipCodeMileageServiceState, typeof InjectedPropName> {

  constructor(){
    super(initialState, InjectedPropName);
    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
      this.freezer.get().set(initialState);
  }

  public async validate(){
    const isValid =  await this.isValidShipperConsignee();
    return isValid;
  }
  
  public async updateShipperZipCode(zipCode:string) : Promise<number | undefined> {
    const {
      zipCodePair
    } = this.freezer.get().toJS();

    zipCodePair.originZipPostalCode = zipCode;

    const mileage = await this.validateAndFetchMileage(zipCodePair);

    return mileage;
  }

  public async updateConsigneeZipCode(zipCode:string) : Promise<number | undefined>{
    const {
      zipCodePair
    } = this.freezer.get().toJS();

    zipCodePair.destZipPostalCode = zipCode;

    const mileage = await this.validateAndFetchMileage(zipCodePair);

    return mileage;
  }

  public async queryForPlace(zipcode: string, whichPlace: "shipper" | "consignee") : Promise<Place[] | undefined> {
    // we have to query shippers and consignees separately because two requests could be in flight simultaneously
    if (whichPlace === "shipper" && this.freezer.get().shipperPlaceResults.isFetching) return;
    if (whichPlace === "consignee" && this.freezer.get().consigneePlaceResults.isFetching) return;

    const places = await this.validateAndFetchPlace(zipcode, whichPlace);

    return places;
  }

  private async validateAndFetchPlace(zipcode: string, whichPlace: "shipper" | "consignee") : Promise<Place[] | undefined> {
    try {
      await yup.string().zipCode().validate(zipcode);

      await managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        ajaxStateProperty: whichPlace === "shipper" ? "shipperPlaceResults" : "consigneePlaceResults",
        params: {
          searchCriteria: zipcode
        },
        onExecute: (apiOptions, params, options) => {
          const factory = PCMilerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return factory.apiV1PCMilerGetZipCodesByCityStatePost(params);
        },
        onError: (err, errorMessage) => {
          ErrorService.pushErrorMessage("Failed to fetch location search results.");
        }
      });

      if (whichPlace === "shipper") {
        if (this.freezer.get().shipperPlaceResults.hasFetched && !this.freezer.get().shipperPlaceResults.error) {
          return this.freezer.get().shipperPlaceResults.data?.toJS();
        }
      }
      else if (whichPlace === "consignee") {
        if (this.freezer.get().consigneePlaceResults.hasFetched && !this.freezer.get().consigneePlaceResults.error) {
          return this.freezer.get().consigneePlaceResults.data?.toJS();
        }
      }
    }
    catch {
      // ignore; validation and display of error messages happens elsewhere
    }

    return undefined;
  }

  private async validateAndFetchMileage(newZipCodePair:ZipCodePair) : Promise<number | undefined> {
    
    const {
      zipCodePair
    } = this.freezer.get().toJS();

    this.freezer.get().set({
      zipCodePair: newZipCodePair
    });

    //Check if the zips are unchanged, if they haven't changed no need to take any action
    //We can assume validation has been run and if passed, we've fetched already(or are currently fetching, in which case we can do nothing)
    if(zipCodePair.destZipPostalCode === newZipCodePair.destZipPostalCode && zipCodePair.originZipPostalCode === newZipCodePair.originZipPostalCode){
        if(this.freezer.get().shipperConsigneeMileageResults.hasFetched && !this.freezer.get().shipperConsigneeMileageResults.error){
          return this.freezer.get().shipperConsigneeMileageResults.data?.miles;
        }
    }
    else{
      //Validate, then if valid, fetch mileage
      const isValid = await this.isValidShipperConsignee();
      if (isValid) {
        await this.getShipperConsigneeMileage();
        if(this.freezer.get().shipperConsigneeMileageResults.hasFetched 
          && !this.freezer.get().shipperConsigneeMileageResults.error
          && (!this.freezer.get().shipperConsigneeMileageResults.data?.error || this.freezer.get().shipperConsigneeMileageResults.data?.error?.length === 0)){
          return this.freezer.get().shipperConsigneeMileageResults.data?.miles;
        }
        
        //If we get here it means PC Miler had issues 
        //trigger the validation so we can update the ui on the validation messages that were set by the pc miler api response
        this.isValidShipperConsignee(true)
      }
      else{
        //If we're not valid we should reset the freezer from any previous calls
        this.freezer.get().set({
          shipperConsigneeMileageResults: managedAjaxUtil.createInitialState()
        });
      }
    }

    return undefined;
  }

  public async getShipperConsigneeMileage() {
    const {
      shipperConsigneeMileageResults,
      zipCodePair,
    } = this.freezer.get().toJS();

    if (shipperConsigneeMileageResults.isFetching) {
      return;
    }
  
    if (zipCodePair.originZipPostalCode && zipCodePair.destZipPostalCode) {
      const isValidFormat = await this.isValidShipperConsignee();
      if (!isValidFormat) {
        return;
      }

      await this.getMileage(zipCodePair.originZipPostalCode, zipCodePair.destZipPostalCode);

      if (!isValidFormat) {
        return;
      }

      this.freezer.get().set({
        isZipValidationSuccessful: true
      });
    }
  }

  private async isValidShipperConsignee(validateZips: boolean = false): Promise<boolean> {
    const {
      isShipperZipInvalid,
      shipperZipPCMilerMessage,
      isConsigneeZipInvalid,
      consigneeZipPCMilerMessage,
      zipCodePair
    } = this.freezer.get().toJS();

    const errors = await validateSchema(ZipCodePairSchema, zipCodePair, {
      context: {
        validateZips: validateZips,
        isShipperZipInvalid: isShipperZipInvalid,
        shipperZipPCMilerMessage: shipperZipPCMilerMessage,
        isConsigneeZipInvalid: isConsigneeZipInvalid,
        consigneeZipPCMilerMessage: consigneeZipPCMilerMessage
      },
      abortEarly: false
    });

    if (errors) {
      this.freezer.get().set({ shipperConsigneeValidationErrors: errors });
      return false;
    }

    this.freezer.get().set({
      shipperConsigneeValidationErrors: null,
      shipperZipPCMilerMessage: undefined,
      consigneeZipPCMilerMessage: undefined
    });
    return true;
  }

  public async getMileage(origin: string, destination: string) {
    const args = {
      origin: origin,
      destination: destination
    };

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "shipperConsigneeMileageResults",
      params: args,
      onExecute: (apiOptions, params, options) => {
        const factory = PCMilerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1PCMilerMileageOriginDestinationGet(params);
      },
      onOk: (data: ZipCodePairMileage) => {
        if (!data.successful && data.error) {
          data.error.forEach((err) => {
            if (err.includes("origin")) {
              this.freezer.get().set({
                isShipperZipInvalid: true,
                shipperZipPCMilerMessage: err
              });
            }
            else if (err.includes("destination")) {
              this.freezer.get().set({
                isConsigneeZipInvalid: true,
                consigneeZipPCMilerMessage: err
              });
            }

            this.freezer.get().set({
              isZipValidationSuccessful: false
            });
          });
          ErrorService.pushErrorMessage(data.error[0] ?? "Mileage could not be calculated - invalid zip.");
        }
        else if (data.successful) {
          this.freezer.get().set({
            isZipValidationSuccessful: true
          });
        }
      },
      onError: (err, errorMessage) => {
        const serverMessage = err?.body?.message;
        if (serverMessage && serverMessage == "PCMiler is unavailable") {
          ErrorService.pushErrorMessage("PC Miler is not available. Contact IT Support", "high", "Retry", () => this.getMileage(origin, destination));
        }
        else if (serverMessage && serverMessage.includes("PCMiler")) {
          ErrorService.pushErrorMessage(serverMessage);
        }
        else {
          ErrorService.pushErrorMessage("Error getting mileage");
        }
      }
    });
  }
}


export const QuoteZipCodeMileageService = new QuoteZipCodeMileageFreezerService();
export type IQuoteZipCodeMileageServiceInjectedProps = ReturnType<QuoteZipCodeMileageFreezerService["getPropsForInjection"]>;