import {
  React,
} from "$Imports/Imports";

import {
  CustomerQuoteView
} from "./CustomerQuoteView";

import {
  IQuoteEntryServiceInjectedProps,
  QuoteEntryService
} from "$State/QuoteEntryFreezerService";

interface ICustomerQuoteViewPageBaseProps {
  quoteId?: string;
}

type CustomerQuoteViewPageBaseProps = ICustomerQuoteViewPageBaseProps
& IQuoteEntryServiceInjectedProps;

class _CustomerQuoteViewPage extends React.Component<CustomerQuoteViewPageBaseProps> {
  async componentDidMount() {
    const {customerQuote} = this.props.QuoteEntryService.getState();
    const id = Number(this.props.quoteId);
    if (!Number.isNaN(id)  && customerQuote?.id !== id) {
      await this.props.QuoteEntryService.fetchQuoteById(this.props.quoteId);
    }
  }

  render() {

    return ( 
      <CustomerQuoteView />
    );
  }
}

export const CustomerQuoteViewPage = QuoteEntryService.inject(
    _CustomerQuoteViewPage
);