import {
  React,
  bind,
  withAuth,
  AuthContextProps,
  _
} from "$Imports/Imports";

import {
  AppBar,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  Avatar
} from "$Imports/MaterialUIComponents";

import {
  ThemeProvider,
  createTheme
} from "$Imports/MaterialUIStyles"

import {
  ThemeConsumer
} from "$Providers/index";

import {
  INavigationServiceInjectedProps,
  NavigationService,
} from "$State/NavigationFreezerService";

import {
  IAboutServiceInjectedProps,
  AboutService
} from "$State/AboutFreezerService";

import {  
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  AboutModal
} from "$Imports/CommonComponents";

import {
  HelpModal
} from "$Imports/CommonComponents";

import { Help } from "$Imports/MaterialUIIcons";

interface IApplicationBarBaseProps {
}

interface IApplicationBarState {
  anchorEl: Element | null;
  menuOpen: boolean;
  helpModalOpen: boolean;
}
type IApplicationBarProps = IApplicationBarBaseProps
  & INavigationServiceInjectedProps
  & IAboutServiceInjectedProps
  & AuthContextProps;

const styles: {
  applicationBarContainer: string;
  applicationBarSection: string;
  logo: string;
  applicationName: string;
  helpButton: string;
  usernameDisplay: string;
} = require("./MainLayout.scss");

import * as kaiserLogo from "../../images/kaiser-red-small.png";

class _ApplicationBar extends React.Component<IApplicationBarProps, IApplicationBarState> {

  state: IApplicationBarState = {
    anchorEl: null,
    menuOpen: false,
    helpModalOpen: false
  };

  componentDidMount() {
    this.props.aboutService.fetchData();
  }

  @bind
  private async logout() {   
    await SharedSecurityContext.logout()
  }

  @bind
  private openAbout() {
    this.closeMenu();
    this.props.aboutService.openModal();
  }

  @bind
  private closeAbout() {
    this.props.aboutService.closeModal();
  }

  @bind
  private openMenu(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({
      anchorEl: event.currentTarget,
      menuOpen: true
    });
  }

  @bind
  private closeMenu() {
    this.setState({
      menuOpen: false
    });
  }

  @bind
  private openHelp(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({
      helpModalOpen: true
    });
  }

  @bind
  private closeHelp() {
    this.setState({
      helpModalOpen: false
    });
  }

  render() {
    const user = SharedSecurityContext.getUser();
    this.props.navigationService.updateTitle();

    const {
      modalOpen: aboutModalOpen,
      aboutFetchResults
    } = this.props.aboutService.getState();

    const {
      helpModalOpen
    } = this.state;

    return (
      <ThemeConsumer>{(context) => {
        const appBarTheme = createTheme(context.themeConfig.themeOptions, context.themeConfig.appBarNavigationMenu);
        return (
          <ThemeProvider
            theme={appBarTheme}
          >
            <AppBar
              position="relative"
            >
              <Toolbar className={styles.applicationBarContainer}>
                <div className={styles.applicationBarSection}>
                  <img alt="" src={kaiserLogo} className={styles.logo}/>
                  <h1 className={styles.applicationName}>Customer Quote Portal</h1>
                </div>

                <div className={styles.applicationBarSection}>
                  <IconButton
                    className={styles.helpButton}
                    onClick={this.openHelp}
                    title="Help"
                    type="submit"
                    color="inherit"
                  >
                    <Help />
                    Help
                  </IconButton>

                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={this.openMenu}
                    color="inherit"
                  >
                    <Avatar>
                      {`${user?.profile?.given_name?.charAt(0)}${user?.profile?.family_name?.charAt(0)}`}
                    </Avatar>
                    <span className={styles.usernameDisplay}>{this.props.user?.profile?.name}</span>
                  </IconButton>
                  <Menu
                    anchorEl={this.state.anchorEl}
                    open={this.state.menuOpen}
                    onClose={this.closeMenu}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem onClick={this.closeMenu}>Profile</MenuItem>
                    <MenuItem onClick={this.openAbout}>About</MenuItem>
                    <MenuItem onClick={this.logout}>Logout</MenuItem>
                  </Menu>
                </div>
              </Toolbar>
              <AboutModal
                isOpen={aboutModalOpen}
                aboutVM={aboutFetchResults.data}
                onClose={this.closeAbout}
              />
              <HelpModal
                isOpen={helpModalOpen}
                onClose={this.closeHelp}
              />
            </AppBar>
          </ThemeProvider>
        );
      }}
      </ThemeConsumer>
    );
  }
}

export const ApplicationBar = withAuth(
  NavigationService.inject(
  AboutService.inject(_ApplicationBar)));
