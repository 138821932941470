import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState
} from "$Imports/Imports";

import {
  CommodityApiFactory,
  Commodity
} from "$Generated/api";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

interface ICommodityState {
  commodityFetchResults: IAjaxState<Commodity[]>;
  activeCommodities: Commodity[];
}

const InjectedPropName = "commodityService";

const initialState = {
  commodityFetchResults: managedAjaxUtil.createInitialState(),
  activeCommodities: []
} as ICommodityState;

class CommodityFreezerService extends FreezerService<ICommodityState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public fetchActiveCommodities(forceUpdate: boolean = false) {
    var fetchResults = this.freezer.get().commodityFetchResults;

    if (fetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "commodityFetchResults",
      onExecute: (apiOptions, param, options) => {
        const factory = CommodityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CommodityItemsGet();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch commodities.");
      },
      onOk: (data: Commodity[]) => {
        this.freezer.get().set({ activeCommodities: _.orderBy(data, x => x.commodityName, 'asc') });
      }
    });
  }
}

export const CommodityService = new CommodityFreezerService();
export type ICommodityServiceInjectedProps = ReturnType<CommodityFreezerService["getPropsForInjection"]>;
