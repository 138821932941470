import {
  React,
  useAuth
} from "$Imports/Imports";

import {
  Button,
  Snackbar
} from "$Imports/MaterialUIComponents";

export const TimeoutWarning: React.FunctionComponent = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const auth = useAuth();

  const handleRenewClick = () =>{
    auth.signinSilent();
    setIsOpen(false)
  }

  React.useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      // Due to automatic silent renewal, this component can be removed at a future date.
      setIsOpen(false);
    })
  }, [auth.events, auth.signinSilent]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={(isOpen)}
      message={(<span>You're about to be signed out due to inactivity. Press continue to stay signed in.</span>)}
      action={[
        (
          <Button
            onClick={handleRenewClick}
            color="inherit"
            key="continue"
          >
            Continue
          </Button>
        )
      ]}
    />
  );
}