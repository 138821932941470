// extracted by mini-css-extract-plugin
var _1 = "AcceptedQuotes__cardStyle__LoQ9J";
var _2 = "AcceptedQuotes__customerQuoteActions__qpAzO";
var _3 = "AcceptedQuotes__customerQuoteRow__ejQ72";
var _4 = "AcceptedQuotes__hideAction__d9Zib";
var _5 = "AcceptedQuotes__resultCell__uxqql";
var _6 = "AcceptedQuotes__resultsGrid__rsEhv";
var _7 = "AcceptedQuotes__resultsMessage__v71Yv";
var _8 = "AcceptedQuotes__searchFormContainer__N9QuD";
export { _1 as "cardStyle", _2 as "customerQuoteActions", _3 as "customerQuoteRow", _4 as "hideAction", _5 as "resultCell", _6 as "resultsGrid", _7 as "resultsMessage", _8 as "searchFormContainer" }
