import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  Card,
  Grid
} from "$Imports/MaterialUIComponents";

import {
  Customer,
  CustomerContact, 
  CustomerQuote
} from "$Generated/api";

import {
  directionType,
  CustomerContactModal,
  ReminderListView,
  EditCustomerModal
} from "$Imports/CommonComponents";

import {
  CustomerDetailsCard
} from "./CustomerDetails/CustomerDetailsCard";

import {
  KaiserDetailsCard
} from "./KaiserDetails/KaiserDetailsCard";

import {
  CustomerQuotes
} from "./CustomerQuotes/CustomerQuotes"

import {
  AcceptedQuotes
} from "./AcceptedQuotes/AcceptedQuotes"

import {
  ContactsCard
} from "./Contacts/ContactsCard";

import {
  CustomerService,
  ICustomerServiceInjectedProps
} from "$State/CustomerFreezerService";

import {
  ICustomerDetailServiceInjectedProps,
  CustomerDetailService
} from "$State/CustomerDetailFreezerService";

import {
  ICustomerQuoteServiceInjectedProps,
  CustomerQuoteService,
} from "$State/CustomerQuotesFreezerService";

import {
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps
} from "$State/QuoteEntryFreezerService"

import {
  StateService,
  IStateServiceInjectedProps
} from "$State/RegionFreezerService";

import {
  NavigationService
} from "$State/NavigationFreezerService";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

const styles: {
  container: string;
  textStyle: string;
  labelStyle: string;
  mainContainer: string;
  column: string;
  card: string;
} = require("./CustomerPortalHomeView.scss");

interface ICustomerPortalHomeViewBaseProp { }

type ICustomerPortalHomeViewProp = ICustomerPortalHomeViewBaseProp 
& ICustomerServiceInjectedProps
& ICustomerDetailServiceInjectedProps
& ICustomerQuoteServiceInjectedProps 
& IQuoteEntryServiceInjectedProps
& IStateServiceInjectedProps;

class _CustomerPortalHomeView extends React.Component<ICustomerPortalHomeViewProp> {

  async componentDidMount() {
    await this.props.customerService.getCustomerByTruckMateId(true);
    this.props.customerService.getCustomerUsersByTruckMateId(true);
    this.props.customerDetailService.fetchContactTypes();
    this.props.regionService.fetchStates();

    const customerId = this.props.customerService.freezer.get().currentCustomer?.id;

    if (customerId) {
      this.props.customerDetailService.fetchCustomerDetailData(customerId);
      this.props.customerDetailService.fetchCustomerContacts(customerId, true);
    }
  }

  componentWillUnmount() {
    this.props.customerDetailService.clearFreezer();
  }

  @bind
  private _openDetailModal() {
    this.props.customerDetailService.openDetailModal();
  }

  @bind
  private _onDetailChange(customer: Partial<Customer>) {
    this.props.customerDetailService.customerOnChange(customer);
  }

  @bind
  private _saveCustomerDetails() {
    this.props.customerDetailService.saveCustomer();
  }

  @bind
  private _closeDetailModal() {
    this.props.customerDetailService.closeDetailModal();
  }

  @bind
  private _toggleShowInactiveContacts(checked: boolean): void {
    this.props.customerDetailService.updateShowInactiveToggle(checked);
    const {currentCustomer} = this.props.customerService.freezer.get();

    if (currentCustomer && currentCustomer.id){
      this.props.customerDetailService.fetchCustomerContacts(currentCustomer.id, true, checked);
    }
  }

  @bind
  private _addEditContact(contact?: CustomerContact) {
    this.props.customerDetailService.openCustomerContactModal(contact);
  }

  @bind
  private _onContactChange(contact: Partial<CustomerContact>) {
    this.props.customerDetailService.contactOnChange(contact);
  }

  @bind
  private async _onContactSave() {
    await this.props.customerDetailService.saveCustomerContact();
  }

  @bind
  private _onContactSortChange(columnName: string | undefined, direction: directionType) {
    this.props.customerDetailService.setContactSortState({
      sortColumnName: columnName,
      sortDirection: direction,
    });
  }

  @bind
  private _closeContactModal() {
    this.props.customerDetailService.closeCustomerContactModal();
  }

  @bind
  private async _viewQuote(quoteNumber: string | undefined){
    await this.props.customerQuotesService.fetchQuote(quoteNumber);
    this.props.QuoteEntryService.setCustomerQuote(this.props.customerQuotesService.getState().quoteFetchResults.data as CustomerQuote);
    NavigationService.navigateTo("/newQuote");
  }

  render() {
    const {
      currentCustomer,
      customerUsersFetchResults
    } = this.props.customerService.getState();
    const {
      contactsFetchResults,
      saveContactResults,
      contactModalState,
      contactTypeFetchResults,
      contactSortState,
      showInactiveContacts,
      customerDetailFetchResults,
      saveCustomerResults,
      detailModalState
    } = this.props.customerDetailService.getState();

    const {
      regionFetchResults
    } = this.props.regionService.getState();
    const regions = regionFetchResults.data ?? [];

    const userId = SharedSecurityContext.getUserId();
    const isManagerUser = SharedSecurityContext.hasRole(["customer-portal:manager"]);

    const formattedDate = (new Date().toLocaleString("en-US", { year: 'numeric', month: 'long', day: 'numeric' }));
    const contactData = contactsFetchResults.data
      ? isManagerUser
      ? contactsFetchResults.data
      : _.filter(contactsFetchResults.data, c => c.customerUser?.userId === userId)
      : [];

    let contactTypeData = contactTypeFetchResults.data ?? [];
    contactTypeData = _.orderBy(contactTypeData, c => c.type);
    const customerUsersData = customerUsersFetchResults.data ?? [];

    return (
      <Grid container className={styles.mainContainer} spacing={1.25}>
        <Grid item xs={12}>
          <Card className={styles.card}>
            <div className={styles.textStyle} >{`Welcome, ${currentCustomer?.customerName ?? ""}`}</div>
            <div className={styles.labelStyle} >
              Today is: <span>{formattedDate}</span>
            </div>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <div className={styles.column} >
            <CustomerDetailsCard
              customerId={currentCustomer?.id}
              customerData={customerDetailFetchResults.data}
              isFetching={customerDetailFetchResults.isFetching || saveCustomerResults.isFetching}
              openDetailModal={this._openDetailModal}
            />
            <ContactsCard
                contacts={contactData}
                addEditContact={this._addEditContact}
                onShowInactive={this._toggleShowInactiveContacts}
                sortState={contactSortState}
                onSortChange={this._onContactSortChange}
                isFetching={contactsFetchResults.isFetching || saveContactResults.isFetching}
                isManagerUser={isManagerUser}
                showInactiveContacts={showInactiveContacts}
            />
            <KaiserDetailsCard
              customerId={currentCustomer?.id}
              customerData={customerDetailFetchResults.data}
              isFetching={customerDetailFetchResults.isFetching}
            />
          </div>
        </Grid>
        <Grid item xs={8} >
          <div className={styles.column} >
            <div style={{height: "25rem"}}>
              <CustomerQuotes onViewQuote={this._viewQuote} />
            </div>
            <div style={{height: "25rem"}}>
              <AcceptedQuotes onViewQuote={this._viewQuote} />
            </div>
            <div style={{height: "25rem"}}>
              <ReminderListView
                customer={currentCustomer} 
                customerUsers={customerUsersData}
                onViewQuote={this._viewQuote} />
            </div>
          </div>
        </Grid>

        <EditCustomerModal
          isOpen={detailModalState.isOpen}
          customer={detailModalState.editCustomer ?? {}}
          isFetching={saveCustomerResults.isFetching}
          regions={regions}
          onSave={this._saveCustomerDetails}
          onCancel={this._closeDetailModal}
          onChange={this._onDetailChange}
        />

        <CustomerContactModal
          isOpen={contactModalState.isOpen}
          contact={contactModalState.editContact}
          validationErrors={contactModalState.validationErrors}
          contactTypes={contactTypeData}
          customerUsers={customerUsersData}
          contacts={contactData}
          onSave={this._onContactSave}
          onCancel={this._closeContactModal}
          onChange={this._onContactChange}
        />
      </Grid>
    );
  }
}

export const CustomerPortalHomeView = CustomerService.inject(
  CustomerDetailService.inject(
    QuoteEntryService.inject(
      CustomerQuoteService.inject(
        StateService.inject(
          _CustomerPortalHomeView
        )
      )
    )
  )
);