import {
  FreezerService,
  IAjaxState,
  managedAjaxUtil,
  bind
} from "$Imports/Imports";

import {
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  PCMilerApiFactory,
  Place
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import yup from "$Shared/utilities/yupExtension";

const InjectedPropName = "CityStateService";

interface ICityStateServiceState {
  searchCriteria: string;
  searchValidationErrors: ValidationError | null;
  searchResults: IAjaxState<Place[]>;
  selectedRow: Place | null;
}

export interface CityStateSearch {
  cityStateSearchCriteria?: string;
}

const initialState: ICityStateServiceState = {
  searchCriteria: "",
  searchValidationErrors: null,
  searchResults: managedAjaxUtil.createInitialState(),
  selectedRow: null
}

const CityStateSearchValidationSchema: SchemaOf<CityStateSearch> = yup.object({
  cityStateSearchCriteria: yup.string().required("City, State is required.").min(3, "At least 3 characters are required.").cityState("Invalid City, State search")
});

class CityStateFreezerService extends FreezerService<ICityStateServiceState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public setSelectedRow(selectedRow: Place | null) {
    this.freezer.get().set({ selectedRow });
  }

  public updateSearchCriteria(searchCriteria: string) {
    this.freezer.get().set({ searchCriteria });
  }

  public async onSearchClick() {
    const searchModel = this.freezer.get().searchCriteria;
    const errors = await validateSchema(CityStateSearchValidationSchema, {cityStateSearchCriteria: searchModel}, {
      abortEarly: false
    });

    this.freezer.get().set({ searchValidationErrors: errors });

    if (errors) {
      return;
    }

    this.setSelectedRow(null);

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "searchResults",
      params: {
        searchCriteria: searchModel
      },
      onExecute: (apiOptions, params, options) => {
        const factory = PCMilerApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1PCMilerGetZipCodesByCityStatePost(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch location search results.");
      }
    });
  }
}

export const CityStateService = new CityStateFreezerService();
export type ICityStateFreezerServiceInjectedProps = ReturnType<CityStateFreezerService["getPropsForInjection"]>;