import {
  React,
  bind
} from "$Imports/Imports";

import {
  DialogActions,
  DialogTitle,
  DialogContent,
  Dialog,
  Button
} from "$Imports/MaterialUIComponents";

interface IHelpModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export class HelpModal extends React.PureComponent<IHelpModalProps> {

  @bind
  private _onClose() {
    this.props.onClose();
  }

  render() {
    const {
      isOpen
    } = this.props;

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <b>Customer Portal Help</b>
        </DialogTitle>
        <DialogContent>
          <div>
            If you need assistance, please email <a href={`mailto:cpsupport@kaisertransport.com?subject=Customer%20Portal%20Help`} target="_blank" style={{ textDecoration: "unset" }} >cpsupport@kaisertransport.com</a>.
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this._onClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}