import {
  React
} from "$Imports/Imports";

interface ICardLinedHeaderProps {
  title: JSX.Element | string;
  titleComponents?: React.ReactNode;
  color: "gray" | "black" | "white";
  style?: React.CSSProperties | undefined;
}

const styles: {
  header: string,
  mainContainer: string,
  gray: string,
  black: string,
  white: string
} = require('./CardLinedHeader.scss');

export class CardLinedHeader extends React.Component<ICardLinedHeaderProps> {
  public static defaultProps = {
    color: "gray"
  };

  render() {
    const {
      title,
      titleComponents,
      color,
      style
    } = this.props;

    return (
      <div className={styles.mainContainer} style={style}>
        <div className={`${styles.header} ${styles[color]}`}>
          <span>{title}</span>
          {titleComponents}
        </div>
        {this.props.children}
      </div>
    );
  }
}