import {
  _,
  moment
} from "$Imports/Imports";

import {
  CustomerQuote
} from "$Generated/api";

/// Used to filter stops with validate date ranges.
function validateQuoteFilter(quote: CustomerQuote): boolean {
  const {
    consigneeEndDate,
    shipperEndDate,
    consigneeStartDate,      
    shipperStartDate
  } = quote;

  if (consigneeStartDate === null || consigneeStartDate === undefined || shipperStartDate === null || shipperStartDate == undefined) {
    return false;
  }

  const consigneeStartDateMoment  = moment(consigneeStartDate).startOf('day');
  const shipperStartDateMoment  = moment(shipperStartDate).startOf('day');

  if(consigneeStartDateMoment.isBefore(shipperStartDateMoment)) {
    return false;
  }

  const consigneeEndDateMoment  = moment(consigneeEndDate ?? consigneeStartDate).startOf('day');
  const shipperEndDateMoment  =  moment(shipperEndDate ?? shipperStartDate).startOf('day');

  if(!consigneeStartDateMoment.isSameOrBefore(consigneeEndDateMoment)) {
    return false;
  }

  if(!shipperStartDateMoment.isSameOrBefore(shipperEndDateMoment)) {
    return false;
  }
  
  return true;
}

export { 
  validateQuoteFilter
};