import {
  bind,
  React,
  moment
} from "$Imports/Imports";

import {
  BinaryChoiceDialog,
  CardLinedHeader,
  CreateReminderButton,
  DisplayFormattedDatetime,
  DueDateField,
  ReminderEditModal,
  ToggleBox,
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  Card,
  Checkbox,
  DataGridPro,
  FormControlLabel,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
  IconButton,
  Link
} from "$Imports/MaterialUIComponents";

import {
  Check,
  Delete,
  Edit
} from "$Imports/MaterialUIIcons";

import {
  Quote,
  CustomerReminder,
  CustomerReminderSearchCriteria,
  Customer,
  CustomerUser
} from "$Generated/api";

import {
  EMPTY_REMINDER,
  ICustomerReminderServiceInjectedProps,
  CustomerReminderService
} from "$State/CustomerReminderFreezerService";

import {
  ReminderSearchForm
} from "./ReminderSearchForm";

interface IOwnProps {
  customer?: Customer;
  customerUsers: CustomerUser[];
  onViewQuote: (quoteNumber: string | undefined) => void;
  style?: React.CSSProperties;
}

type OwnProps = IOwnProps
  & ICustomerReminderServiceInjectedProps;

interface IOwnState {
  isDeleteModalOpen: boolean;
  isEditModalOpen: boolean;
  selectedReminder: CustomerReminder;
  selectedReminderListType: reminderListType;
}

const styles: {
  card: string;
  headerActions: string;
  isComplete: string;
  reminderSearch: string;
  reminderGrid: string;
  reminderRow: string;
  reminderActions: string;
  hideAction: string;
  showAction: string;
} = require("./ReminderListView.scss");

const reminderListTypes = ["Just Me", "Everyone"];
type reminderListType = typeof reminderListTypes[number];

const getRowClassNames = (row: CustomerReminder): string => {
  return `${styles.reminderRow} ${row.isComplete ? styles.isComplete : ""}`;
};

class _ReminderListView extends React.Component<OwnProps, IOwnState> {
  state: IOwnState = {
    isDeleteModalOpen: false,
    isEditModalOpen: false,
    selectedReminder: {
      customer: {
        linkId: this.props.customer?.id,
        linkName: this.props.customer?.customerName
      }
    },
    selectedReminderListType: "Just Me"
  };

  private readonly _columns: GridColDef[] = [{
    headerName: "Created On",
    field: "createdOn",
    renderCell: (params: GridRenderCellParams<Date>) =>
    <DisplayFormattedDatetime value={params.value} formatString={'MM/DD/YYYY'} />,
    width: 120,
    disableColumnMenu: true,
    sortable: true,
  }, {
    field: "createdByName",
    headerName: "Assigned To",
    disableColumnMenu: true,
    width: 150
  }, {
    headerName: "Due Date",
    field: "dueDate",
    renderCell: (params: GridRenderCellParams<Date>) =>
      <DueDateField
        soonCutoff={moment.utc(params.value).startOf('day').toDate()}
        value={params.value}
      />,
    width: 120,
    disableColumnMenu: true,
    sortable: true,
  }, {
    headerName: "Quote #",
    field: "customerQuote",
    renderCell: (params: GridRenderCellParams<Quote>) =>
      params.value ? (
        <Link
          style={{cursor: "pointer"}}
          color="secondary"
          onClick={() => this.props.onViewQuote(params.row.customerQuote.linkName)}
        >
          {`EQ${params.row.customerQuote.linkName}`}
        </Link>
      ) : "",
    width: 150,
    disableColumnMenu: true,
    sortable: true
  }, {
    headerName: "Description",
    field: "text",
    flex: 1,
    disableColumnMenu: true,
    sortable: true
  }, {
    headerName: "",
    field: "actions",
    renderCell: (params: GridRenderCellParams<number, CustomerReminder>) => (
      <div className={styles.reminderActions}>
        <IconButton
          className={params.row.isComplete ? styles.showAction : styles.hideAction}
          disabled={params.row.isComplete}
          onClick={() => this._completeReminder(params.row.id!)}
          size="small"
          title="Complete reminder"
        >
          <Check />
        </IconButton>
        {!params.row.isComplete && (
          <IconButton
            className={styles.hideAction}
            disabled={params.row.isComplete}
            onClick={() => this._toggleEditModal(params.row)}
            size="small"
            title="Edit reminder"
          >
            <Edit />
          </IconButton>
        )}
        <IconButton
          className={styles.hideAction}
          onClick={() => this._toggleDeleteModal(params.row)}
          size="small"
          title="Delete reminder"
        >
          <Delete />
        </IconButton>
      </div>
    ),
    width: 120,
    disableColumnMenu: true,
    sortable: false
  }];

  componentDidMount() {
    this.props.customerReminderService.update({
      searchCriteria: {
        reminderListType: this.state.selectedReminderListType
      },
      showCompleted: false
    });
    this.props.customerReminderService.fetchCustomerReminders(true);
  }

  @bind
  private async _onSearchSubmit(search: CustomerReminderSearchCriteria): Promise<void> {
    this.props.customerReminderService.update({
      searchCriteria: {
        ...search,
        reminderListType: this.state.selectedReminderListType,
        customerId: this.props.customer?.id
      }
    });

    await this.props.customerReminderService.fetchCustomerReminders(true);
  }

  @bind
  private _toggleShowCompleted(_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    this.props.customerReminderService.update({ showCompleted: checked });
  }

  @bind
  private _updateSort(model: GridSortModel): void {
    const newSort = model[0];
    if (newSort) {
      this.props.customerReminderService.update({ sort: { sortColumnName: newSort.field, sortDirection: newSort.sort } });
    }
  }

  @bind
  private _toggleDeleteModal(model?: CustomerReminder): void {
    this.setState((prev) => ({
      selectedReminder: model ? model : {},
      isDeleteModalOpen: !prev.isDeleteModalOpen
    }));
  }

  @bind
  private _toggleEditModal(model?: CustomerReminder): void {
    if (model && !model?.customer){
      model.customer = {
        linkId: this.props.customer?.id,
        linkName: this.props.customer?.customerName
      }
    }

    this.setState((prev) => ({
      selectedReminder: model ? model : {},
      isEditModalOpen: !prev.isEditModalOpen
    }));
  }

  @bind
  private async _editReminder(model?: CustomerReminder): Promise<void> {
    if (model) {
      await this.props.customerReminderService.fetchCustomerReminders(true);
    }

    this._toggleEditModal();
  }

  @bind
  private async _deleteReminder(id: number): Promise<void> {
    await this.props.customerReminderService.deleteCustomerReminder(id);
    await this.props.customerReminderService.fetchCustomerReminders(true);
  }

  @bind
  private async _completeReminder(id: number): Promise<void> {
    await this.props.customerReminderService.setCustomerReminderToComplete(id);
    await this.props.customerReminderService.fetchCustomerReminders(true);
  }

  @bind
  private _selectReminderListType(reminderListType: string) {
    if (reminderListType === this.state.selectedReminderListType) {
      return;
    }

    this.setState({
      selectedReminderListType: reminderListType as reminderListType
    });

    CustomerReminderService.freezer.get().searchCriteria.set({
      reminderListType: reminderListType,
      customerId: this.props.customer?.id
    });

    this.props.customerReminderService.fetchCustomerReminders(true);
  }

  render() {
    const {
      fetchResult,
      sort,
      showCompleted
    } = this.props.customerReminderService.getState();

    const {
      isDeleteModalOpen,
      isEditModalOpen,
      selectedReminder,
      selectedReminderListType
    } = this.state;

    const {
      customerUsers
    } = this.props;

    const style: React.CSSProperties = {
      display: "flex",
      flexDirection: "column",
      padding: "0.625rem 0.625rem 0rem 0.625rem",
      ...this.props.style,
    }

    // applying a dynamic filter via the grid's filterModel does not seem to work
    // entire dataset must be reloaded when updating, deleting, etc
    const reminders = (fetchResult.data ?? [])
      .filter(x => showCompleted || !x.isComplete);

    const sortModel: GridSortModel = [{ field: sort?.sortColumnName ?? "dueDate", sort: sort?.sortDirection ?? "asc" }];

    return (
      <Card className={styles.card} >
        <CardLinedHeader
          title="My To Do List"
          titleComponents={(
            <div className={styles.headerActions}>
              <UserAccessControl roles={["customer-portal:manager"]}>
                <ToggleBox
                  selectedValue={selectedReminderListType}
                  options={reminderListTypes}
                  toggleClick={this._selectReminderListType}
                />
              </UserAccessControl>
              <UserAccessControl roles={["reminder:create"]}>
                <CreateReminderButton
                  seed={{
                    ...EMPTY_REMINDER
                  }}
                  onClick={this._toggleEditModal}
                />
              </UserAccessControl>
            </div>
          )}
          style={style}
          color="white"
        />

        <div className={styles.reminderGrid} >
          <ReminderSearchForm
            onSubmit={this._onSearchSubmit}
          />

          <FormControlLabel
            label="Show completed"
            control={(
              <Checkbox
                checked={showCompleted}
                onChange={this._toggleShowCompleted}
              />
            )}
          />

          <DataGridPro
            rows={reminders}
            columns={this._columns}
            density="compact"
            hideFooter
            sortModel={sortModel}
            onSortModelChange={this._updateSort}
            sortingOrder={["asc", "desc"]} // null removed to disallow "no" sorting
            getRowClassName={(params) => getRowClassNames(params.row)}
            columnVisibilityModel={{
              createdByName: selectedReminderListType === "Everyone"
            }}
          />

          <BinaryChoiceDialog
            isOpen={isDeleteModalOpen}
            message="Are you sure you want to delete this reminder?"
            onClick={(value) => {
              if (value) {
                this._deleteReminder(selectedReminder.id!);
              }
              this._toggleDeleteModal();
            }}
            trueText="Delete"
            trueColor="error"
            falseText="Cancel"
          />

          <ReminderEditModal
            isOpen={isEditModalOpen}
            reminder={selectedReminder}
            onClose={this._editReminder}
            customerUsers={customerUsers}
          />
        </div>
      </Card>
    );
  }
}

export const ReminderListView = CustomerReminderService.inject(_ReminderListView);
