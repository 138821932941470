import {
  React
} from "$Imports/Imports";

import {
  Card
} from "$Imports/MaterialUIComponents";

interface IOwnProps {
  title: string;
  majorValue: React.ReactNode;
  style?: React.CSSProperties | undefined;
}

const styles: {
  container: string;
  title: string;
  majorValue: string;
} = require("./QuoteDetailCard.scss");

class _QuoteDetailCard extends React.PureComponent<IOwnProps> {
  render() {
    const {
      title,
      majorValue,
      style
    } = this.props;

    return (
      <Card className={styles.container} style={style}>
        <div className={styles.title}>{title}</div>
        <div className={styles.majorValue}>{majorValue}</div>
      </Card>
    );
  }
}

export const QuoteDetailCard = _QuoteDetailCard;