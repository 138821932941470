import {
  React,
  _,
  bind,
  NumberFormat,
  NumberFormatValues,
  NumberFormatProps
} from "$Imports/Imports";

import {
  TextField,
  TextFieldProps
} from "$Imports/MaterialUIComponents";

interface IPercentageInputFieldBasedProps {
  onPercentValueChange: (newValue?: number) => void;
  value?: number;
}

export type IPercentageInputFieldProps = IPercentageInputFieldBasedProps & NumberFormatProps<TextFieldProps>;

export class PercentageInputField extends React.PureComponent<IPercentageInputFieldProps> {

  @bind
  private _onValueChange(value: NumberFormatValues) {
    this.props.onPercentValueChange(value.floatValue !== undefined && value.floatValue !== null ? value.floatValue / 100 : undefined);
  }

  render() {
    const {
      onPercentValueChange,
      value,
      ...passthroughProps
    } = this.props;

    return (
      <NumberFormat
        {...passthroughProps}
        suffix={'%'}
        value={value === undefined || value === null ? "" : value * 100}
        onValueChange={this._onValueChange}
        customInput={TextField}
      />
    );
  }
}
