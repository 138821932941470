import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  moment,
  IAjaxState,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf
} from "$Shared/imports/Yup";

import {
  ISortState
} from "$Imports/CommonComponents";

import {
  CustomerReminder,
  CustomerReminderApiFactory,
  CustomerReminderSearchCriteria
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

interface ICustomerReminderState {
  searchCriteria: CustomerReminderSearchCriteria;
  sort: ISortState;
  showCompleted: boolean;
  fetchResult: IAjaxState<CustomerReminder[]>;
  createResult: IAjaxState<CustomerReminder>;
  updateResult: IAjaxState<CustomerReminder>;
  deleteResult: IAjaxState<void>;
  setCompleteResult: IAjaxState<void>;
}

const injectedPropName = "customerReminderService";

export const CustomerReminderValidationSchema: SchemaOf<NullableOptional<CustomerReminder>> = yup.object({
  id: yup.number().required(),
  customer: yup.object().nullable().notRequired(),
  customerQuote: yup.object().nullable().notRequired(),
  dueDate: yup.date()
    .typeError("Invalid date")
    .required("Due Date is required")
    .min(moment().startOf('day').toDate(), "Due Date must be today or later"),
  text: yup.string()
    .required("Description is required")
    .max(300, "Description can not exceed 300 characters"),
  createdById: yup.number().notRequired(),
  createdOn: yup.date().notRequired(),
  isComplete: yup.boolean().required(),
  isActive: yup.boolean().required(),
  createdByName: yup.string().nullable().notRequired()
});

// prevent controlled/uncontrolled input warnings
export const EMPTY_REMINDER: CustomerReminder = {
  id: 0,
  text: ""
};

export const EMPTY_REMINDER_SEARCH: CustomerReminderSearchCriteria = {
  search: "",
  quoteNumber: ""
};

const initialState: ICustomerReminderState = {
  searchCriteria: { ...EMPTY_REMINDER_SEARCH },
  sort: {
    sortColumnName: "dueDate",
    sortDirection: "asc"
  },
  showCompleted: false,
  fetchResult: managedAjaxUtil.createInitialState(),
  createResult: managedAjaxUtil.createInitialState(),
  updateResult: managedAjaxUtil.createInitialState(),
  deleteResult: managedAjaxUtil.createInitialState(),
  setCompleteResult: managedAjaxUtil.createInitialState()
};

class _CustomerReminderFreezerService extends FreezerService<ICustomerReminderState, typeof injectedPropName> {
  constructor() {
    super(initialState, injectedPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  @bind
  public update(newState: Partial<Omit<ICustomerReminderState, "fetchResult" | "createResult" | "updateResult" | "deleteResult" | "setCompleteResult">>) {
    this.freezer.get().set(newState);
  }

  @bind
  public fetchCustomerReminders(forceUpdate: boolean = false) {
    const {
      searchCriteria,
      fetchResult
    } = this.freezer.get();

    if (fetchResult.hasFetched && !forceUpdate) {
      return;
    }

    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "fetchResult",
      params: {
        body: searchCriteria.toJS()
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerReminderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerReminderSearchPost(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? "Failed to fetch reminders.");
      }
    })
  }

  @bind
  public createCustomerReminder(model: CustomerReminder) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "createResult",
      params: {
        body: model
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerReminderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerReminderPost(params);
      },
      onOk: () => {
        ErrorService.pushErrorMessage("Reminder set.", "successful");
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? "Failed to create reminder.");
      }
    });
  }

  @bind
  public updateCustomerReminder(id: number, model: CustomerReminder) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "updateResult",
      params: {
        id: id,
        body: model
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerReminderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerReminderIdPut(params);
      },
      onOk: () => {
        ErrorService.pushErrorMessage("Reminder updated.", "successful");
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? "Failed to update reminder.");
      }
    });
  }

  @bind
  public deleteCustomerReminder(id: number) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "deleteResult",
      params: {
        id: id
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerReminderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerReminderIdDelete(params);
      },
      onOk: () => {
        ErrorService.pushErrorMessage("Reminder deleted.", "successful");
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? "Failed to delete reminder.");
      }
    });
  }

  @bind
  public setCustomerReminderToComplete(id: number) {
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "setCompleteResult",
      params: {
        id: id
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerReminderApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerReminderIdCompletePost(params);
      },
      onOk: () => {
        ErrorService.pushErrorMessage("Reminder marked complete.", "successful");
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? "Failed to mark reminder as complete.");
      }
    });
  }
}

export const CustomerReminderService = new _CustomerReminderFreezerService();
export type ICustomerReminderServiceInjectedProps = ReturnType<_CustomerReminderFreezerService["getPropsForInjection"]>;
