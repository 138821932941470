
import {
  React,
  withAuth,
  AuthContextProps,
  _
} from "$Imports/Imports";

import {
  Button
} from "$Imports/MaterialUIComponents"

import {
  NavigationService,
  INavigationServiceInjectedProps
} from "$State/NavigationFreezerService"

import { 
  SharedSecurityContext 
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

const styles: {
  mainContainer: string,
  appTitle: string,
  card: string,
  message: string
} = require("./NoCompanyAccessView.scss");

interface INoCustomerAccessViewBaseProps {

}

type INoCustomerAccessViewProps = INoCustomerAccessViewBaseProps & INavigationServiceInjectedProps & AuthContextProps;

class _NoCustomerAccessView extends React.Component<INoCustomerAccessViewProps> {

  private async _onLogout() {  
    await SharedSecurityContext.logout();
  }

  render() {
    return (
      <div className={styles.mainContainer}>
        <div className={styles.appTitle}>SALESPORTAL CUSTOMER PORTAL</div>

        <div className={styles.message}>
          This user does not currently have a company associated with their account. 
          Please associate a company to this account via KeyCloak and login again.
        </div>

        <Button 
          onClick={this._onLogout}
          variant="contained"
          color="secondary"
        >
            Logout
        </Button>

      </div>
    );
  }
}

export const NoCustomerAccessView = withAuth(
  NavigationService.inject(
    _NoCustomerAccessView,
  )
);
