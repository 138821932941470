const kaiserLogo = require("../images/kaiser-red-small.png") as string;
const kaiserLogLogo = require("../images/kaiser-log-small.png") as string;
const kaiserTransLogo = require("../images/kaiser-trans-small.png") as string;

export enum CompanyEnum {
  Transport = "1",
  Logistics = "2"
}

export function getCompanyLogo (companyId: number | undefined) {
  if (companyId == undefined){
    return kaiserLogo;
  }
  return companyId.toString() == CompanyEnum.Logistics ? kaiserLogLogo : kaiserTransLogo;
}