import {
  bind,
  React,
  moment
} from "$Imports/Imports";

import {
  DateRangePicker,
  SearchControlsContainer,
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  CustomerReminderSearchCriteria
} from "$Generated/api";

import {
  EMPTY_REMINDER_SEARCH,
  ICustomerReminderServiceInjectedProps,
  CustomerReminderService
} from "$State/CustomerReminderFreezerService";

interface IOwnProps {
  onSubmit: (search: CustomerReminderSearchCriteria) => void;
}

type OwnProps = IOwnProps
  & ICustomerReminderServiceInjectedProps;

interface IOwnState {
  searchCriteria: CustomerReminderSearchCriteria;
  searchDisabled: boolean;
}

const styles: {
  container: string;
} = require("./ReminderSearchForm.scss");

class _ReminderSearchForm extends React.PureComponent<OwnProps, IOwnState> {
  state: IOwnState = {
    searchCriteria: { ...EMPTY_REMINDER_SEARCH },
    searchDisabled: true,
  };

  @bind
  private _onTextChange(text: string): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        search: text ?? undefined
      },
      searchDisabled: false
    }));
  }

  @bind
  private _onQuoteNumberChange(text: string): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        quoteNumber: text ?? undefined
      },
      searchDisabled: false
    }));
  }

  @bind
  private _onDateRangeChange(start: Date | null, end: Date | null): void {
    this.setState((prev) => ({
      searchCriteria: {
        ...prev.searchCriteria,
        startDate: start ? moment(start).startOf('day').toDate() : undefined,
        endDate: end ? moment(end).endOf('day').toDate() : undefined
      },
      searchDisabled: false
    }));
  }

  @bind
  private _onSubmit(): void {
    const { searchCriteria } = this.state;

    this.props.onSubmit(searchCriteria);
    this.setState({ searchDisabled: true });
  }

  @bind
  private _onClear(): void {
    this.setState({
      searchCriteria: { ...EMPTY_REMINDER_SEARCH }
    });

    this.props.onSubmit(EMPTY_REMINDER_SEARCH);

    this.setState({ searchDisabled: true });
  }

  componentDidMount() {
    const { searchCriteria } = this.props.customerReminderService.getState();

    this.setState({
      searchCriteria: { ...searchCriteria },
      searchDisabled: true
    });
  }

  render() {
    const {
      searchCriteria,
      searchDisabled
    } = this.state;

    return (
      <SearchControlsContainer
        className={styles.container}
        onSubmit={this._onSubmit}
        onClear={this._onClear}
        searchDisabled={searchDisabled}
      >
        <AdvanceTextField
          style={{ flex: "1" }}
          label="Description"
          value={searchCriteria.search}
          onChange={(event) => this._onTextChange(event.target.value)}
        />
        
        <AdvanceTextField
          style={{ flex: "0 0 7rem" }}
          label="Quote #"
          value={searchCriteria.quoteNumber}
          onChange={(event) => this._onQuoteNumberChange(event.target.value)}
        />

        <div style={{ display: "inline-flex", flex: "0 0 17rem", gap: "0.5rem" }}>
          <DateRangePicker
            startDate={searchCriteria.startDate}
            endDate={searchCriteria.endDate}
            onChange={this._onDateRangeChange}
          />
        </div>
      </SearchControlsContainer>
    );
  }
}

export const ReminderSearchForm = CustomerReminderService.inject(_ReminderSearchForm);
