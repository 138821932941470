import {
  React,
  bind
} from "$Imports/Imports";

import {
  CustomerContact
} from "$Generated/api";

import {
  IconButton,
  Tooltip
} from "$Imports/MaterialUIComponents";

import {
  Edit,
  Person
} from "$Imports/MaterialUIIcons";

import {
  UserAccessControl
} from "$Imports/CommonComponents";

import {  
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

const styles: {
  hideShow: string;
  activeUser: string;
} = require("./CustomerContacts.scss");

interface IActionMenuProps {
  contact: CustomerContact;
  editContact: (contact: CustomerContact) => void;
}

export class ActionMenu extends React.Component<IActionMenuProps> {
  @bind
  private _onEditClick() {
    const { contact } = this.props;
    this.props.editContact(contact);
  }

  render() {
    const { contact } = this.props;

    const isAssignedUser = SharedSecurityContext.getUserId() === contact?.customerUser?.userId;
    const customerUserName = isAssignedUser ? "Assigned to me" : contact.customerUser ? `${contact?.customerUser?.firstName} ${contact?.customerUser?.lastName}` : "";

    return (
      <>
        {contact.customerUserId ?
          <IconButton size="small" style={{cursor: "default"}}>
            <Tooltip
              title={customerUserName}
              placement="left"
            >
              <Person fontSize="small" className={isAssignedUser ? styles.activeUser : ""} style={{ verticalAlign: "middle" }} />
            </Tooltip>
          </IconButton>
          : ""
        }
        <UserAccessControl roles={["customer-portal:manager"]}>
          <div className={styles.hideShow}>
            <IconButton onClick={this._onEditClick} size="small">
              <Edit fontSize="small" style={{ verticalAlign: "middle" }} />
            </IconButton>
          </div>
        </UserAccessControl>
      </>
    )
  }
}