import {
  React
} from "$Imports/Imports";

import {
  Button
} from "$Imports/MaterialUIComponents";

import {
  Clear,
  Search
} from "$Imports/MaterialUIIcons";

interface IOwnProps {
  onSubmit: () => void;
  onClear?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  searchDisabled?: boolean;
}

type OwnProps = IOwnProps
  & React.HTMLAttributes<HTMLDivElement>

class _SearchControlsContainer<T> extends React.PureComponent<OwnProps> {
  render() {
    const {
      children,
      onSubmit,
      onClear,
      disabled,
      searchDisabled,
      ...rest
    } = this.props;

    return (
      <div {...rest}>
        {children}

        <div style={{ display: "inline-flex", gap: "0.25rem" }}>
          <Button
            className="iconAsButton"
            onClick={onSubmit}
            title="Search"
            disabled={disabled || searchDisabled}
            type="submit"
          >
            <Search />
          </Button>
          { onClear && (
            <Button
              className="iconAsButton"
              onClick={onClear}
              title="Clear"
              disabled={disabled}
            >
              <Clear />
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export const SearchControlsContainer = _SearchControlsContainer;
