import {
  React,
  bind
} from "$Imports/Imports";

import {
  DataTable,
  IDataTableColumn,
  AdvanceTextField,
  AjaxActionIndicator
} from "$Imports/CommonComponents";

import {
  Place
} from "$Generated/api";

import {
  CityStateSearch,
  CityStateService,
  ICityStateFreezerServiceInjectedProps
} from "$State/CityStateFreezerService";

import { 
  ValidationErrorParser 
} from "$Utilities/ValidationErrorParser";

import {
  IconButton,
  Button
} from "$Imports/MaterialUIComponents";

import {
  Clear,
  Search
} from "$Imports/MaterialUIIcons";

import {
  getFormattedZipPostalCode,
  getTrimmedZipPostalCode
} from "$Shared/utilities/helpers";

const styles: {
  clearButton: string,
  clearIcon: string,
  searchButton: string,
  noResults: string,
  resultsTable: string,
  headerRow: string
} = require("./CityStateSearchResults.scss"); 

interface ICityStateSearchResultsBaseProps {
  onEnterPressWithSelectedRow: () => void;
}

type ICityStateSearchResultsProps = ICityStateSearchResultsBaseProps & ICityStateFreezerServiceInjectedProps;

class _CityStateSearchResults extends React.Component<ICityStateSearchResultsProps> {

  componentWillUnmount() {
    this.props.CityStateService.clearFreezer();
  }

  private readonly columns: Array<IDataTableColumn<Place>> = [
    {
      columnName: "city",
      headerValue: "City",
      headerProps: {
        className: styles.headerRow
      },
      columnFieldData: (d) => d.city ?? "",
      sortMethod: (d) => d.city ?? ""
    },
    {
      columnName: "stateProvince",
      headerValue: "State",
      headerProps: {
        className: styles.headerRow
      },
      columnFieldData: (d) => d.stateProvince ?? "",
      sortMethod: (d) => d.stateProvince ?? ""
    },
    {
      columnName: "zipPostalCode",
      headerValue: "Postal Code",
      headerProps: {
        className: styles.headerRow
      },
      columnFieldData: (d) => getFormattedZipPostalCode(d.zipPostalCode) ?? "",
      sortMethod: (d) => getFormattedZipPostalCode(d.zipPostalCode) ?? ""
    },
    {
      columnName: "county",
      headerValue: "County",
      headerProps: {
        className: styles.headerRow
      },
      columnFieldData: (d) => d.county ?? "",
      sortMethod: (d) => d.county ?? ""
    }
  ];

  private resultsRef: HTMLDivElement | undefined;
  private containerDivRef = React.createRef<HTMLDivElement>();

  @bind
  private _onCityStateChanged(e: React.ChangeEvent<{ name: string; value: string; }>) {
    this.props.CityStateService.updateSearchCriteria(e.target.value);
  }

  @bind
  private _clearCityState() {
    this.props.CityStateService.updateSearchCriteria("");
  }

  @bind
  private async _onSearchClick() {
    await this.props.CityStateService.onSearchClick();
  }

  @bind
  private _searchFieldOnKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === "Enter") {
      this._onSearchClick();
    }
  }

  @bind
  private _onRowClick(row: Place) {
    const selectedRow = {
      ...row,
      zipPostalCode: getTrimmedZipPostalCode(row.zipPostalCode)
    };
    this.props.CityStateService.setSelectedRow(selectedRow);
  }

  @bind
  private _onRowSelect() {
    const selectedRow = this.props.CityStateService.freezer.get().selectedRow;

    if (selectedRow) {
      this.props.onEnterPressWithSelectedRow();
    }
  }

  @bind
  private _setAndUpdateRef(el: HTMLDivElement) {
    if (el) {
      this.resultsRef = el;
      this.resultsRef?.focus();
    }
  }

  @bind
  private _setSelectedRowScroll(el: HTMLTableRowElement | null, containerRef: React.RefObject<HTMLDivElement> | undefined) {
    if (el && containerRef && containerRef.current) {
      const rowRect = el.getBoundingClientRect();
      const containerRefRect = containerRef.current.getBoundingClientRect();
      const currentScrollY = containerRef?.current?.scrollTop;

      if (rowRect.top > containerRefRect.bottom) {
        containerRef?.current?.scrollTo({
          top: (currentScrollY ?? 0) + el.clientHeight * 2, 
          behavior: "smooth"
        });
      } else if (rowRect.top < (containerRefRect.top + rowRect.height)) {    
        containerRef?.current?.scrollTo({
          top: (currentScrollY ?? 0) - el.clientHeight * 2, 
          behavior: "smooth"
        });
      }
    }
  }

  render() {

    const {
      searchCriteria,
      searchValidationErrors,
      searchResults,
      selectedRow
    } = this.props.CityStateService.getState();

    const validationsParser = new ValidationErrorParser<CityStateSearch>(searchValidationErrors);
    const locationData = searchResults.data ?? [];

    return (
      <>
        <div style={{display: "flex"}}>
          <AdvanceTextField
            label="City, State (abbr.)" 
            onChange={this._onCityStateChanged}
            onKeyPress={this._searchFieldOnKeyPress}
            value={searchCriteria ?? ""}
            autoFocus
            error={!validationsParser.isValid("cityStateSearchCriteria")} 
            helperText={validationsParser.validationMessage("cityStateSearchCriteria") || "Search by City, ST"}
          />
          <div className={styles.clearButton}>
            <IconButton
              size="small"
              onClick={this._clearCityState}     
            >
              <Clear className={styles.clearIcon} />
            </IconButton>
          </div>
          <div className={styles.searchButton}>
            <Button
              color="primary"
              variant="contained"
              disabled={searchResults.isFetching}
              onClick={this._onSearchClick}
            >
              <Search /> Search
            </Button>
          </div>
        </div>
        <AjaxActionIndicator
          state={[searchResults]}
        />
        <div className={styles.resultsTable} ref={this.containerDivRef}>
          {(searchResults.hasFetched && locationData.length !== 0) &&
            <DataTable
              columns={this.columns}
              data={locationData}
              stickyHeader={true}
              useKeyboardNavigation
              tableContextProps={{
                selectedRow,
                containerDivRef: this.containerDivRef,
                setScroll: this._setSelectedRowScroll
              }}
              tableRef={this._setAndUpdateRef}
              onRowClick={this._onRowClick}
              onRowDoubleClick={this._onRowSelect}
              tableActionEnterKey={this._onRowSelect}
            />}
          {searchResults.hasFetched && locationData.length === 0 && <div className={styles.noResults}>No locations found</div>}
        </div>

      </>
    )
  }

}

export const CityStateSearchResults = CityStateService.inject (
  _CityStateSearchResults
);