import {
  React,
  bind,
  _,
  moment,
  NumberFormatValues,
  Prompt
} from "$Imports/Imports";

import {
  Card,
  Button,
  IconButton,
  Icon
} from "$Imports/MaterialUIComponents";

import {
  CustomerReminder,
  CustomerQuote,
  CustomerContact
} from "$Generated/api";

import {
  Error,
  Clear,
  Info
} from "$Imports/MaterialUIIcons";

import {
  CustomerInfo
} from "./CustomerInfo";

import {
  IQuoteEntryServiceInjectedProps,
  QuoteEntryService
} from "$State/QuoteEntryFreezerService";

import {
  UserAccessControl,
  AjaxActionIndicator,
  CardLinedHeader,
  CreateReminderButton,
  ReminderEditModal,
  AdvanceTextField,
  ConfirmCancelModal,
  CustomerContactModal
} from "$Imports/CommonComponents";

import {
  CustomerService,
  ICustomerServiceInjectedProps
} from "$State/CustomerFreezerService";

import {
  CustomerDetailService,
  ICustomerDetailServiceInjectedProps
} from "$State/CustomerDetailFreezerService";

import {
  CommodityService,
  ICommodityServiceInjectedProps
} from "$State/CommodityFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser"

import {
  AddEditQuoteCommodity
} from "./AddEditQuoteCommodity";

import {
  CommodityDataEntryView
} from "./CommodityDataEntryView";

import {
  QuoteDatesEntry
} from "./QuoteDatesEntry";

import {
  AccessRestrictionsEntry
} from "./AccessRestrictionsEntry";

import {
  EquipmentTypeEntry
} from "./EquipmentTypeEntry";

import {
  TarpEntry
} from "./TarpEntry";

import {
  DeclaredValueEntry
} from "./DeclaredValueEntry";

import {
  StateService,
  IStateServiceInjectedProps
} from "$State/RegionFreezerService";

import {
  QuoteZipCodeMileageService,
  IQuoteZipCodeMileageServiceInjectedProps
} from "$State/QuoteZipCodeMileageFreezerService";

import {
  TarpService,
  ITarpServiceInjectedProps
} from "$State/TarpFreezerService";

import {
  CommodityShippingDistanceInput
} from "$Imports/CommonComponents";

import { 
  IQuoteFreightServiceInjectedProps,
  QuoteFreightService
} from "$State/QuoteFreightFreezerService";

import {
  IEquipmentTypeServiceInjectedProps,
  EquipmentTypeService
} from "$State/EquipmentTypeFreezerService";

import {
  EMPTY_REMINDER
} from "$State/CustomerReminderFreezerService";

import { 
  ErrorService 
} from "$State/ErrorFreezerService";

import {
  NavigationService
} from "$State/NavigationFreezerService";

const styles: {
  mainContainer: string;
  header: string;
  input: string;
  actions: string;
  errorIcon: string;
  clearIcon: string;
  icon: string;
  infoIcon: string;
} = require("./QuoteView.scss");

interface IQuoteViewBaseProp { }

interface IQuoteViewState {
  isReminderModalOpen: boolean;
  reminder: CustomerReminder;
  navigationModalIsOpen: boolean;
  nextLocation?: string;
  hasConfirmedNavigation: boolean;
}

export type DateType = "Start" | "End";
export type DateEntity = "shipper" | "consignee";

type DateEntityCapitilized = "Shipper" | "Consignee"

type IQuoteViewProps = IQuoteViewBaseProp
  & IQuoteEntryServiceInjectedProps
  & ICustomerServiceInjectedProps
  & ICustomerDetailServiceInjectedProps
  & IStateServiceInjectedProps
  & ICommodityServiceInjectedProps
  & IQuoteZipCodeMileageServiceInjectedProps
  & ITarpServiceInjectedProps
  & IQuoteFreightServiceInjectedProps
  & IEquipmentTypeServiceInjectedProps;

class _QuoteView extends React.Component<IQuoteViewProps, IQuoteViewState> {
  state: IQuoteViewState = {
    isReminderModalOpen: false,
    reminder: {
      ...EMPTY_REMINDER
    },
    navigationModalIsOpen: false,
    nextLocation: "",
    hasConfirmedNavigation: false
  };

  async componentDidMount() {
    this.props.regionService.fetchStates();
    this.props.commodityService.fetchActiveCommodities(true);
    await this.props.customerService.getCustomerByTruckMateId(true);
    this.props.equipmentTypeService.fetchEquipmentTypes(true);
    this.props.TarpService.fetchTarps(true);

    const { currentCustomer } = this.props.customerService.getState();
    if (currentCustomer && currentCustomer.id) {
      this.props.customerDetailService.fetchCustomerDetailData(currentCustomer.id);
      this.props.customerDetailService.fetchCustomerContacts(currentCustomer.id);
      this.props.customerDetailService.fetchContactTypes();
      this.props.customerService.getCustomerUsersByTruckMateId();
      await this.props.customerDetailService.fetchCustomerCommodityExclusions(currentCustomer.id, true);
    }
    
    const {customerQuote} = this.props.QuoteEntryService.getState();
    if (customerQuote && customerQuote.shipperZipPostalCode) {
      const shipperPlaces = await this.props.QuoteZipCodeMileageService.queryForPlace(customerQuote.shipperZipPostalCode, "shipper");
      this.props.QuoteEntryService.setShipperPlace(shipperPlaces && shipperPlaces.length > 0 ? shipperPlaces[0] : null);
    }
    if (customerQuote && customerQuote.consigneeZipPostalCode) {
      const consigneePlaces = await this.props.QuoteZipCodeMileageService.queryForPlace(customerQuote.consigneeZipPostalCode, "consignee");
      this.props.QuoteEntryService.setConsigneePlace(consigneePlaces && consigneePlaces.length > 0 ? consigneePlaces[0] : null);
    }
  }

  componentWillUnmount() {
    this.props.QuoteEntryService.clearFreezer();
    this.props.QuoteZipCodeMileageService.clearFreezer();
    this.props.QuoteFreightService.clearFreezer();
  }

  @bind
  private _onDateChange(dateEntity: DateEntity, dateType: DateType, date: Date | null | undefined) {
    this.props.QuoteEntryService.updateCustomerQuote({ [`${dateEntity}${dateType}Date`]: date });
    this.props.QuoteEntryService.updateExpeditedShippingNotes();
  }

  @bind
  private _onHardTimeChange(dateEntity: DateEntity, time: string | undefined) {
    this.props.QuoteEntryService.updateCustomerQuote({ [`${dateEntity}HardTime`]: time });
  }

  @bind
  private _onApptRequiredChange(dateEntity: DateEntity, checked: boolean) {
    const dateEntityCapitilized: DateEntityCapitilized = dateEntity === "shipper" ? "Shipper" : "Consignee"

    if (checked) {
      this.props.QuoteEntryService.updateCustomerQuote({ [`is${dateEntityCapitilized}AppointmentRequired`]: checked });
    }
    else {
      this.props.QuoteEntryService.updateCustomerQuote({ [`is${dateEntityCapitilized}AppointmentRequired`]: checked, [`${dateEntity}HardTime`]: undefined });
    }
  }

  @bind
  private _onAccessCheckboxChange(customerQuote: Partial<CustomerQuote>) {
    this.props.QuoteEntryService.updateCustomerQuote(customerQuote);
  }

  @bind
  private async calculateRate() {
    const isShippingConsigneeMileageValid = await this.props.QuoteZipCodeMileageService.validate();
    if (isShippingConsigneeMileageValid) {
      this.props.QuoteEntryService.rateAndSaveQuote();
    }
  }

  @bind
  private _onDescriptionChanged(newValue: string) {
    this.props.QuoteEntryService.updateCustomerQuote({ description: newValue });
  }

  @bind
  private _onEquipmentTypeChange(equipmentTypeId: string | undefined) {
    this.props.QuoteEntryService.updateCustomerQuote({ equipmentTypeId: equipmentTypeId ? parseInt(equipmentTypeId) : undefined });
    const { customerQuote } = this.props.QuoteEntryService.getState();
    if (customerQuote.customerQuoteFreights) {
      const { activeEquipmentTypes } = this.props.equipmentTypeService.getState();
      const equipmentType = activeEquipmentTypes.find(et => et.id === equipmentTypeId);
      this.props.QuoteFreightService.calculateFreightTotalResults(customerQuote.customerQuoteFreights ?? [], undefined, equipmentType?.tmEquipmentCode);
    }
  }

  @bind
  private _onTarpChange(tarpId: string | undefined) {
    this.props.QuoteEntryService.updateCustomerQuote({ tarpId: tarpId ? parseInt(tarpId) : undefined });
  }

  @bind
  private _onClearTarp() {
    this.props.QuoteEntryService.updateCustomerQuote({ tarpId: undefined });
  }

  @bind
  private _onDeclaredValChanged(value?: NumberFormatValues) {
    this.props.QuoteEntryService.updateCustomerQuote({ declaredValue: value?.floatValue });
  }

  @bind
  private _toggleEditModal(model?: CustomerReminder): void {
    this.setState((prev) => ({
      reminder: model ? model : {},
      isReminderModalOpen: !prev.isReminderModalOpen
    }));
  }

  @bind
  private async _onCreateReminder(model?: CustomerReminder): Promise<void> {
    this._toggleEditModal();
  }

  @bind
  private _displayNavigationModal(location: string) {
    this.setState({
      navigationModalIsOpen: true,
      nextLocation: location
    });
  }

  @bind
  private _onNavigationConfirm() {
    this.setState({
      hasConfirmedNavigation: true,
      navigationModalIsOpen: false
    }, () => this.state.nextLocation && NavigationService.navigateTo(this.state.nextLocation));
  }

  @bind
  private _onNavigationCancel() {
    this.setState({
      navigationModalIsOpen: false
    });
  }

  @bind
  private _addEditContact(contact?: CustomerContact) {
    this.props.customerDetailService.openCustomerContactModal(contact);
  }

  @bind
  private _onContactChange(contact: Partial<CustomerContact>) {
    this.props.customerDetailService.contactOnChange(contact);
  }

  @bind
  private async _onContactSave() {
    await this.props.customerDetailService.saveCustomerContact(true);
  }

  @bind
  private _closeContactModal() {
    this.props.customerDetailService.closeCustomerContactModal();
  }

  render() {
    const {
      isReminderModalOpen,
      reminder,
      navigationModalIsOpen,
      hasConfirmedNavigation
    } = this.state;

    const {
      currentCustomer,
      customerUsersFetchResults
    } = this.props.customerService.getState();

    const {
      contactsFetchResults,
      commodityExclusionsFetchResults,
      contactTypeFetchResults,
      contactModalState
    } = this.props.customerDetailService.getState();

    const {
      regionFetchResults
    } = this.props.regionService.getState();

    const {
      activeCommodities,
      commodityFetchResults
    } = this.props.commodityService.getState();

    const {
      activeEquipmentTypes,
      equipmentTypeFetchResults
    } = this.props.equipmentTypeService.getState();

    const {
      tarpFetchResults
    } = this.props.TarpService.getState();

    const {
      customerQuote,
      quoteValidationErrors,
      rateFetchResults,
      viewOnly,
      hasQuoteChanged
    } = this.props.QuoteEntryService.getState();

    const {
      isZipValidationSuccessful
    } = this.props.QuoteZipCodeMileageService.getState();

    const commodityExclusions = commodityExclusionsFetchResults.data ?? [];

    const validationsParserQuote = new ValidationErrorParser<CustomerQuote>(quoteValidationErrors);

    let equipmentTypeOptions = activeEquipmentTypes ?? [];
    const commoditiesToDisplay = activeCommodities.filter(c => !commodityExclusions?.find(e => e.customerId === currentCustomer?.id && c.id === e.commodityId));

    let tarpOptions = tarpFetchResults.data ?? [];

    const now = moment();
    if (tarpOptions.length > 0) {
      tarpOptions = tarpOptions.filter(
        t => t.tarpValues?.filter(
          v => v.startDateTime && moment(v.startDateTime).isBefore(now) && (v.endDateTime ? moment(v.endDateTime).isAfter(now) : true) || v.id === -1
        )
      );
    }
    
    const selectedTarp = tarpOptions?.find(t => t.id === customerQuote.tarpId);
    let selectedTarpEndDate = undefined;
    if (selectedTarp && selectedTarp.tarpValues) {
      selectedTarpEndDate = selectedTarp.tarpValues.find(v => v.startDateTime && moment(v.startDateTime).isSameOrBefore(now) && (v.endDateTime ? moment(v.endDateTime).isAfter(now) : true))?.endDateTime;
    }
    const tarpInactive = tarpOptions && selectedTarp ? !(selectedTarp.isActive) : false;
    const tarpExpired = selectedTarpEndDate ? moment(selectedTarpEndDate).isBefore(moment()) : false;
    if ((tarpInactive && !viewOnly && !hasQuoteChanged) || (tarpExpired && !viewOnly)) {
      this._onTarpChange(undefined)
      ErrorService.pushErrorMessage("Inactive tarp removed.");
    }
    
    const contactData = contactsFetchResults.data ?? [];

    let contactTypeData = contactTypeFetchResults.data ?? [];
    contactTypeData = _.orderBy(contactTypeData, c => c.type);
    const customerUsers = customerUsersFetchResults.data ?? [];

    const isFetching: boolean = commodityFetchResults.isFetching
      || rateFetchResults.isFetching
      || contactsFetchResults.isFetching
      || equipmentTypeFetchResults.isFetching;

    const salesRepNumber = currentCustomer?.salesAgent?.phoneNumber ?? '800-590-2101';
    const navMessage = "Navigating away before saving quote edits will discard all changes. Are you sure you want to navigate away?";
    const tarpInfo = `Tarp size is inclusive of the length, width, and height of your freight, but is generally categorized by height. For example, if your freight is 8' tall, you will likely need to select 8' Tarp.\r\nIf your freight is more than 20' long, select Full Tarp for more accurate pricing.\r\nIf you are unsure what you need, please contact your Kaiser Representative at ${salesRepNumber}.`;

    return (
      <>
        <Card className={styles.mainContainer}>
          <div className={styles.header}>
            <div>{`New Quote for ${currentCustomer?.customerName ?? ""}`}</div>
            <div>
              {customerQuote.id && 
                <UserAccessControl roles={["reminder:create"]}>
                  <CreateReminderButton
                    seed={{
                      ...EMPTY_REMINDER,
                      customer: {
                        linkId: currentCustomer?.id,
                        linkName: currentCustomer?.customerName,
                      },
                      customerQuote: {
                        linkId: customerQuote.id,
                        linkName: customerQuote.quoteNumber?.toString()
                      }
                    }}
                    onClick={this._toggleEditModal}
                  />
                </UserAccessControl>
              }
            </div>
          </div>
          <CustomerInfo
            currentQuote={customerQuote}
            currentCustomer={currentCustomer}
            contacts={contactData}
            regions={regionFetchResults.data ?? []}
            addContact={this._addEditContact}
          />
          <CardLinedHeader title="Complete the Quote Form" style={{ marginTop: "10px" }}>
            <CommodityShippingDistanceInput />
            <AjaxActionIndicator
              state={rateFetchResults}
              showProgress={isFetching}
            />
            <CommodityDataEntryView />

            <CardLinedHeader title="Enter Freight Details" color="black">
              <div style={{ display: "flex", paddingTop: "0.313rem"}}>
                <AdvanceTextField
                  label="Description"
                  onDebouncedChange={this._onDescriptionChanged}
                  value={customerQuote?.description ?? ""}
                  inputProps={{ maxLength: 250 }}
                  disabled={viewOnly}
                  error={!validationsParserQuote.isValidDeep(`description`)}
                  helperText={validationsParserQuote.validationMessage(`description`)}
                  className={styles.input}
                />
                <div className={styles.input}>
                  <EquipmentTypeEntry
                    equipmentTypeId={customerQuote?.equipmentTypeId}
                    viewOnly={viewOnly}
                    equipmentTypeOptions={equipmentTypeOptions}
                    onEquipmentTypeChange={this._onEquipmentTypeChange}
                    validationErrors={quoteValidationErrors}
                  />
                </div>
                <div className={styles.input}>
                  <TarpEntry
                    tarpId={customerQuote?.tarpId}
                    viewOnly={viewOnly}
                    tarpOptions={tarpOptions}
                    onTarpChange={this._onTarpChange}
                    validationErrors={quoteValidationErrors}
                  />
                  <IconButton
                    size="small"
                    onClick={this._onClearTarp}
                    disabled={viewOnly}
                  >
                    <Clear className={styles.clearIcon} />
                  </IconButton>
                  <Icon title={tarpInfo} className={styles.icon}>
                    <Info className={styles.infoIcon} />
                  </Icon>
                </div>
                <div className={styles.input} style={{ width: "12rem"}}>
                  <DeclaredValueEntry
                    declaredValueEntry={customerQuote.declaredValue ?? undefined}
                    viewOnly={viewOnly}
                    onValueChange={this._onDeclaredValChanged}
                    validationErrors={quoteValidationErrors}
                  />
                </div>
              </div>
              <QuoteDatesEntry
                dateEntity="shipper"
                viewOnly={viewOnly}
                startDate={customerQuote?.shipperStartDate}
                endDate={customerQuote?.shipperEndDate}
                isAppointmentRequired={customerQuote?.isShipperAppointmentRequired}
                hardTime={customerQuote?.shipperHardTime}
                onDateChange={this._onDateChange}
                onHardTimeChange={this._onHardTimeChange}
                onApptRequiredChange={this._onApptRequiredChange}
                validationErrors={quoteValidationErrors}
              />
              <QuoteDatesEntry
                dateEntity="consignee"
                viewOnly={viewOnly}
                startDate={customerQuote?.consigneeStartDate}
                endDate={customerQuote?.consigneeEndDate}
                isAppointmentRequired={customerQuote?.isConsigneeAppointmentRequired}
                hardTime={customerQuote?.consigneeHardTime}
                onDateChange={this._onDateChange}
                onHardTimeChange={this._onHardTimeChange}
                onApptRequiredChange={this._onApptRequiredChange}
                validationErrors={quoteValidationErrors}
              />
              <div style={{ whiteSpace: "nowrap" }}>
                {customerQuote.isExpedited &&
                  <td className={styles.errorIcon}>
                    <Error fontSize="small" style={{ verticalAlign: "middle" }} />
                    {"Expedited Shipping"}
                  </td>}
              </div>
              <AccessRestrictionsEntry
                customerQuote={customerQuote}
                viewOnly={viewOnly}
                onAccessCheckboxChange={this._onAccessCheckboxChange}
              />
            </CardLinedHeader>

            <UserAccessControl roles={["quote:create", "quote:edit"]}>
              <div className={styles.actions}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isFetching || viewOnly || !isZipValidationSuccessful || (rateFetchResults.hasFetched && !hasQuoteChanged) || !hasQuoteChanged}
                  onClick={this.calculateRate}
                >
                  Calculate Rate
                </Button>
              </div>
            </UserAccessControl>

          </CardLinedHeader>
        </Card>
        <AddEditQuoteCommodity
          activeCommodities={commoditiesToDisplay}
          activeEquipmentTypes={activeEquipmentTypes}
        />

        <Prompt
          message={(location) => {
            const {
              editMode,
              editQuoteOtherInfoMode
            } = this.props.QuoteEntryService.getState();
            if (!hasConfirmedNavigation && (editMode || editQuoteOtherInfoMode)) {
              this._displayNavigationModal(location.pathname);
              return false;              
            }

            return true;
          }}
        />
        <ConfirmCancelModal
          isOpen={navigationModalIsOpen}
          onCancelNo={this._onNavigationCancel}
          onCancelYes={this._onNavigationConfirm}
          message={navMessage}
        />
        <ReminderEditModal
          isOpen={isReminderModalOpen}
          reminder={reminder}
          onClose={this._onCreateReminder}
          customerUsers={customerUsers}
        />
        <CustomerContactModal
          isOpen={contactModalState.isOpen}
          contact={contactModalState.editContact}
          validationErrors={contactModalState.validationErrors}
          contactTypes={contactTypeData}
          customerUsers={customerUsers}
          contacts={contactData}
          onSave={this._onContactSave}
          onCancel={this._closeContactModal}
          onChange={this._onContactChange}
        />
      </>
    );
  }
}

export const QuoteView = QuoteEntryService.inject(
  StateService.inject(
    CustomerService.inject(
      CustomerDetailService.inject(
        CommodityService.inject(
          QuoteZipCodeMileageService.inject(
            TarpService.inject(
              QuoteFreightService.inject(
                EquipmentTypeService.inject(
                  _QuoteView
                )
              )
            )
          )
        )
      )  
    )
  )
);