import {
  React,
  NumberFormat,
  NumberFormatValues,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  TextField
} from "$Imports/MaterialUIComponents";

import {
  Quote
} from "$Generated/api";

interface IDeclaredValueEntryBaseProps {
  declaredValueEntry?: number,
  viewOnly?: boolean,
  onValueChange?: (value: NumberFormatValues) => void;
  validationErrors?: ValidationError | null
}

export class DeclaredValueEntry extends React.PureComponent<IDeclaredValueEntryBaseProps> {

  @bind
  private _onValueChange(value: NumberFormatValues) {
    if (this.props.onValueChange) {
      this.props.onValueChange(value);
    }
  }

  render() {

    const {
      declaredValueEntry,
      viewOnly,
      validationErrors
    } = this.props;

    const validationsParserQuote = new ValidationErrorParser<Quote>(validationErrors);
    
    return (
      <NumberFormat
        allowNegative={false}
        thousandSeparator={true}
        decimalScale={0}
        disabled={viewOnly}
        fullWidth
        prefix="$"
        label="Declared Value *"
        value={declaredValueEntry ?? ""}
        customInput={TextField}
        onValueChange={this._onValueChange}
        error={!validationsParserQuote.isValidDeep(`declaredValue`)}
        helperText={validationsParserQuote.validationMessageDeep(`declaredValue`)}
      />
    );
  }
}