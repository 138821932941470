import {
  _
} from "$Imports/Imports";

import { IThemeConfiguration } from "./themeConfiguration";

import {
  defaultTheme
} from "./defaultTheme";

export const logisticsGreen = "#0E9A47";
export const highlightGreen = "#E5FFE5";

const greenThemeDefinition = {
  
  themeOptions: {
    palette: {
      secondary: {
        main: logisticsGreen,
      }
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          head: {
            color: logisticsGreen
          }
        }        
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: highlightGreen,
              "&:hover": {
                backgroundColor: highlightGreen
              }
            }
          }
        }
      },
      MuiDataGrid: {
        styleOverrides: {
          row: {
            "&.Mui-selected": {
              backgroundColor: highlightGreen,
              "&:hover": {
                backgroundColor: highlightGreen
              }
            }
          }
        }
      }
    }
  },
  sideNavigationMenu: {
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: logisticsGreen
            }
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: logisticsGreen
            }
          }
        }
      },     
    }

  },
  appBarNavigationMenu: {
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: logisticsGreen
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: logisticsGreen
          }
        }
      }
    }
  }
};

// _.merge mutates the base object so you have to give it an empty object
export const greenTheme: IThemeConfiguration = _.merge({}, defaultTheme, greenThemeDefinition);
